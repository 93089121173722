import React, {useState, useEffect} from 'react';    // eslint-disable-line no-unused-vars
import {useSelector, useDispatch} from 'react-redux';
import useModalState from '../tinyparts/ModalState';
import {get, isEmpty, has} from 'lodash';
import { 
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTag} from '@fortawesome/free-solid-svg-icons'
import configFile from '../../config';
import {subscriberCartsArray as _subscriberCartsArray, cartsAccounting as _cartsAccounting } from '../../redux/selectors';
import { changeQtyItemInCart as __changeQtyItemInCart,
  setCoupon as __setCoupon } from '../../redux/actions/marketplaceActions';    
// import { ClipLoader } from 'react-spinners';
import {Spinner} from '../tinyparts/Spinners'
import SCart from './SCart';

export default function CartWidget(){
  const { availableSubscribers} = useSelector(({marketplaceReducer})=> ({
    availableSubscribers : marketplaceReducer.availableSubscribers,
  }));
  const { subscriberCartsArray, cartsAccounting} = useSelector((state)=> ({
    subscriberCartsArray: _subscriberCartsArray(state),
    cartsAccounting: _cartsAccounting(state)
  }));
  const dispatch = useDispatch();
  const changeQtyItemInCart = (itemInCart, qty)=>dispatch(__changeQtyItemInCart(itemInCart, qty));

    return <div className="col-sm p-2">
      {subscriberCartsArray.map((scart) => {
            let subscriberInfo =availableSubscribers[scart.sid];
            return <SCart key={scart.sid}
            {...{ scart, changeQtyItemInCart, 
                isStandAlone : subscriberCartsArray.length === 1, 
                isAbstract : true, subscriberInfo }}
        />})}
        <SCartFooter {...{cartsAccounting }} />                    
    </div>
}

const SCartFooter = ({cartsAccounting}) =>{
    const {  couponResponse} = useSelector(({marketplaceReducer})=> ({
      couponResponse : marketplaceReducer.checkout.couponResponse,
    }));
    const dispatch = useDispatch();
    const setCoupon = (couponResponse)=>dispatch(__setCoupon(couponResponse));
    const { isOpen, toggle } = useModalState();
    const {accounting} = cartsAccounting;
    const {tax, coupon, total} = accounting;
    const [couponCode, setCouponCode]= useState(null);
    const [couponError, setCouponError]= useState(null);
    let [loading, setLoading] = useState(false);

    async function applyCoupon(){
      if(!couponCode && !couponResponse) {
        setCouponError( {type: 'error', message: 'Please enter a promo code.'});
        return;
      }
      try{
          let url = `${process.env.REACT_APP_API_DOMAIN}/api/coupon/verify`;
          let couponRequest = {
              method: 'POST',
              url,
              data: {coupon : couponCode || couponResponse.code}
          }
          setLoading(true);
          const { data } = await configFile.Axios(couponRequest);
          console.log("TCL: applyCoupon -> data", data)
          const {coupon , error} = data;
          if(coupon){
            setCoupon(coupon);
            setCouponCode(null);
            setCouponError(null);
            isOpen && toggle()
          }else{
            setCouponError({type: 'error', message: get(error, 'message', 'Invalid Coupon.')});
          }
          setLoading(false);
        }catch(e){
          const {
            data,
          } = e.response;
          if(coupon) setCoupon(null);
          setCouponCode(null);
          setCouponError(isOpen ?   {type: 'error', message: get(data, 'message',e.message)} :   null);
          setLoading(false);
      }
    }

    useEffect(()=>{
      // if coupon exists, validate it
      if(couponResponse){
        applyCoupon()

      }
    }, [])

    return <div className="container">
    <hr/>
    <div className="row">
    {loading ? 
        <div className='text-right align-right'>
          <Spinner />
        {/* <ClipLoader className = "spinnerloading" loading={true} color="#26A65B" size={parseInt(25, 10)} margin="4px"/>   */}
        </div>
      :
      <p className="col-12 text-right text-success"  onClick={e=> {toggle();}} style={{cursor: 'pointer'}}>
        add promo code{' '}
      </p>    
      
    }
    </div>
    {!isEmpty(couponResponse) && !loading &&  <div className="row">

          <h5 className="col-10 text-right text-success font-weight-light">
            <FontAwesomeIcon icon={faTag}    color="green"   /> Savings:
          </h5>
          <h5 className="col-2 text-right  text-success">
            ${coupon}
          </h5>
    </div>
    }
    <div className="row">
      <h5 className="col-10 text-right">
        Sales Tax:
      </h5>
      <h5 className="col-2 text-right">
        ${tax}
      </h5>
    </div>
    <div className="row">
      <h5 className="col-10 text-right">
        Tips <span className="text-muted">(no Tipping)</span>:
      </h5>
      <h5 className="col-2 text-right">
        $0.00
      </h5>
    </div>
    <hr />
    <div className="row">
      <h4 className="col-10 text-right">
        Total:
      </h4>
      <h5 className="col-2 text-right">
        ${total}
      </h5>
    </div>
    <Modal isOpen={isOpen} toggle={toggle}  >
        <ModalHeader 
          toggle={toggle}><FontAwesomeIcon 
          icon={faTag}    
          color="green"   /> Apply Coupon</ModalHeader>
        <ModalBody>
          <div className="form-group"> 
            <input name="promocode" id="promocode" 
              type="text" 
              min="3"
              autoComplete='off' placeholder='Enter you promo code'
              value={couponCode || ''}
              onChange={e=>setCouponCode(e.target.value)}
              className="form-control" />
            {has(couponError, 'message')  && <div className="text-danger d-block mx-2">{couponError.message}</div>}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-lg btn-outline'
            style={{cursor: 'pointer'}}
            onClick={toggle}>Cancel</button>
          <button 
                className='btn btn-lg btn-success '
                style={{cursor: 'pointer'}}
                type="submit"
                onClick={applyCoupon} >
                {loading && <Spinner />   }
                  Add
                </button>          
        </ModalFooter>
      </Modal>        
  </div>;
}