
import React, { useEffect, useState, useContext, useCallback } from "react";
import { identity, pickBy, get } from 'lodash';
import * as Sentry from '@sentry/browser';
import { useSelector, useDispatch } from 'react-redux';
import { InsightsContext } from "./Insights";
import { db, auth } from "./base.js";
import { onSnapshot, doc, updateDoc, getDoc } from "firebase/firestore";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getAuth, createUserWithEmailAndPassword, currentUser, EmailAuthProvider } from "firebase/auth";
import { getTokenExpirationDate, isTokenExpired } from './redux/utils/jwtHelper';
import { updateCheckoutAction as __updateCheckoutAction } from "./redux/actions/marketplaceActions.js";
import { checkoutPageEmpty as _checkoutPageEmpty } from './redux/selectors';
import { getType, retry } from "./togonowutils.js";
import { throwIfMissing } from './togonowutils'
import { Spinner } from "reactstrap";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {

    // STATE
    const { customerlocation } = useSelector(({ marketplaceReducer }) => ({
        customerlocation: marketplaceReducer.customerlocation,
    }));
    // SELECTORS
    const { checkoutPageEmpty } = useSelector((state) => ({
        checkoutPageEmpty: _checkoutPageEmpty(state),
    }));
    // ACITIONS
    const dispatch = useDispatch();
    const updateCheckoutAction = (checkout) => dispatch(__updateCheckoutAction(checkout));

    // HOOKS
    const [currentUser, setCurrentUser] = useState(null);
    const [currentProfile, setCurrentProfile] = useState(null);
    const [idToken, setIdToken] = useState(null);
    const [authLoading, setAuthloading] = useState(true);
    const [intervalID, setIntervalID] = useState(0);
    const [unsubscriberProfile] = useState(null);

    // const _setAuthLoading = (_authloading) => setAuthLoading(_authloading) // need this since setAuthLoading can't be accessed inside a use Effect
    function __setauthloading(_) {
        setAuthloading(_)
    }
    async function signOut() {
        unsubscriberProfile && unsubscriberProfile();
        auth.signOut();
    }

    async function UpdateProfile({ name, phone }) {
        if (!phone && !name) {
            return;
        }

        let user = auth().currentUser;
        if (!user) return;  // only update if we are logged in

        try {
            let u = pickBy({ name, phone }, identity);
            const { uid } = user
            const customerRef = doc(db, "customers", uid);
            await updateDoc(customerRef, u)
        } catch (e) {
            console.error(e.message);
        }
    }

    // if address is not in profile, it'll add it to the profile
    async function AddAddress(newLocation = throwIfMissing(), forUser = null, forLocations = null) {
        let _u = currentUser || forUser;  // sometime we need to add it for a user that hassend been assigned to the state yet
        if (!_u || getType(newLocation) !== 'object' || !newLocation.placeId || !newLocation.address) {
            return;
        }
        try {
            const { uid } = _u
            let newLocations = (currentProfile ? currentProfile.locations : get(forLocations, 'locations')) || [];
            let locations_matches = newLocations.filter(l => l.placeId === newLocation.placeId);
            if (locations_matches.length === 0) {
                newLocations.push(newLocation);
                const customerRef = doc(db, "customers", uid);
                await updateDoc(customerRef, { locations: newLocations })
                // await  firebase.firestore().collection('customers').doc(uid).update({ locations: newLocations });
                return true;
            }
            return false;
        } catch (e) {
            console.error(e.message);
        }
    }

    async function UpdatePassword(currentPassword, newPassword) {
        try {
            let user = auth().currentUser;

            var cred = EmailAuthProvider.credential(user.email, currentPassword);
            // var cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);

            user.reauthenticateWithCredential(cred).then(() => {
                user.updatePassword(newPassword).then(() => {
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.error(error.message)
            });
            console.error('error changed password');
        } catch (e) {
            console.log(e.message)
        }
    }

    function refreshToken() {
        let _currentUser = auth.currentUser;
        if (_currentUser) {
            _currentUser.getIdToken(true).then((_token) => {
                setIdToken(_token);
            })
        }
    }



    function schedultokenrenewal(_token) {
        const expiresAt = getTokenExpirationDate(_token);
        const delay = expiresAt - Date.now(0) - ((expiresAt - Date.now(0)) > 20000 ? 20000 : 0);
        if (delay > 0) {
            let _intervalID = setInterval(() => {
                refreshToken();
            }, delay);
            setIntervalID(_intervalID);
        }
    };


    // Updates profiel and  Checkout Page if it's empty
    function setEmptyCheckout(data) {
        // Is the Checkout Info Empty? then placeholder it
        if (data && checkoutPageEmpty) {
            let { name, email, phone, deliveryvalue } = data;
            let _c = { name, email, phone, deliveryvalue };
            let checkout = pickBy(_c, identity);
            updateCheckoutAction(checkout)
        }
    }



    async function listenToProfile(_doc, newuser, _token) {
        let _data = _doc.data();
        const { uid } = newuser
        if (_doc.exists) {
            !!customerlocation && await AddAddress(customerlocation, newuser, _data);

            let c = await getCustomer(uid);
            return setFullProfile(c, _token, newuser, uid);
        } else {
            console.log("TCL: Document Does not Exists!!***********************");
        }
    }


    async function setFullProfile(doc, _token, newuser, uid) {
        if (doc.exists) {
            let data = doc.data();
            setIdToken(_token);
            setCurrentUser(newuser);
            setCurrentProfile(data);
            setEmptyCheckout(data);
            schedultokenrenewal(_token);
        } else {
            Sentry.captureException(new Error(`user not found in firebase, uid: ${uid}`));
        }
    }

    async function getCustomer(uid) {
        try {
            let customerRef = doc(db, "customers", uid);
            let _doc = await getDoc(customerRef)
            if (_doc.exists()) {
                return _doc;
            } else {
                throw new Error('Document Not found')
            }
        } catch (e) {
            console.log("🚀 ~ file: Auth.js ~ line 188 ~ getCustomer ~ e", e)


        }
    }



    const getCustomerSafe = async (uid) => {
        return await retry(getCustomer, 5, uid)
    };




    useEffect(() => {
        auth.onAuthStateChanged((newuser) => {






            (async () => {
                __setauthloading(true);
                let _token = !!newuser && await newuser.getIdToken();
                let _tokenExpired = !!newuser && isTokenExpired(_token);
                if (!_token || _tokenExpired) {
                    setCurrentUser(null);
                    setCurrentProfile(null)
                    setIdToken(null)
                    if (intervalID) {
                        clearInterval(intervalID);
                        setIdToken(null);
                    }
                    __setauthloading(false);
                    return;
                }

                if (newuser) {
                    const { uid } = newuser;

                    //*** Double check if we have a new location from ananymous browsing, if so will be added to the profile */
                    try {









                        let _doc = await retry(getCustomer, 5, uid);
                        await listenToProfile(_doc, newuser, _token);











                    } catch (e) {
                        console.log('💣💣💣 account created but document not found!!');
                        console.log(e.message)
                        console.trace()
                        Sentry.captureException(new Error(`created account ${uid} but could not get the profile from firebase functions`));
                    }
                } else {
                    Sentry.captureException(new Error('error retreiving user profile'));
                    throw (new Error('error retrieving user profile'));
                }
                __setauthloading(false);
            })()
        });


    }, []);


    return (
        <AuthContext.Provider
            value={{
                authLoading,
                currentUser, currentProfile, idToken, // props
                signOut, UpdateProfile, UpdatePassword, AddAddress,// actions
                setEmptyCheckout, // actions
                refreshToken
            }}
        >
            {authLoading ? <Spinner /> : children}
        </AuthContext.Provider>
    );
};