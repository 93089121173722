import configFile from '../../config';
import {has } from 'lodash';
import {throwIfMissing, SendSentryException} from '../../togonowutils'
import * as Sentry from '@sentry/browser';


export const selectItem = (selectedItem, getstate) => {
  return (dispatch, getState)=>{
    let __state = getState();
    if(selectedItem && !selectedItem.subscriberid){
      selectedItem.subscriberid = __state.marketplaceReducer.selectedSubscriber;
    }
    dispatch({
      type: 'SELECT_ITEM',
      selectedItem,
    });

  }
}

export const selectSubscriber = (subscriberid) => {
  return (dispatch, getState)=>{
    const __state = getState();
    if(has(__state.marketplaceReducer, ['availableSubscribers', subscriberid, 'menu', 'Groups'])){
      dispatch({
          type: 'SELECT_SUBSCRIBER',
          subscriberid,
      })    
    }else{
      let menuversion = __state.marketplaceReducer.availableSubscribers[subscriberid] && '_' + __state.marketplaceReducer.availableSubscribers[subscriberid].menuversion;
      let url = `${process.env.REACT_APP_MARKETPLACE_STORAGE_BASEURL}/subscribermenu/` + subscriberid  +  (menuversion  && menuversion) + (process.env.REACT_APP_MARKETPLACE_IS_PENDING && process.env.REACT_APP_MARKETPLACE_IS_PENDING) + '.json';
      dispatch({
        type: 'GET_SUBSCRIBERMENU',
        payload: configFile.Axios.get(url),
        meta: {     subscriberid}
      });

    }
  }
}

export const resetSelectedSubscriberNItem = ()=>{
  return (dispatch, getState)=>{
    dispatch({
      type: 'reset_selected_subscriber_n_item'
    })  
  }
}

export const setAppVersion = (appversion)=>{
  return (dispatch, getState)=>{
    dispatch({
      type: 'set_app_version',
      appversion
    })  
  }
}



export const clearSelectedSubscriber = () => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_SELECT_SUBSCRIBER'
    })  
  };
}

export const selectOV = (selectedOV) => {
  return (dispatch)=>{
    // console.trace('selectOV Action')
    dispatch({
        type: 'SELECT_OV',
        selectedOV
    })    
  }
}
export const selectSubOV = (selectedSubOV) => {
  return (dispatch)=>{
    dispatch({
        type: 'SELECT_SubOV',
        selectedSubOV
    })    
  }
}



export const setCoupon = (coupon) => {
  return (dispatch, getState)=>{
    dispatch({
        type: 'SET_COUPON',
        coupon       
    })
  }
}

export const addItem = (item, qty) => {
  return (dispatch, getState)=>{
    dispatch({
        type: 'ADD_ITEM',
        item,qty       
    })
  }
}

export const removeItem = (removeItem) => {
  return (dispatch)=>{
    dispatch({
        type: 'REMOVE_ITEM',
        removeItem
    })    
  }
}

export const changeQty = (item, qty) => {
  return (dispatch)=>{
    dispatch({
        type: 'CHANGE_QTY',
        item, qty
    })    
  }
}

export const changeQtyItemInCart = (itemInCart, qty) => {
  return (dispatch)=>{
    dispatch({
        type: 'CHANGE_QTY_ITEMINCART',
        itemInCart, qty
    })    
  }
}


export const __updateSubscribersExpiry_NEW = ()=> dispatch=>{
  dispatch({ type:'UPDATE_SUBSCRIBERS_EXPIRY'})
  return Promise.resolve()
}

export const setAvailableSubscribersAndMarketplaceInfo = ({availableSubscribers, marketplaceclosed, marketplacemessage})=> {
  return (dispatch)=>{
    dispatch({
        type: 'UPDATE_SUBSCRIBERS_EXPIRY',
        availableSubscribers, marketplaceclosed, marketplacemessage
    })    
  }
}


export const setMarketplaceMenu = (marketplacemenu)=>{
  return (dispatch)=>{
    dispatch({
        type: 'SET_MARKETPLACE',
        payload : {data : marketplacemenu}
    })  
  }
}



export const setMarketplaceInfo = (marketplaceinfo)=>{
  return (dispatch)=>{
    dispatch({
        type: 'SET_MARKETPLACEINFO',
        marketplaceinfo
    })
  }
}

export const resetMarketplaceSubscriberMenus = ()=>{
  return {type: 'RESET_MARKETPLACESUBSCRIBERMENUS'}
}


//***************************** */
//****** customerlocation RELATED *** */
//***************************** */
export const setLocation = (customerlocation) => {
  return (dispatch)=>{
    dispatch({
        type: 'SET_LOCATION',
        customerlocation
    })    
  }
}
export const clearLocation = () => {
  return (dispatch)=>{
    dispatch({
        type: 'CLEAR_LOCATION'
    })    
  }
}


// gets customer location if there's any sepcial requirements e.g. dropdown building etc...
export const getPlaceByID = (placeId, authToken)=>{
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/delivery/${placeId}` ;
  return {
    type: 'GET_PLACEID',
    payload: configFile.Axios({
      method: 'GET',
      url,
      headers: (authToken ? {
        'Authorization': 'Bearer ' + authToken
      } : {})
    })
  };
}


//***************************** */
//****** Profile RELATED *** */
//***************************** */
export  const  setProfile = (userProfile) => {
  return (dispatch)=>{
    dispatch({
        type: 'SET_PROFILE',
        userProfile,
    })
  }
}

export  const  getProfileOrders = (authToken= throwIfMissing()) => {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/order/` ;
  return {
    type: 'GET_PROFILE_ORDERS',
    payload: configFile.Axios({
      method: 'GET',
      url,
      headers: {
        'Authorization': 'Bearer ' + authToken
      }
    })
  };
}
export  const  clearProfileOrders = () => {
  return {
    type: 'CLEAR_PROFILE_ORDERS'}
}
export  const  getOrderDetails = (orderid=throwIfMissing(), authToken) => {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/order/id/${orderid}` ;

  return {
    type: 'GET_ORDER_DETAILS',
    payload: configFile.Axios({
      method: 'GET',
      url,
      headers: (authToken ? {
        'Authorization': 'Bearer ' + authToken
      } : {})
    })
  };
}

export const clearOrderDetails = ()=>{
  return {
    type: 'CLEAR_ORDER_DETAILS'}
}


//***************************** */
//****** STRIPE RELATED *** */
//***************************** */
export  const  logOut = () => {
  return (dispatch)=>{
    dispatch({
        type: 'LOGOUT'
    })
}
}

export  const  getStripeProfile = (usertoken= throwIfMissing()) => {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/profile/listcards` ;
  return {
    type: 'GET_STRIPEPROFILE',
    payload: configFile.Axios({
      method: 'GET',
      url,
      headers: {
        'Authorization': 'Bearer ' + usertoken 
      }
    })
  };
}


// export const SetPaymentIntentAction = ({paymentIntent, ephemeralKey, customer})=>{
export const SetPaymentIntentAction = ({paymentIntent, customer})=>{
  return (dispatch)=>{
    dispatch({
        type: 'PaymentIntent',
        paymentIntent, 
        // ephemeralKey, 
        customer
    })
  }
}



export  const  addCard = (cctoken, usertoken = throwIfMissing()) => {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/profile/addcard`;
  return {
    type: 'GET_ADDCARD',
    payload: configFile.Axios({
      method: 'POST',
      url,
      headers: {
        'Authorization': 'Bearer ' + usertoken 
      },
      data: {cctoken}
    })
  }
}

export  const  deleteCard = (cardOrCardID = throwIfMissing(), usertoken= throwIfMissing()) => {
  const cardid = cardOrCardID.id || cardOrCardID;
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/profile/deletecard`;
  return {
    type: 'GET_DELETECARD',
    payload: configFile.Axios({
      method: 'POST',
      url,
      headers: {
        'Authorization': 'Bearer '+ usertoken 
      },
      data: { cardid }
    })
  }
}

export const selectCard = (card)=>{
  return (dispatch)=>{
    dispatch({
        type: 'SELECT_CARD',
        card
    })
  }
}


export const submitOrderFulfilled = (id)=>{
  return (dispatch)=>{
    dispatch({
      type: 'SUBMIT_ORDER_FULFILLED',
      id
  })
  }
}

export const switchPaymentMode = (paymentMode)=>{
    return (dispatch)=>{
        dispatch({
          type: 'SWITCH_PAYMENT_MODE',
          paymentMode
      })
      }
}

// Checkout page related

export const updateCheckoutAction = (checkout)=>{
  // console.log("TCL: updateCheckoutAction -> checkout", checkout)
  return (dispatch)=>{
    dispatch({
        type: 'UPDATE_CHECKOUT',
        checkout
    })
  }
}


export const checkoutLoadingAction = (CHECKOUT_VALIDATING)=>{
  return (dispatch)=>{
    dispatch({
        type: 'CHECKOUT_VALIDATING',
        CHECKOUT_VALIDATING
    })
  }
}



// ERROR AND SPINNER RELATED

export const setErrorsAction = (err)=>{
  return (dispatch)=>{
    dispatch({type: 'SET_ERRORS', appError: err, loggerError: err});
  }
}

export const clearErrorsAction = ()=>{
  return (dispatch)=>{
    dispatch({type: 'CLEAR_ERRORS'});
  }
}

// export const setRequestFullfilled = (requestFullfilled=false)=>{
//   return (dispatch=>{
//     dispatch({type: 'RequestFullfilled'});
//   });
// }

// StartupCOntainer RELATED
//**** RESET SPINNERS */
export const resetSpinnerAction = ()=>{
  return (dispatch)=>{
    dispatch({type: 'RESET_SPINNERS'});
  }
}

export const clearLoadedSubscribers = ()=>{
  return (dispatch)=>{
    dispatch({type: 'CLEAR_SUBSCRIBERS'});
  }
}