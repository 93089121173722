import React, { Fragment, useEffect } from 'react';
import AppBoundary from './components/AppBoundary';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import {
    BrowserRouter as Router,
    Route, Redirect,
} from 'react-router-dom';

import './custom.scss';
import './index.css';
import store from './redux/store';
import { saveState } from './redux/reducers/localStorage';
import { AuthProvider } from "./Auth";
import { InsightsProvider } from './Insights';
import OrderDetail from './components/OrderDetail';
import Header from './containers/Header/Header';
import Privacy from "./components/Privacy";
import SignIn from "./components/SignIn";
import Profile from "./components/Profile";
import { PrivateRoute, OnlyPublicRoute } from "./components/PrivateRoute";
import MenuContainer from './containers/MenuContainer';
import SubscriberMenu from './components/SubscriberMenu';
import Payment from './components/Payment';
import Orders from './components/Orders';
import Address from './containers/Address';
import Reset from './components/Reset';
import Checkout from './components/Checkout';
import { Spinner } from './components/tinyparts/Spinners';
import CartDock from './components/CartDock';
import Login from './components/Login';

import PopUp from './components/Popup';


import { createTheme, ThemeProvider } from '@mui/material/styles'
import purple from '@mui/material/colors/purple';
import ItemDetail from './components/ItemDetail';

const accent = purple['A200']; // #e040fb

const theme = createTheme({
    palette: {
        primary: {
            main: "#107003"
        },
        accent: {
            main: accent
        }
    }
});

theme.typography.h2 = {
    fontSize: "1.0rem",
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.2rem',
        fontWeight: 'normal',
    }
}

theme.typography.h2[theme.breakpoints.up('sm')] = {  // 960px
    fontSize: '1.2rem',
    fontWeight: 'normal',
}

theme.typography.h2[theme.breakpoints.up('md')] = {  // 960px
    fontSize: '1.5rem',
    fontWeight: 'normal',
}

const __RELEASE = '3.3';
const __BUILD = '3.33';
const __DateTime = 'NOV_14_2022'
console.log(`ToGoNow Marketplace Version %s- Build %s %s`, __RELEASE, __BUILD, __DateTime);
console.log(`Enviroment File: `, process.env.REACT_APP_ENVFILE)
store.subscribe(() => {
    saveState(store.getState());
});


function Routes() {
    return (
        <AppBoundary {...{ __RELEASE, __BUILD }}>
            {appBoundary => {
                return (
                    <Router>
                        <Route path={'/'} >
                            <>
                                <Header toogleCartDock={appBoundary.toogleCartDock} />
                                <CartDock {...{ appBoundary }} />
                                <PopUp popupTitle={appBoundary.popupTitle} popupSubtitle={appBoundary.popupSubtitle} />
                            </>
                        </Route>
                        <Route exact path='/'>
                            {appBoundary.customerlocation ? <Redirect to={'/menu'} /> : <Redirect to={'/address'} />}
                        </Route>
                        <Route path='/menu' component={() => <MenuContainer appBoundary={appBoundary} />} />
                        <Route exact path={["/s/:subscriberid"]} component={() => <>
                            <SubscriberMenu appBoundary={appBoundary} />
                            <ItemDetail toggleAddedToCart={appBoundary.toggleAddedToCart} />
                        </>} />
                        <Route exact path='/loading' component={Spinner} />
                        <Route path='/address' component={Address} />
                        <PrivateRoute exact path='/orders' component={Orders} />
                        <Route exact path='/orders/id/' component={OrderDetail} />       {/* id is passed as a query string in the url */}
                        <Route exact path='/orders/id/:orderid' component={OrderDetail} />   {/* id is passed in the url */}
                        <PrivateRoute path='/payment' component={Payment} />
                        <Route path='/checkout' component={Checkout} />
                        <OnlyPublicRoute path='/reset' component={Reset} />
                        <OnlyPublicRoute path="/login" component={Login} />
                        {/* <OnlyPublicRoute path="/signup" component={SignUp} /> */}
                        <OnlyPublicRoute exact path="/signin" component={SignIn} />
                        <OnlyPublicRoute path="/signin/to/:to" component={SignIn} />
                        <PrivateRoute path="/profile" component={Profile} />
                        <Route exact path="/privacy" component={Privacy} />
                    </Router>
                )
            }}
        </AppBoundary>
    )
}

const renderRoutes = () => (
    <Provider store={store}>
        <InsightsProvider>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Routes />
                    </Router>
                </ThemeProvider>
            </AuthProvider>
        </InsightsProvider>
    </Provider>
);
render(renderRoutes(), document.getElementById('root'));       