import React from 'react';
import { db } from "../base.js";
// throw new Error('hello');


import { onSnapshot, doc, getDoc } from "firebase/firestore";
import * as Sentry from '@sentry/browser';
// AZURE INSIGHTS
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
// AZURE INSIGHTS
import { connect } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
import {
    __updateSubscribersExpiry_NEW, clearErrorsAction,
    setAppVersion,
    resetSpinnerAction, getPlaceByID,
    setAvailableSubscribersAndMarketplaceInfo,
    setMarketplaceInfo,
    resetSelectedSubscriberNItem
} from '../redux/actions/marketplaceActions';
import { Errored, Spinner } from '../components/tinyparts/Spinners'

import { get, orderBy } from 'lodash';
import { arrSubscriberToObject } from '../redux/utils/aggregate';

const __lgScreenDock = 0.4;
const __smScreenDock = 0.9;
const __lgScreenRestSlider = { slidesCount: 4, logoWidth: 150, smallScreen: false };
const __smScreenRestSlider = { slidesCount: 2, logoWidth: 100, smallScreen: true };

class AppBoundary extends React.Component {
    constructor(props) {
        super(props);
        const { __RELEASE, __BUILD } = this.props;
        this.mql = window.matchMedia(`(min-width: 800px)`);
        this.state = {
            error: null,
            errorInfo: null,
            loading: true,
            cartDockVisible: false,
            sizeOfDock: (this.mql.matches ? __lgScreenDock : __smScreenDock),
            plazas: null, unsubscribePlazas: null,
            addedToCartTitle: null,
            popupTitle: null,
            popupSubtitle: null,
        };




        Sentry.init({
            dsn: process.env.REACT_APP_RAVEN_DSN,
            release: __RELEASE,
            build: __BUILD
        });

        this.toogleCartDock = this.toogleCartDock.bind(this);
        this.refreshCustomerLocation = this.refreshCustomerLocation.bind(this);
        this.startup = this.startup.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
        this.unlisten = this.unlisten.bind(this);
        this.toggleAddedToCart = this.toggleAddedToCart.bind(this);
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error messag e
        debugger;
        this.setState({
            error,
            errorInfo,
            loading: false
        });
        Sentry.captureException(error);
    }

    componentDidUpdate(prevProps) {
        if (get(this.props, 'customerlocation.id') !== get(prevProps, 'customerlocation.id')) {
            this.startup();
        }
    }


    componentDidMount() {
        this.setState({ loading: true });
        this.props.setAppVersion(this.props.__BUILD)
        this.props.clearErrorsAction();
        this.props.resetSpinnerAction();
        this.props.resetSelectedSubscriberNItem();
        this.refreshCustomerLocation();
        this.startup();

    }

    componentWillUnmount() {
        this.unlisten();
    }

    unlisten() {
        this.props.history.listen((location, action) => {
            if (this.props.appError) this.props.clearErrorsAction();
        });
    }

    toogleCartDock() {
        this.setState({ cartDockVisible: !this.state.cartDockVisible })
    }



    toggleAddedToCart = function (title, subtitle) {
        this.setState({ popupTitle: title, popupSubtitle: subtitle });
    }






    refreshCustomerLocation() {
        if (this.props.customerlocation) {
            let { customerlocation, getPlaceByID } = this.props;
            let { placeId } = customerlocation;
            if (placeId) getPlaceByID(placeId);
        }
    }

    startup() {
        this.unsubscribe();



        // first cleanup, remove selected subscriber and selected item, let it be decided by the route
        const { setAvailableSubscribersAndMarketplaceInfo, customerlocation } = this.props;
        let cL = customerlocation?.id //get(customerlocation, 'id')
        if (!cL) {
            this.setState({ loading: false });
            return;
        }  // only download the info if we have a customer locaiton!!

        const unsubscribePlazas = onSnapshot(doc(db, "zone", cL), async (_doc) => {
            if (_doc.exists()) {
                var arrPlazas = [];
                let zone = _doc.data();
                const { marketplaceclosed, marketplacemessage } = zone;
                let plazas = zone.plaza;
                let count_plazas = plazas.length;
                let availableS = {}
                var subscriberinfo;

                let idx = 0;
                for (const p of plazas) {
                    // plazas.forEach((p, idx) => {


                    let d = await getDoc(p.plaza);
                    let plaza = d.data();
                    plaza.subscribers.forEach(function (s) {
                        s.latestordertime = p.orderby;
                        s.deliverytime = p.deliverytime;
                    })
                    arrPlazas.push(...plaza.subscribers);
                    //**   got everything  */
                    if (idx === count_plazas - 1) {
                        arrPlazas = orderBy(arrPlazas, ['sort', 'name']);
                        subscriberinfo = { subscriberinfo: arrPlazas }
                        arrSubscriberToObject(subscriberinfo, availableS);
                        setAvailableSubscribersAndMarketplaceInfo({ availableSubscribers: availableS, marketplaceclosed, marketplacemessage })
                    }
                    idx++;


                };

            } else {
                console.error('Attempted to download an Plaza Info for unavaialble customer locaiton');
                this.setState({error: 'document not found'})
            }
            this.setState({ loading: false });
        });
        this.setState({ unsubscribePlazas })
    }

    unsubscribe() {
        this.state.unsubscribePlazas && this.state.unsubscribePlazas();
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to="/checkout" />;
        }

        if (this.state.loading) return <div className="spinner vh-100">
            <Spinner />
        </div>

        if (this.state.error) {
            console.error('💣💣💣',this.state.error);
            if(this.state.error === 'document not found'){
                // return <div>unable to reach server, check your internet connction.</div>
                return <Errored >
                    <div>unable to reach server, check your internet connction or reload this page.</div>
                </Errored>
            }
            return <div className="spinner vh-100">
            <div>Error Loading . . .</div>
        </div>
        }


        if (this.state.errorInfo) {
            console.log("TCL: render -> this.state.errorInfo", this.state.errorInfo)
            return (
                <div>
                    <button
                        onClick={() => {
                            Sentry.showReportDialog();
                        }}>Report feedback
                    </button>
                    <h2>Something went wrong.</h2>
                    We have been notified about this error.
                </div>
            );
        }
        return this.props.children({
            ...this.props,
            toogleCartDock: this.toogleCartDock,
            cartDockVisible: this.state.cartDockVisible,
            sizeOfDock: this.state.sizeOfDock,
            toggleAddedToCart: this.toggleAddedToCart,
            popupTitle: this.state.popupTitle,
            popupSubtitle: this.state.popupSubtitle

        })


    }
}

const mapStateToProps = (state) => {
    const { nextUpdate, marketplaceinfo, customerlocation,
        // marketplacemenu, selectedSubscriber, menuversion, availableSubscribers, 
        appError } = state.marketplaceReducer;
    return ({
        nextUpdate, marketplaceinfo, customerlocation, appError
    })
};



//****************************** */
// AZURE INSIGHTS
//****************************** */
var reactPlugin = new ReactPlugin();
//****************************** */
// AZURE INSIGHTS
//****************************** */



export default withRouter(connect(mapStateToProps, {
    __updateSubscribersExpiry_NEW,
    setAppVersion,
    clearErrorsAction, resetSpinnerAction, getPlaceByID,
    setAvailableSubscribersAndMarketplaceInfo, setMarketplaceInfo,
    resetSelectedSubscriberNItem,
})(
    withAITracking(reactPlugin, AppBoundary)
));