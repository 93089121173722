import React from 'react';
import CartLine from '../CartLine';
import './SCart.css';
import  {DeliveryCountdown} from '../DeliveryCountdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock} from '@fortawesome/free-regular-svg-icons';


const SCartHeader = ({scart, subscriberInfo}) =>{
    if(!subscriberInfo) return (<div>missing subscriberInfo</div>);
    const restaurant_logourl = (id: string) => (`https://togonowloc.imgix.net/${id}.jpg?w=75`) ;
    const {subscriberid, expiry } = subscriberInfo;
    const {fulfill_today, delivery_f, nextDeliveryBy_f} = expiry;
    const { orderby: nextOrderbyToday} = subscriberInfo.expiry;
    const restaurantName=(subscriberInfo  ? subscriberInfo.name : undefined);
    const mins_15 = 1000 * 60 * 35; // ms x sec x mins
    return(
        <React.Fragment>        
            <li className="d-flex align-items-center mt-3 mb-1">
                {/* <img alt="" className="img-thumbnail"  src={restaurant_logourl(subscriberid)} /> */}
                <div className="ms-2">
                    <DeliveryCountdown {...{nextOrderbyToday, onlyShowWithin: mins_15, 
                        textFormat: ' my-1  text-left ',
                        showAlert: true
                    }} />
                    {subscriberInfo && !fulfill_today && 
                        <small className="text-danger" style={subscriberInfo && subscriberInfo.disabled ? {textDecoration: 'Line-Through'} : null}>
                            <FontAwesomeIcon icon={faClock}  color="red"  /> Delivery {nextDeliveryBy_f}.
                        </small>}
                    <h4 className="my-0">{restaurantName}</h4>
                    {subscriberInfo && fulfill_today && 
                        <small className="text-muted" style={subscriberInfo && subscriberInfo.disabled ? {textDecoration: 'Line-Through'} : null}>
                            <FontAwesomeIcon icon={faClock}  color="gray"  /> Delivery {delivery_f}.
                        </small>}
                </div>
                <h4 className="ms-auto me-2">{scart.getSubscriberTotal}</h4>
            </li>
        </React.Fragment>
    )
}

const SCart = (props) => {
    const {scart, changeQtyItemInCart, isAbstract, subscriberInfo} = props;
    const isStandAlone = scart.cartItems.length >= 2;
    return (
        <div  className={`${subscriberInfo && subscriberInfo.disabled && 'grayout'}`}    >
            <SCartHeader {...{scart,subscriberInfo }} />
            {!isAbstract  &&
                scart.cartItems.map((itemInCart, idx) => {
                return (
                    <CartLine key={itemInCart.item_ID + '_' +  idx} 
                        {...{itemInCart, changeQtyItemInCart, isAbstract, isStandAlone}} />
                );
            })}
        </div>
    );
};



export default SCart;