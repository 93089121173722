import React from 'react';
import { Alert } from 'reactstrap';

export const __AlertLine = (props) => {
    const { appError, errorCorrectAction, errorCorrectLabel } = props;
    let aError = (appError ? (appError.info || appError) : null)
    return (
        aError ?
            <div className="container m-2 alert alert-danger alert-dismissible">
                <strong className="col-10 m-10">{aError}</strong>
                {errorCorrectAction && errorCorrectLabel && <button className=" col-2 m-2" onClick={errorCorrectAction}>{errorCorrectLabel}</button>}
            </div>
            :
            null
    )
}
export const AlertLine = ({ appError, errorCorrectAction, errorCorrectLabel, color = 'danger' }) => {
    let aError = (appError ? (appError.info || appError.message || appError) : null)


    return (
        aError ?
                <Alert className={'mx-2 my-2'} color={color} >
                    {aError}{' '}
                    <a
                        className="alert-link"
                        href="#"
                        onClick={errorCorrectAction}
                    >
                        {errorCorrectLabel}
                    </a>
                </Alert>
            :
            null
    )
}

