import React from 'react';
import {get} from 'lodash';
import {DeliveryCountdown} from './DeliveryCountdown';
import {deliveryTimes} from '../redux/utils/aggregate'
import Timer from './tinyparts/Timer';

const  MarketplaceBanner = ({ upcomingDeliveries, nextOrderbyToday, smallScreen})=>{
    if(!upcomingDeliveries) return null;
    const isPlural = get(upcomingDeliveries, 'length') > 1;
    
    const { formattedDeliveriesToday, strWhen } = deliveryTimes(upcomingDeliveries);
    return (
        <DeliveryCountdown {...{nextOrderbyToday, onlyShowWithin: 60*60*1000, smallScreen}} >
               
            <span id='pre_countdown' className='me-2'   >
                Upcoming deliver{isPlural ? 'ies' : 'y'}{strWhen !== '' ? ' ' + strWhen : ''}
                {formattedDeliveriesToday}{isPlural && ' (depending on the restaurant)'}.</span>
        </DeliveryCountdown>
    );
}
export {MarketplaceBanner}