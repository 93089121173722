import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard} from '@fortawesome/free-regular-svg-icons';

export default function({updateCheckoutAction,
    checkout,
    errorName,errorEmail, errorPhone, sizeOfInput
}) { 
    
    function handleChange(e) {
        updateCheckoutAction({[e.target.id]: e.target.value});
    }
    

    const formstyle = `form-control form-control${sizeOfInput ||''}`;
    const inputErrorBorder = (errored)=> errored ?  ' border border-danger' :'';
    return (
    <div className="d-flex mb-4 p-2">
        <div className="flex-grow-1 border-left border-thick border-success p-2" >
            <h3 className="font-weight-bold text-muted">
                <FontAwesomeIcon icon={faAddressCard}  className='me-2'  color="green" />{' '}
                Order Info
            </h3>
            <div className="form-group">
                <label htmlFor="name">Name:</label>
                {errorName && <span id="nameError" className="form-text text-danger">{errorName}</span>}
                <input type="text" className={formstyle + inputErrorBorder(errorName)} id="name" 
                    value={checkout.name ? checkout.name : '' }
                    aria-describedby="nameHelp" 
                    onChange={handleChange}
                />
                <small id="nameHelp" className="form-text text-muted">
                    Will be abbreviated on the bag label. e.g. John S.</small>
            </div>
            <div className="form-group">
                <label htmlFor="email">Email:</label>
                {errorEmail && <p id="emailError" className="form-text text-danger">{errorEmail}</p>}
                <input type="email" className={formstyle+ inputErrorBorder(errorEmail)}
                    value={checkout.email ? checkout.email : ''}
                    onChange={handleChange}
                    id="email" aria-describedby="emailHelp"  />
                <small id="emailHelp" className="form-text text-muted">
                    Receipt and order updates will be sent to this address. We never share your email.</small>
            </div>
            <div className="form-group">
                <label htmlFor="phone">Phone:</label>
                {errorPhone && <p id="phoneError" className="form-text text-danger">{errorPhone}</p>}
                <input type="text" className={formstyle + inputErrorBorder(errorPhone)}
                    value={checkout.phone ? checkout.phone : ''}
                    onChange={handleChange}
                    id="phone" aria-describedby="phoneHelp"  />
                <small id="phoneHelp" className="form-text text-muted">If an issue arises, we will call or text this number.</small>
            </div>                    
        </div>
    </div>
)
}