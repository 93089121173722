import React from 'react';
import {ShowMarkupTag, FormatedOptionValue} from './tinyparts';
import { useSelector, useDispatch} from 'react-redux';
import { selectOV, selectSubOV} from "../redux/actions/marketplaceActions.js";
import {get} from 'lodash';


export const MultipleSelect = ({OV, isChecked , clickedOV} ) => { // Radiobox
    const dispatch = useDispatch();
    const calculatedmarkup = Number.parseFloat(OV.optionvalue_markup) || 0;
    return (
    <div className="inputstyle me-2 b1"  >
        <input  type="radio" id={clickedOV}    checked={isChecked} onChange={() => dispatch(selectOV(clickedOV))}   />
        <label className='d-block' htmlFor={clickedOV}  style={ {'paddingLeft' : '41px', 'textIndent': '0px'}}>
            <FormatedOptionValue optionvalue_value={OV.optionvalue_value}  />
            <ShowMarkupTag {...{calculatedmarkup}} />
        </label>
    </div>
    );
}
export const MultipleSelectSubOption = ({so , clickedSO, isChecked }) => { // Radiobox
    const dispatch = useDispatch();
    const calculatedmarkup = Number.parseFloat(so.markup) || 0;
    return (
    <div className=" inputstyle me-2 b1"  >
        <input  type="radio" id={clickedSO}    checked={isChecked}  onChange={() => dispatch(selectSubOV(clickedSO))}  />
        <label className='d-block' htmlFor={clickedSO}  style={ {'paddingLeft' : '41px', 'textIndent': '0px'}}>
            <FormatedOptionValue optionvalue_value={so.title}  />
            <ShowMarkupTag {...{calculatedmarkup}} />
        </label>
    </div>
    );
} 