import React, {   useState, useEffect, useRef, createRef} from 'react';
import {  get, has } from 'lodash';
import MenuItem from './MenuItem';
import SubscriberHeader from './SubscriberHeader';
import {Spinner, Errored} from './tinyparts/Spinners'
import { useSelector} from 'react-redux';



export default function({
    subscriberid,
    addedItemTobag,  clearSelectedSubscriber,
    appBoundary,
}){


    const   {marketplaceclosed, 
        subscriberinfo, subscribermenu, 
        currentSubscriberMenu_loading
    } = useSelector(({ marketplaceReducer }) => ({
        marketplaceclosed: get(marketplaceReducer , 'marketplaceclosed'),
        subscriberinfo: get(marketplaceReducer, `availableSubscribers[${subscriberid}]`),
        subscribermenu :get(marketplaceReducer, `availableSubscribers[${subscriberid}].menu`),
        currentSubscriberMenu_loading :get(marketplaceReducer, `currentSubscriberMenu_loading`),
    }));


    const [sectionRefs, setSectionRefs] = React.useState([]);
    // const [loading, setLoading] = React.useState(true);
    const headerRef = useRef(null);

    const [v, setV] = useState(null);   //  menu.Groups[0].groupTitle

    const getDimensions = (ele) => {
        const { height } = ele.getBoundingClientRect();
        const offsetTop = ele.offsetTop;
        const offsetBottom = offsetTop + height;
      
        return {
          height,
          offsetTop,
          offsetBottom
        };
      };
      
    function handleChange(_, value){
        var element = document.getElementById(value);
        const offset = 45;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
        setV(value);
    }      

    const scrollBind = {handleChange, v};


    const handleScroll = () => {    
        if(!headerRef  || !headerRef.current ||  sectionRefs.length === 0) return;
        const { height: headerHeight } = getDimensions(headerRef.current);
        
        const scrollPosition = window.scrollY + headerHeight;
        const selected = sectionRefs.find((ref) => {
            const ele = ref.current;
            if (ele) {
                const { offsetBottom, offsetTop } = getDimensions(ele);
                return scrollPosition > offsetTop && scrollPosition < offsetBottom;
            }
        });
        if(has(selected, 'current')){
            const {id} = selected.current;
            if(v !== id) setV(id);
        }
    }

    useEffect(() => {
        if(subscribermenu && subscribermenu.Groups){
            let arrRefs = subscribermenu.Groups.map((_, i) =>  createRef())
            setSectionRefs(arrRefs);
        }
    }, [subscribermenu])

    useEffect(() => {
        if(sectionRefs.length > 0){
            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            }
        }else{
            window.removeEventListener("scroll", handleScroll);
        }
    }, [sectionRefs]);

    if(currentSubscriberMenu_loading === 'loading') return   <div className="spinner vh-100">  
        <Spinner />  
    </div> 
    if(currentSubscriberMenu_loading === 'error' || currentSubscriberMenu_loading?.name === 'Error' )   {
        return <div className="spinner vh-100">  
        <Errored message={currentSubscriberMenu_loading?.message} />  
    </div> }



    

    return <> 
        <SubscriberHeader   key={get(subscriberinfo, 'subscriberid', 's_header')}   {...{ subscriberinfo, clearSelectedSubscriber, headerRef, marketplaceclosed, ...scrollBind }} />
        {subscribermenu && subscribermenu.Groups.map((group, i) => 
            <span id={`${group.groupTitle}`} ref={sectionRefs[i]}  key={`${group.groupID}`}>
                <h5 id={`${group.groupTitle}_header`} className="row justify-content-center h3 mb-1" key={`${group.groupID_header}`}  >{group.groupTitle}</h5>
                <div id={`${group.groupTitle}_items`} className="row mx-1 justify-content-center" key={`g_${group.groupID}`}>
                    {group.Items ? (group.Items.map(menuItem =>     <MenuItem  {...{ menuItem, addedItemTobag, appBoundary }} key={`s_${menuItem.item_ID}`} />)) : <div>Coming Soon</div>}
                </div>
            </span>
        )} 
    </>
}
