import React, { useEffect, useState } from 'react';

import moment from 'moment-timezone';
import renderer from './CountdownRenderer';
import Countdown from 'react-countdown';

import Timer from './tinyparts/Timer';

export const DeliveryCountdown = ({
    onlyShowWithin = 3600001,
    ordercuttoff, nextOrderbyToday,
    children, alwaysShow = false,
    smallScreen,
    textFormat = 'pt-1 h3 text-center lead',
    counterTextFormat = 'text-danger',
    showAlert = false
}) => {

    const [showCountDown, setShowCountDown] = useState(false);
    const [dte_nextorderbytoday, setDte_nextorderbytoday] = useState(null);


    

    useEffect(()=>{
        let now = moment();
        setDte_nextorderbytoday(moment(nextOrderbyToday || ordercuttoff));
        // setDte_nextorderbytoday(new Date(nextOrderbyToday || ordercuttoff));
        let mom_nextorderbytoday = moment(nextOrderbyToday || ordercuttoff).tz("America/Los_Angeles").utc();
        let shouldShow = mom_nextorderbytoday.diff(now);
        // console.log("🚀 ~ shouldShow", shouldShow)
        setShowCountDown( alwaysShow || (shouldShow > 0 && shouldShow < onlyShowWithin))
    }, [onlyShowWithin, nextOrderbyToday, ordercuttoff])

    if (!children && !showCountDown) return       null;    // if no children or no need to show counter, return null so we don't get an empty div 



        // console.log("🚀 ~ dte_nextorderbytoday", dte_nextorderbytoday)
        // console.log("🚀 ~ onlyShowWithin", onlyShowWithin)
    return <div className={`d-flex justify-content-center gap-2 ${textFormat}  ${smallScreen ? 'font-weight-bold small' : ''}`} >
        {children}
        <Timer secondsOrMoment={dte_nextorderbytoday}/> 
    </div>
};