import React, { Component, useState, useContext } from 'react';
import { AuthContext } from '../../Auth';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import DROPDOWN from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { get } from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCreditCard, faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { faScroll, faSignOutAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/actions/marketplaceActions';


export const LoginWidget = () => {
    let history = useHistory();

    const handlePaymentMethodsClick = () => {
        history.push({ pathname: '/Payment' });
    }

    const handleOrdersClick = () => {
        history.push({ pathname: '/Orders' });
    }
    const { currentUser, currentProfile, loading, signOut } = useContext(AuthContext) || {};
    
    
    let variant = 'success' // 'link'
    let size = 'sm'

    if(loading) return <FontAwesomeIcon icon={faSpinner} color={'white'} spin />

    if(currentUser){
        return <AccountInfoList {...{
            handleOrdersClick, signOut,
             handlePaymentMethodsClick
        }} />
    }
    
    return <>
            <Button variant={variant}
                className={'m-md-e-2'}
                onClick={() => { history.push('/signin') }}
                size={size} >
                <FontAwesomeIcon icon={faUser} color={"white"} />
            </Button>
 
        </>

    

    const render = () => {
        const { currentUser, currentProfile, loading, signOut } = this.context || {};
        let userProfileFullName = get(currentProfile, 'name', '');
        const {
            sizeOfButton, sizeOfFA } = this.props;
        const {
            accountDropDownOpen, toggle_accountDropDown,
        } = this.props
        const { handlePaymentMethodsClick, handleOrdersClick } = this;

        let btnClassRegister = `btn btn-success me-2  btn${sizeOfButton || ''}`;

        return (loading ? <FontAwesomeIcon icon={faSpinner} color={'white'} spin /> : currentUser ?
            <AccountInfoList {...{
                handleOrdersClick, signOut, currentUser,
                userProfileFullName, handlePaymentMethodsClick, accountDropDownOpen, toggle_accountDropDown
            }} />
            :
            <FontAwesomeIcon icon={faUser} color={"white"}
                onClick={() => { this.props.history.push('/signin') }}
                className={`col  fa${sizeOfFA}`} />
        );
    }
}

function AccountInfoList({
     handlePaymentMethodsClick, handleOrdersClick,
     
}) {
    let history = useHistory();
    const { currentProfile, currentUser } = useContext(AuthContext) || {};
    const { signOut } = useContext(AuthContext);
    const [processing, setProcessing] = useState(false);
    const dispatch = useDispatch();

    const doSignOut = async () => {
        await signOut();
        await dispatch(logOut());

    }

    const style = {
        cursor: 'pointer',
        textDecoration: 'underline'
    }
    let variant = 'success' // 'link'
    let size = 'sm'

    return (<DROPDOWN>
        <DROPDOWN.Toggle variant={variant} id="dropdown-basic">
            <FontAwesomeIcon icon={faUser} color={"white"} />
        </DROPDOWN.Toggle>
        <DROPDOWN.Menu>
            {currentProfile && <DROPDOWN.Item>Hi, <a style={style} onClick={e => history.push('/profile')} href='#'>{currentProfile?.name || ''}</a></DROPDOWN.Item>}
            <DROPDOWN.Item onClick={e => history.push('/profile')}><FontAwesomeIcon className='me-2' icon={faAddressCard} color={"green"} />Profile</DROPDOWN.Item>
            <DROPDOWN.Item onClick={handlePaymentMethodsClick}  ><FontAwesomeIcon className='me-2' icon={faCreditCard} color={"green"} />Payment</DROPDOWN.Item>
            <DROPDOWN.Item onClick={handleOrdersClick}  ><FontAwesomeIcon className='me-2' icon={faScroll} color={"green"} />Orders</DROPDOWN.Item>
            <hr />
            <DROPDOWN.Item onClick={doSignOut}  ><FontAwesomeIcon className='me-2' icon={faSignOutAlt} color={"green"} />Sign Out</DROPDOWN.Item>
        </DROPDOWN.Menu>
    </DROPDOWN>);



    // return (<Dropdown size="sm" isOpen={accountDropDownOpen} toggle={toggle_accountDropDown} className={''}>
    //     <DropdownToggle color="success" className="" caret >

    //         <Button variant={variant} size={size} active >
    //             <FontAwesomeIcon icon={faUser} color={"white"} />
    //         </Button>

    //     </DropdownToggle>
    //     <DropdownMenu >
    //         {currentProfile && <DropdownItem header>Hi, <a style={style} onClick={e => history.push('/profile')} href='#'>{currentProfile?.name}</a></DropdownItem>}
    //         {currentUser && <DropdownItem onClick={e => history.push('/profile')}   ><FontAwesomeIcon className='me-2' icon={faAddressCard} color={"green"} />Profile</DropdownItem>}
    //         <DropdownItem onClick={handlePaymentMethodsClick}   ><FontAwesomeIcon className='me-2' icon={faCreditCard} color={"green"} />Payment</DropdownItem>
    //         <DropdownItem onClick={handleOrdersClick}           ><FontAwesomeIcon className='me-2' icon={faScroll} color={"green"} />Orders</DropdownItem>
    //         <hr />
    //         <DropdownItem onClick={doSignOut}  ><FontAwesomeIcon className='me-2' icon={faSignOutAlt} color={"green"} />Sign Out</DropdownItem>
    //     </DropdownMenu>
    // </Dropdown>)
};