// CardSection.js
import React from 'react';
// import {CardElement} from 'react-stripe-elements';
 

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: 'green',  //'#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

const __lgScreenFontSize = '18px';
// const __smScreenFontSize = '14px';


const CardSection = ({setCardElement, doReset}) => {

  function handleReady(element){
    setCardElement(element);
    doReset();
  };
  return (
    <CardElement 
      {...createOptions(__lgScreenFontSize)}
      onReady={(el) => 
        {
          el.focus();
          handleReady(el)

        }
      }  
    >
    </CardElement>
  );
};
 
export default CardSection;