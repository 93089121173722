import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, onAuthStateChanged, getRedirectResult, connectAuthEmulator } from 'firebase/auth';

var app, auth, db;

try {

    app = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
    });

    auth = getAuth(app);
    db = getFirestore(app)
    if (process.env.NODE_ENV === 'development') {
        console.log('👋👋👋 Firebase traffic is being routed to local emulator');
        connectAuthEmulator(auth, process.env.REACT_APP_FIREBASE_AUTH_DOMAIN);
        connectFirestoreEmulator(db, 'localhost', 8080);
    }

} catch (e) {
    console.log('💣Error from base.js')
    console.log('💣 e.message:', e.message)
    console.log('💣 e.stack:', e.stack)
    console.log('💣 e:', e)

}

export { auth, db };