// Import FirebaseAuth and firebase.
import React from 'react';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// import firebase from 'firebase';  // eslint-disable-line no-unused-vars

import { withRouter } from "react-router-dom";


const Login = () => {
  // let history = useHistory();
  return (
    <div className="auth-wrapper mt-4">
    <div className="auth-inner">
    <form>
    <h3>Sign In</h3>

    <div className="form-group">
        <label>Email address</label>
        <input type="email" className="form-control" placeholder="Enter email" />
    </div>

    <div className="form-group">
        <label>Password</label>
        <input type="password" className="form-control" placeholder="Enter password" />
    </div>

    <div className="form-group">
        <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="customCheck1" />
            <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
        </div>
    </div>

    <button type="submit" className="btn btn-success btn-block">Submit</button>
    <p className="forgot-password text-right">
        Forgot <a href="#">password ?</a>
    </p>
</form>

</div>
  </div>)
}

export default withRouter(Login);