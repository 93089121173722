import React from 'react';    // eslint-disable-line no-unused-vars
import Loading from './loading.svg';
import {LoadingStyle} from './tinyparts/LoadingStyle'


export default class Reset extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            doneReset: false, 
            resetting: false
        };
        this.resetProfile = this.resetProfile.bind(this);
    }
    
    resetProfile(){
        this.setState({resetting: true})
        window.localStorage.clear()
        setTimeout(()=>this.setState({doneReset : true, resetting: false}),2000);

        
    }

    render(){
        if(this.state.resetting){
            return  <div style={LoadingStyle} ><img src={Loading} alt="loading"/></div>
        }
        
        if(this.state.doneReset){
            return <div style={LoadingStyle} >
            <div>
            Reset Successful!<br></br>
            <button onClick={()=>{window.location.href = '/';}}>Return To ToGoNow Marketplace</button>
            </div>
            </div>
        }
        if(!this.state.resetting && !this.state.doneReset){
            return <div style={LoadingStyle} >
            <div>
            <button onClick={this.resetProfile}>Reset Profile</button><br></br>
            this will reset the your profile information saved on this browser.
            </div>
            </div>
        }
      
    }
  }