import {isEqual, sumBy, findIndex  } from 'lodash';
import  update              from 'immutability-helper';
import accounting from 'accounting';
import ItemInCart from './ItemInCart';
import {throwIfMissing} from '../../togonowutils'


class SubscriberCart {
    constructor(initWithParameter = throwIfMissing()  ){
        if(Array.isArray(initWithParameter)){
            // objects in array are in json or of type
            if(!initWithParameter.isArray && !initWithParameter.every(i => i instanceof ItemInCart)){
                // objects are of json format
                this.createCartObjectsfromJSON(initWithParameter);
            }else {
                // objects are in ItemInCart format
                this.sid = initWithParameter[0].subscriberid;

                this.cartItems = initWithParameter;
            }
        }else{

            // we have a JSON object propably loaded from localstorage
            if(initWithParameter.sid && initWithParameter.cartItems && 
                Array.isArray(initWithParameter.cartItems)){
                this.sid = initWithParameter.sid;
                this.createCartObjectsfromJSON(initWithParameter.cartItems);
            }else{
                throw new Error('supplied parameter is not in the expected format:\n' + JSON.stringify(initWithParameter));
            }
        }
        this.findExactItemIdx = this.findExactItemIdx.bind(this);
    }
    

    createCartObjectsfromJSON(cItemsJson){
        let convertToItemInCart = [];
        cItemsJson.map((citem, idx)=>{
            convertToItemInCart.push( new ItemInCart(citem));
            return convertToItemInCart;
        });
        this.cartItems = convertToItemInCart;
        // this.sid = convertToItemInCart[0].item.subscriberid;
    }
    
    addItemToCart(itm, qty=1){
        if(itm.subscriberid !== this.sid) {
            throw new Error(
                `attempted to add an item for different subscrbier. 
                current sid: ${this.sid}, 
            item sid: ${itm.subscriberid}`);
        }
        let idx = this.findExactItemIdx(itm);
        if(idx > -1 ){
            // found Item
            let targetline = this.cartItems[idx];
            targetline.addQty(qty);
            return this.cartItems = update(this.cartItems, 
                {[idx]: {$set: targetline}});
        }
        
        return this.cartItems = update(this.cartItems, {$push : [new ItemInCart(itm, qty)]});
    }
            
    removeItemInCart(itemInCart = throwIfMissing()){
        let idx  = this.findItemInCartIndex(itemInCart);
        if(idx > -1 ){
            // if it is the last item in the subscriber cart, return an error, cart has to be destroyed from the redux            
            if(this.cartItems.length === 1){
                throw new Error(
                    `Attempted to remove the last item in the cart, has to be removed from the Marketplace reducer instead
                        current sid: ${this.sid}
                        current item sid: ${itemInCart.item_Title} (${itemInCart.item_ptr})`
                    );
            }else{
                // return cart items array with the itm removed
                return this.cartItems = [
                    ...this.cartItems.slice(0, idx),
                    ...this.cartItems.slice(idx + 1)
                ]
            }
        }
        throw new Error(`item ${itemInCart.item_ptr} not found`);
    }

    removeItem(itm = throwIfMissing()){
        let idx = this.findExactItemIdx(itm);
        if(idx > -1 ){
            // if it is the last item in the subscriber cart, return an error, cart has to be destroyed from the redux            
            if(this.cartItems.length === 1){
                throw new Error(
                    `Attempted to remove the last item in the cart, has to be removed from the Marketplace reducer instead
                        current sid: ${this.sid}
                        current item sid: ${itm.item_Title} (${itm.item_ID})`
                    );
            }else{
                // return cart items array with the itm removed
                return this.cartItems = [
                    ...this.cartItems.slice(0, idx),
                    ...this.cartItems.slice(idx + 1)
                ]
            }
        }
        throw new Error(`item ${itm.item_ID} not found`);
    }

    setItemQuantity(itm=throwIfMissing(),qty){
        // uses a full item
        if(qty < 0) throw new Error('quantity must be a number bigger than 0');
        let idx = this.findExactItemIdx(itm);
        if(idx > -1){
            // found exact item (with options)
            let targetline = this.cartItems[idx];
            let newCartItem = new ItemInCart(targetline, qty) ;
            let thisCartiTems = update(this.cartItems,  {[idx]: {$set : newCartItem}});
            return this.cartItems= thisCartiTems;
        }else{
            // item not found, just add it
            throw new Error(
                `Attempted to change quantity of an  item that is not in the cart
                    current sid: ${this.sid}
                    current item sid: ${itm.item_Title} (${itm.item_ID})`
                );
        }
    }

    setItemInCartQuantity(itemInCart=throwIfMissing(),qty){
        // using an Item In Cart
        if(qty < 0) throw new Error('quantity must be a number bigger than 0');
        let idx = this.findItemInCartIndex(itemInCart);
        if(idx > -1){
            let targetline = this.cartItems[idx];
            let newCartItem = new ItemInCart(targetline, qty) ;
            let thisCartiTems = update(this.cartItems,  {[idx]: {$set : newCartItem}});
            return this.cartItems= thisCartiTems;
        }else{
            // item not found, just add it
            throw new Error(
                `Attempted to change quantity of an  item that is not in the cart
                    current sid: ${this.sid}
                    current item sid: ${itemInCart.item_Title} (${itemInCart.item_ptr})`
                );
        }

    }

    getItems =  ()=>{
        return this.cartItems.map(i => i.item);
    }

    deepCompareSelectedOVs = (itm, cartline,)=>{
        if( !(`${itm.subscriberid}.${itm.item_ID}` === cartline.item_ptr)) return false;
        if((itm.selectedOVs === undefined     || itm.selectedOVs.length === 0) 
        &&  (cartline.selectedOVs === undefined || cartline.selectedOVs === null || cartline.selectedOVs.length === 0)){
            return true;
        }
        if(itm.selectedOVs && cartline.selectedOVs && itm.selectedOVs.length === cartline.selectedOVs.length){
            return isEqual(itm.selectedOVs.sort(), cartline.selectedOVs.sort());
        }else return false;
    }

    findExactItemIdx = (itm)=>{
        const _deepCompareSelectedOVs = this.deepCompareSelectedOVs;
        let found =  findIndex(this.cartItems, function(cartline) { 
            return _deepCompareSelectedOVs(itm, cartline);
        });
        return found;
    }


    findItemInCartIndex =(itemInCart)=>{
        let foundidx = findIndex(this.cartItems, itemInCart)
        return foundidx;
    }

    get getSubscriberTotal(){
        return  accounting.formatMoney(sumBy(this.cartItems, s =>  parseFloat(s._linetotal)   ));
    }    
    get accounting_getSubscriberTotal(){
        return accounting.toFixed(sumBy(this.cartItems, function(s) { return parseFloat(s._linetotal)}),2);
    }     

    trimmed = ()=>{
        let xxx = this.cartItems.map(cI => cI.trimmed());
//  console.log("xxx ", xxx);
        return {sid: this.sid, cartItems :xxx};
    }
}


export { ItemInCart, SubscriberCart  };