import React from 'react';
import {ovMap } from '../redux/utils/aggregate';
import  './inputstyle.css'
import {ShowMarkupTag, FormatedOptionValue} from './tinyparts';
import { useSelector, useDispatch} from 'react-redux';
import { selectOV} from "../redux/actions/marketplaceActions.js";


export const SingleSelect = (props) =>{    // Checkbox
    const dispatch = useDispatch();
    const {OV, 
        isChecked, 
        clickedOV,  OGid} = props;
    const clickeOVMapped = ovMap(clickedOV, OGid);
    const calculatedmarkup = Number.parseFloat(OV.optionvalue_markup) || 0;

    return (
        <div className={`inputstyle me-2 c1`}  >
            <input type="checkbox" 
                id={clickeOVMapped}  
                checked={isChecked} 
                onChange={()=>dispatch(selectOV(clickeOVMapped))} 
            />
            <label htmlFor={clickeOVMapped} style={ {'paddingLeft' : '41px', 'textIndent': '0px'}}>
                <FormatedOptionValue optionvalue_value={OV.optionvalue_value} />
                <ShowMarkupTag {...{calculatedmarkup}} />
            </label>
        </div>)
} 


