import React, { useState, useEffect } from 'react';
import {splitBy, ovMap } from '../redux/utils/aggregate';
import {filter , isEmpty, chain} from 'lodash';
import {SingleSelect} from './SingleSelect';
import {MultipleSelect, MultipleSelectSubOption} from './MultipleSelect';
import PropTypes from 'prop-types';

export const OptionValue = ( {OVs, OGid, simultedCheckBox, hasNoIndex, selectedOV_MAP, selectOV, isPrintout, children}) => {
    
    
    
    const [filteredChildren, setFilteredChildren] = useState([]);
    
    useEffect(()=>{
        let n = []
        if(!isEmpty(selectedOV_MAP) ){
            if(!isEmpty(children)){ n= children.filter(c=> c.optionvalue_ID === splitBy(selectedOV_MAP[0], 2)) };
        }
        setFilteredChildren(n );
    }, [selectedOV_MAP])

    const rawMarkup = (optionvalue_value) =>{
        return { __html: optionvalue_value + '&nbsp &nbsp'};
    }

    let clickedOV;
    if(simultedCheckBox && isEmpty(children)){
        let isChecked = false;
        const no_OptionValue = OVs[hasNoIndex];
        const textOptionValue = (hasNoIndex === 0 ? OVs[1]  :  OVs[0] )// since it's only two values 0 or 1 we can use 
        if(!isEmpty(selectedOV_MAP)){
            let ovFromMapped = splitBy(selectedOV_MAP[0],2) ;
            if(ovFromMapped === textOptionValue.optionvalue_ID){
                isChecked = true;
            }
            if(ovFromMapped === no_OptionValue.optionvalue_ID){
                isChecked = false;
            }
        }else{
            if(textOptionValue && textOptionValue.optionvalue_isdefault){
                isChecked = true;
            }
            if(no_OptionValue && no_OptionValue.optionvalue_isdefault){
                isChecked = false;
            }
        }
        const OV = textOptionValue;
        
        clickedOV =  (isChecked ? no_OptionValue : textOptionValue);

            if(isPrintout === true){
                return (isChecked ? 
                <div>{OV.optionvalue_value}, </div> : 
                (!no_OptionValue.optionvalue_isdefault && <React.Fragment><s>{OV.optionvalue_value}</s>, </React.Fragment>)            )
            }
            if(isPrintout === 'richtext'){
                return (isChecked ? 
                `${OV.optionvalue_value},` : 
                (!no_OptionValue.optionvalue_isdefault && <React.Fragment><s>{OV.optionvalue_value}</s>, </React.Fragment>)            )
            }
        return (<SingleSelect key={OV.optionvalue_ID}    {...{OV, OGid, isChecked, selectOV, clickedOV,  selectedOV_MAP}} />)
        
    }


    var Oid;

    const listOVs = OVs.map((OV) => {
        const OVid = OV.optionvalue_ID;
        if(!Oid) Oid = OV.optionvalue_optionid;
        let isChecked2 = false;
        if((selectedOV_MAP && splitBy(selectedOV_MAP[0], 2) === OVid) || (!selectedOV_MAP[0] && OV.optionvalue_isdefault)){
            isChecked2 = true;
        }
        clickedOV =ovMap(OV, OGid); // `${OGid}.${Oid}.${OVid}`;
        if(isPrintout === true){
            return (isChecked2 && <span className="me-1">{OV.optionvalue_value}{','}</span>)     
        }
        if(isPrintout === 'richtext'){
            let keyval = Math.random();
           return (isChecked2 && 
            <span
                key={keyval}
                dangerouslySetInnerHTML={rawMarkup(OV.optionvalue_value)}
            />
        )     
        }

        return (<MultipleSelect  key={OV.optionvalue_ID}   isChecked={isChecked2}  {...{OV, selectOV, clickedOV, OGid}} />);
        
    });    


    return <>
    <div className="d-flex justify-content-start flex-wrap p-2  bg-white   border-2 border-left  border-success   a3_1">
        {[listOVs]} 
    </div>
    {filteredChildren.length > 0 && 
        <div className="d-flex justify-content-start flex-wrap p-2  bg-white   border-2 border-left  border-success   a3_1">

        {/* Generates Pick Side (Fries* , sweet fries, salad) */}
        {filteredChildren.map(c => {
    // ************ Example of c
    // c: {id: "45645645"
    // "title": "Sides",
    // option_ID: "8c4e26b3-f3e8-49cb-a415-c12a032afe6a"
    // optionvalue_ID: "46e017e8-0737-443c-9c81-93f14d480b45"
    // suboptions: (3) [{…}, {  "id": "sdfasdf",  "title": "Fries",  "markup": 0,  "isdefault": true   }, {…}]}
    // ************ examples of selected Sub OV
    //          0) OG                                              1) OID                       2) OVID                     3) CID      4) SubOID
    // '548de37d-56cc-4961-8cb2-869b248e03fb.8c4e26b3-f3e8-49cb-a415-c12a032afe6a.46e017e8-0737-443c-9c81-93f14d480b45.     45645645 .  sdfasdf'              fries               (default)
    // '548de37d-56cc-4961-8cb2-869b248e03fb.8c4e26b3-f3e8-49cb-a415-c12a032afe6a.46e017e8-0737-443c-9c81-93f14d480b45.     45645645 .  lkjsdgxcv'            sweet fries
    // '548de37d-56cc-4961-8cb2-869b248e03fb.8c4e26b3-f3e8-49cb-a415-c12a032afe6a.46e017e8-0737-443c-9c81-93f14d480b45.     435343   .  vsdfasdf'             Coke               (default)
    // '548de37d-56cc-4961-8cb2-869b248e03fb.8c4e26b3-f3e8-49cb-a415-c12a032afe6a.46e017e8-0737-443c-9c81-93f14d480b45.     435343   .  vsdfwerqwerasdf'      Diet Coke               

                // what is selected, is it fries or not the default?
                // selectedOV_Map example: ["548de37d-56cc-4961-8cb2-869b248e03fb.8c4e26b3-f3e8…e8-0737-443c-9c81-93f14d480b45.45645645.lkjsdgxcv"]
                // console.group('------ outer loop -----')
                const startWith =  `${OGid}.${c.option_ID}.${c.optionvalue_ID}.${c.id}`;
                const relatedChildren = filter(selectedOV_MAP, (selectedOVMAP)=> {
                    return       selectedOVMAP.startsWith(startWith);
                })


                
                
                var  defaultSO =   chain(c.suboptions).filter( {isdefault : true}).head().value();      // gets fries since it's default
                // console.log("%c OptionValue -> defaultSO", 'background : red', defaultSO)
            return <div  key={c.id}>
                    <div className="d-flex justify-content-start text-muted font-weight-bold">{c.title}</div>  
                    <div className={'d-flex justify-content-start'}>
                        {c.suboptions.map(so => {
                            const clickedSO = `${startWith}.${so.id}`;
                            const foundSubOVSelected = chain(relatedChildren).filter(__so => __so === clickedSO).head().value();
                            return (<MultipleSelectSubOption   key={so.id}
                                                    isChecked={relatedChildren.length > 0 ? foundSubOVSelected !== undefined : so.isdefault === true}   
                                                    {...{so, clickedSO}} />)
                    })}</div>
                {console.groupEnd()}</div>
            })}
        </div>}
    </>
}

OptionValue.propTypes = {
    OGid: PropTypes.string.isRequired,
    OVs: PropTypes.array.isRequired,
    selectOV: PropTypes.func    
}