import React from 'react';
import { has} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBell} from '@fortawesome/free-solid-svg-icons';
import { getType } from '../../togonowutils';


const RenderIcon =({icon}) =>{
    switch(icon) {
      case 'reminder':
        return <FontAwesomeIcon icon={faBell}    /> ;
      default:
        return null;
    }

  }


  const RenderAlert = ({icon, style, message})=>{
      return <div className={`text-center alert ${style || 'alert-light'}`} role="alert">
        <RenderIcon icon={icon}/> {message}
    </div>
  }
  
  

 const MarketplaceMessage = ({marketplacemessage}) => {
    if(!marketplacemessage) return null;
    const {message, icon, disabled, style} = marketplacemessage;
    if (getType( marketplacemessage) === 'string'){
        return  <RenderAlert {...{icon, style, message : marketplacemessage}}/>
    }

    if (getType( marketplacemessage) === 'object' && has(marketplacemessage, 'message')){
        
        if(disabled) return null;
        return <RenderAlert {...{icon, style, message}}/>
    }
    return null;

}


export default MarketplaceMessage;