let zone = [
  {
     "lng": -122.0694005,
     "lat": 37.3989025
  },
  {
     "lng": -122.0698404,
     "lat": 37.4008926
  },
  {
     "lng": -122.0697009,
     "lat": 37.4028572
  },
  {
     "lng": -122.0674693,
     "lat": 37.405039
  },
  {
     "lng": -122.0663535,
     "lat": 37.4078599
  },
  {
     "lng": -122.0373856,
     "lat": 37.4015702
  },
  {
     "lng": -122.0374715,
     "lat": 37.4194578
  },
  {
     "lng": -122.034918,
     "lat": 37.4268368
  },
  {
     "lng": -122.0217215,
     "lat": 37.4216391
  },
  {
     "lng": -121.9967016,
     "lat": 37.4177894
  },
  {
     "lng": -121.9914233,
     "lat": 37.4110728
  },
  {
     "lng": -121.9729697,
     "lat": 37.4177195
  },
  {
     "lng": -121.9546878,
     "lat": 37.3896286
  },
  {
     "lng": -121.9359767,
     "lat": 37.3974706
  },
  {
     "lng": -121.9265353,
     "lat": 37.3868326
  },
  {
     "lng": -121.9353763,
     "lat": 37.3822292
  },
  {
     "lng": -121.9417273,
     "lat": 37.3766023
  },
  {
     "lng": -121.953357,
     "lat": 37.3793309
  },
  {
     "lng": -121.9638717,
     "lat": 37.3817858
  },
  {
     "lng": -121.9659316,
     "lat": 37.3744879
  },
  {
     "lng": -121.9777758,
     "lat": 37.3767062
  },
  {
     "lng": -121.9880755,
     "lat": 37.3779686
  },
  {
     "lng": -122.0114219,
     "lat": 37.377489
  },
  {
     "lng": -122.0435225,
     "lat": 37.3870372
  },
  {
     "lng": -122.0509448,
     "lat": 37.3851974
  },
  {
     "lng": -122.0685851,
     "lat": 37.3916744
  },
  {
     "lng": -122.0685636,
     "lat": 37.3955612
  },
  {
     "lng": -122.0694005,
     "lat": 37.3989025
  }
];


// const ___subzones = [{"id":"aaaaa","polygon":[{"lng":"-122.0367315","lat":"37.4011964"},{"lng":"-122.0663535","lat":"37.4078599"},{"lng":"-122.0674693","lat":"37.405039"},{"lng":"-122.0697009","lat":"37.4028572"},{"lng":"-122.0683172","lat":"37.3910363"},{"lng":"-122.0509448","lat":"37.3851974"},{"lng":"-122.0435225","lat":"37.3870372"},{"lng":"-122.0323542","lat":"37.3837392"},{"lng":"-122.027376","lat":"37.3986735"},{"lng":"-122.0367315","lat":"37.4011964"},{"lng":""}]},{"id":"bbbbb","polygon":[{"lng":"-122.0373856","lat":"37.4015702"},{"lng":"-122.027376","lat":"37.3986735"},{"lng":"-122.0323542","lat":"37.3837392"},{"lng":"-122.0114219","lat":"37.377489"},{"lng":"-121.9955328","lat":"37.3775328"},{"lng":"-121.9957045","lat":"37.4033783"},{"lng":"-121.9984511","lat":"37.4090372"},{"lng":"-121.9914233","lat":"37.4110728"},{"lng":"-121.9967016","lat":"37.4177894"},{"lng":"-122.0217215","lat":"37.4216391"},{"lng":"-122.034918","lat":"37.4268368"},{"lng":"-122.0374715","lat":"37.4194578"},{"lng":"-122.0373856","lat":"37.4015702"},{"lng":""}]},{"id":"ccccc","polygon":[{"lng":"-121.9417059","lat":"37.3765767"},{"lng":"-121.9353549","lat":"37.3822036"},{"lng":"-121.9265139","lat":"37.386807"},{"lng":"-121.9359553","lat":"37.397445"},{"lng":"-121.9546664","lat":"37.389603"},{"lng":"-121.9729483","lat":"37.4176939"},{"lng":"-121.9984297","lat":"37.4090116"},{"lng":"-121.9956831","lat":"37.4033527"},{"lng":"-121.9955114","lat":"37.3775072"},{"lng":"-121.9880541","lat":"37.377943"},{"lng":"-121.9659102","lat":"37.3744623"},{"lng":"-121.9638503","lat":"37.3817602"},{"lng":"-121.9417059","lat":"37.3765767"},{"lng":""}]}];

const subzones =[{"id":"aaaaa","polygon":[{"lng":-122.0367315,"lat":37.4011964},{"lng":-122.0663535,"lat":37.4078599},{"lng":-122.0674693,"lat":37.405039},{"lng":-122.0697009,"lat":37.4028572},{"lng":-122.0683172,"lat":37.3910363},{"lng":-122.0509448,"lat":37.3851974},{"lng":-122.0435225,"lat":37.3870372},{"lng":-122.0323542,"lat":37.3837392},{"lng":-122.027376,"lat":37.3986735},{"lng":-122.0367315,"lat":37.4011964}]},{"id":"bbbbb","polygon":[{"lng":-122.0373856,"lat":37.4015702},{"lng":-122.027376,"lat":37.3986735},{"lng":-122.0323542,"lat":37.3837392},{"lng":-122.0114219,"lat":37.377489},{"lng":-121.9955328,"lat":37.3775328},{"lng":-121.9957045,"lat":37.4033783},{"lng":-121.9984511,"lat":37.4090372},{"lng":-121.9914233,"lat":37.4110728},{"lng":-121.9967016,"lat":37.4177894},{"lng":-122.0217215,"lat":37.4216391},{"lng":-122.034918,"lat":37.4268368},{"lng":-122.0374715,"lat":37.4194578},{"lng":-122.0373856,"lat":37.4015702}]},{"id":"ccccc","polygon":[{"lng":-121.9417059,"lat":37.3765767},{"lng":-121.9353549,"lat":37.3822036},{"lng":-121.9265139,"lat":37.386807},{"lng":-121.9359553,"lat":37.397445},{"lng":-121.9546664,"lat":37.389603},{"lng":-121.9729483,"lat":37.4176939},{"lng":-121.9984297,"lat":37.4090116},{"lng":-121.9956831,"lat":37.4033527},{"lng":-121.9955114,"lat":37.3775072},{"lng":-121.9880541,"lat":37.377943},{"lng":-121.9659102,"lat":37.3744623},{"lng":-121.9638503,"lat":37.3817602},{"lng":-121.9417059,"lat":37.3765767}]}];

export  { zone, subzones};


