export const  LoadingStyle = {
  // position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  width: '100vw',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white'
};