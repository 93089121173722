import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import {Spinner} from './tinyparts/Spinners';
import { AuthContext } from "../Auth";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser, loading } = useContext(AuthContext);
  {if(loading){
    return <Spinner />
  }}
  return (
    <Route
      {...rest}
      render={routeProps =>
        currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={"/signin"} />
        )
      }
    />
  );
};
const OnlyPublicRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser, loading } = useContext(AuthContext);
  {if(loading){
    return <Spinner />
  }}
  return (
    <Route
      {...rest}
      render={routeProps =>
        currentUser ? (
          <Redirect to={"/profile"} />
          ) : (
          <RouteComponent {...routeProps} />
        )
      }
    />
  );
};


export  {PrivateRoute, OnlyPublicRoute}