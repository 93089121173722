import _, { filter, flatMap, omit, find, keys, get, has, isEqual } from 'lodash';
// import defaultState from './state';
import { Expiry } from '../utils/Expiry';
import * as Sentry from '@sentry/browser';

import { ItemInCart, SubscriberCart } from '../utils/SubscriberCart'
import { getType, isJson } from '../../togonowutils';
import { loadState } from './localStorage';
// import { isNumberObject } from 'util';
import { trimUserProfile } from '../utils/dprecated_Profile.js';
import { calculateNextUpdate } from '../utils/aggregate';
// import { bindActionCreators } from 'redux';
let loadedState = loadState();
export default function marketplaceReducer(state = loadedState, action = { type: '__none__' }) {
    // handles susccessful thunk response but with error in the response body
    // e.g. action.payload.data.error = Cannot open server 'or2vd5ytz9' requested by the login. Client with IP address '97.70.116.30' is not allowed to access the server.  To enable access, use the Windows Azure Management Portal or run sp_set_firewall_rule on the master database to create a firewall rule for this IP address or address range.  It may take up to five minutes for this change to take effect.
    if (has(action, 'action.payload.data.error')) {
        const e_message = `action type: ${action.type}. Error Message: ${action.payload.data.error}`;
        let loadingstatus = {}
        switch (action.type) {
            case 'GET_AVAILABLE_SUBSCRIBERS_INFO_FULFILLED':
                loadingstatus = { sinfo_loading: 'error' }
                break;
            case 'GET_SUBSCRIBERMENU_FULFILLED':
                loadingstatus = { currentSubscriberMenu_loading: 'error' }
                break;
            case 'SUBMIT_ORDER_FULFILLED':
                loadingstatus = { checkout_validating: null }
                break;
            case 'GET_PROFILE_ORDERS_FULFILLED': case 'GET_ORDER_DETAILS_FULLFILLED':
                loadingstatus = { isFetchingProfileOrders: null }
                break
            case 'GET_ADDCARD_FULFILLED':
                loadingstatus = { isFetchingAddCard: null }
                break;
            default:
                console.warn('action.type.error is defined but did not fall under any of the action.type conditions', action.type);
        }
        return { ...state, loggerError: e_message, appError: e_message, ...loadingstatus }
    }
    let subscriberCartExisting, sid, err = {}, strError, menuversion, clone_availableSubscribers, 
        newState, newAvailableSubscribers,
        sIDs, nextDeliveryToday, nextOrderbyToday, allExpiredForToday, someExpiredForToday, 
        setDeliveryToday, setDeliveryTomorrow, arrDeliveryToday, arrDeliveryTomorrow, upcomingDeliveries, nextUpdate;
    switch (action.type) {
        // Marketplace MENU
        // case 'GET_MARKETPLACE_PENDING':
        //     return {...state, loading: 'loading', sinfo_loading: 'loading', error : null, ...__reset_marketplace_subscribersmenus()}
        case 'SET_MARKETPLACE':
            // double check the response we got is valid object, if it's not valid object, 
            // it'll be a long string which will 
            // fail iSJson so now we need to log the line error
            
            if(getType( action.payload.data) !== "object") {
                let validJson = isJson(action.payload.data, err);
                if (validJson !== true) {
                    strError = (err.error.info || err.error.message || 'Error parsing json file received!');
                    let appError, loggerError = appError = { info: strError };
                    Sentry.captureException(new Error(strError));
                    return {
                        ...state, loggerError, appError,
                        loading: 'success',
                        sinfo_loading: 'success',
                    };
                }
            }
            let { menu: marketplacemenu } = action.payload.data;
            menuversion = get(action, 'action.payload.headers["last-modified"]');
            return {
                ...state,
                loading: 'success',
                sinfo_loading: 'success',
                marketplacemenu,
                menuversion,
            }
        // *********************************************************
        // Marketplace Version and Reset 
        // *********************************************************
        case 'reset_selected_subscriber_n_item':
            return { ...state, selectedSubscriber: null, selectedItem: null }

        case 'set_app_version':
            return action.appversion !=  state.appversion ? {...state, 
                appversion : action.appversion,
                selectedSubscriber: null, selectedItem: null,
                carts : null, availableSubscribers:  null
            } : state;
        case 'reset_availableSubscribers':  // used only via chrome devtools
            return { ...state, availableSubscribers:  null, carts : null} 
        // *********************************************************
        // *** End of Marketplace Version and Reset 
        // *********************************************************                       
        case 'SET_LOCATION':
            // only change customer location if is different and not null
            if (state.customerlocation && isEqual(state.customerlocation, action.customerlocation)) return {
                ...state,
                requestFullfilled: true
            };
            return {
                ...state, 'customerlocation': action.customerlocation,
                // requestFullfilled: true
            }
        case 'CLEAR_LOCATION':
            return { ...state, 'customerlocation': null, selectedSubscriber: null, selectedItem: null, carts: {}, availableSubscribers: null, checkout: null }
        case 'GET_PLACEID_PENDING':
            return { ...state, checkout_validating: 'loading' }
        case 'GET_PLACEID_FULFILLED':
            return {
                ...state,
                'customerlocation': { ...state.customerlocation, ...action.payload.data },
                checkout_validating: null,
                checkout: {
                    ...state.checkout,
                    deliveryinputrequired: get(action.payload.data, 'deliverto.required', false)
                },
            }
        case 'GET_PLACEID_REJECTED':
            return { ...state, checkout_validating: 'error' }
        case 'DISABLE_SUBSCRIBER':
            sid = action.payload.timerName;
            if (state.availableSubscribers[sid]) {
                const x = { ...state, availableSubscribers: { ...state.availableSubscribers, [sid]: { ...state.availableSubscribers[sid], disabled: true } } };
                return x;
            } else {
                return state;
            }
        case 'UPDATE_SUBSCRIBERS_EXPIRY':
            let availableSubscribers = action.availableSubscribers;

            sIDs = keys(availableSubscribers || state.availableSubscribers);
            // if we already have a menu, keep it
            clone_availableSubscribers = { ...(availableSubscribers || state.availableSubscribers || {}) };
            nextDeliveryToday = null;
            nextOrderbyToday = null;
            allExpiredForToday = true;
            someExpiredForToday = false;
            setDeliveryToday = new Set();
            setDeliveryTomorrow = new Set();
            sIDs.map(sid => {
                let s = clone_availableSubscribers[sid];

                if (get(state, `availableSubscribers[${sid}].menu`, false) && get(state, `availableSubscribers[${sid}].menuversion`, false) &&
                    availableSubscribers[sid].menuversion === state.availableSubscribers[sid].menuversion) {
                    s.menu = get(state, `availableSubscribers[${sid}].menu`, null)
                }





                s.expiry = Expiry(s);
                const { delivery, orderby, nextDeliveryBy, fulfill_today } = s.expiry;
                if (fulfill_today === true) {
                    // *** update nextDeliveryToday and nextOrderbyToday if the new values are smaller
                    nextDeliveryToday = (nextDeliveryToday === null ? delivery : (nextDeliveryToday < delivery ? nextDeliveryToday : delivery));
                    nextOrderbyToday = (nextOrderbyToday === null ? orderby : (nextOrderbyToday < orderby ? nextOrderbyToday : orderby));
                    allExpiredForToday = false;
                    setDeliveryToday.add(delivery);
                } else {  // to be fullfileed tomorrow
                    someExpiredForToday = true;
                    nextDeliveryBy && setDeliveryTomorrow.add(nextDeliveryBy);
                }
                return null;
            });
            arrDeliveryToday = (setDeliveryToday.size > 0 ? Array.from(setDeliveryToday).sort() : null);
            arrDeliveryTomorrow = (setDeliveryTomorrow.size > 0 ? Array.from(setDeliveryTomorrow).sort() : null);
            upcomingDeliveries = arrDeliveryToday || arrDeliveryTomorrow;


            nextUpdate = calculateNextUpdate(nextOrderbyToday);
            let updatemarketplaceClosedNMessage = {}
            if (action.marketplaceclosed !== undefined) updatemarketplaceClosedNMessage.marketplaceclosed = action.marketplaceclosed;
            if (action.marketplacemessage !== undefined) updatemarketplaceClosedNMessage.marketplacemessage = action.marketplacemessage;
            return {
                ...state,
                ...updatemarketplaceClosedNMessage,
                availableSubscribers: clone_availableSubscribers,
                marketplaceinfo: {
                    ...state.marketplaceinfo,
                    nextOrderbyToday, nextDeliveryToday, allExpiredForToday, someExpiredForToday,
                    upcomingDeliveries, nextUpdate
                }
            };

        case 'CLEAR_CUSTOMER_SUBSCRIBERS':
            return { ...state, 'availableSubscribers': null, loading: null, error: null }
        // SUBSCRIBER MENU
        case 'GET_SUBSCRIBERMENU_PENDING':
            return {
                ...state,
                currentSubscriberMenu_loading: 'loading',
                availableSubscribers: {
                    ...state.availableSubscribers,
                    [action.meta.subscriberid]: { ...state.availableSubscribers[action.meta.subscriberid], menu: null, menuloaded: false }
                }
            }
        case 'GET_SUBSCRIBERMENU_FULFILLED':
            return {
                ...state,
                currentSubscriberMenu_loading: 'success',
                availableSubscribers: {
                    ...state.availableSubscribers,
                    [action.meta.subscriberid]: {
                        ...state.availableSubscribers[action.meta.subscriberid],
                        menu: action.payload.data,
                        menuloaded: true
                    }
                },
                selectedSubscriber: action.meta.subscriberid
            }
        case 'CLEAR_SUBSCRIBERS':
            return Object.assign({}, state, { availableSubscribers: null });
        case 'GET_SUBSCRIBERMENU_REJECTED': {
            return Object.assign({}, state, { currentSubscriberMenu_loading: action.payload.toJSON() || 'error' });
        }
        case 'SELECT_SUBSCRIBER':
            return Object.assign({}, state, { selectedSubscriber: action.subscriberid });

        case 'CLEAR_SELECT_SUBSCRIBER':
            return state.selectedSubscriber ? Object.assign({}, state, { selectedSubscriber: null }) : state;
        case 'SELECT_ITEM':
            return { ...state, selectedItem: (action.selectedItem ? action.selectedItem : null) }
        case 'SELECT_OV':
            {
                // console.group(  'redux seletct OV'  )
                // console.log(action.selectedOV)
                let selectedOV = action.selectedOV;
                const [OGid, Oid, OVid] = selectedOV.split('.');
                // *** item does not have any SELECTED OVS
                const relatedOG = find(state.selectedItem.OptionGroups, function (og) { return og.optionGroup_ID === OGid })
                if (relatedOG === undefined) return { ...state, 'appError': `Invalid optionGroup selected ${OGid} not found` }
                const relatedO = find(relatedOG.Options, function (o) { return o.option_ID === Oid });
                if (relatedO === undefined) return { ...state, 'appError': `Invalid option selected ${Oid} not found` }
                const relatedOV = find(relatedO.OptionValues, function (ov) { return ov.optionvalue_ID === OVid });
                // console.group('%c  **********SelectOV***********', 'background: #222; color: #bada55');  

                if (!state.selectedItem.selectedOVs) {
                    // double check the selected ov is not already isDefault
                    if (relatedOV === undefined) return { ...state, 'appError': `Invalid optionvalue selected ${OVid} not found` }
                    if (relatedOV.optionvalue_isdefault === true) {
                        return state;   // the optionvalue is already selected by default, no need to register a new OV
                    }
                    return {
                        ...state,
                        'selectedItem': {
                            ...state.selectedItem,
                            'selectedOVs': [action.selectedOV]
                        },
                        'appError': null
                    }
                } else {
                    // some selections already exists
                    // remove other optionvalues that are withing the same OptionGroup.Options
                    // add the selected optionvalue if it is not default
                    const state_selectedItem_selectedOVs = state.selectedItem.selectedOVs;
                    let removedRelatedOV = filter(state_selectedItem_selectedOVs, (sOV) => {
                        return !sOV.startsWith(`${OGid}.${Oid}.`)
                    });
                    // console.log("%c relatedOV -> removedRelatedOV", 'color : blue', removedRelatedOV)

                    if (!relatedOV.optionvalue_isdefault === true) {
                        if (relatedO.children && relatedO.children.length > 0) {

                            // repeats OVs => OV.id.subovs 
                            var result = flatMap(filter(relatedO.children, { optionvalue_ID: OVid }), item =>
                                _(item.suboptions, item.id)
                                    .filter({ isdefault: true })
                                    .map((SO) => selectedOV + '.' + item.id + '.' + SO.id)
                                    .value()
                            );
                            Array.prototype.push.apply(removedRelatedOV, result);
                        }
                        removedRelatedOV.push(selectedOV);
                    }
                    // console.log("%c relatedOV -> removedRelatedOV UPDATED**", 'color : orange', removedRelatedOV)
                    // console.groupEnd()
                    return {
                        ...state,
                        'selectedItem': {
                            ...state.selectedItem,
                            'selectedOVs': (removedRelatedOV.length > 0 ? removedRelatedOV : null)    // only keep non empty array
                        },
                        'appError': null
                    }

                }
            }
        case 'SELECT_SubOV':
            let selectedSubOV = action.selectedSubOV;
            // console.log("marketplaceReducer -> selectedSubOV", selectedSubOV)
            const [OGid, Oid, OVid, id, SubOV] = selectedSubOV.split('.');

            // console.log("marketplaceReducer -> state.selectedItem.selectedOVs", state.selectedItem.selectedOVs)
            // *** item does not have any SELECTED OVS
            // const relatedOG = find(state.selectedItem.OptionGroups, function (og) { return og.optionGroup_ID === OGid })
            // if(relatedOG === undefined) return {...state, 'appError': `Invalid optionGroup selected ${OGid} not found`}
            // const relatedO = find(relatedOG.Options, function (o) { return o.option_ID === Oid});
            // if(relatedO === undefined) return {...state, 'appError': `Invalid option selected ${Oid} not found`}
            // const relatedOV = find(relatedO.OptionValues, function (ov) { return ov.optionvalue_ID === OVid});
            // const relatedSubOv = 

            const state_selectedItem_selectedOVs = state.selectedItem.selectedOVs;
            // console.log("//relatedOV -> state_selectedItem_selectedOVs", state_selectedItem_selectedOVs)
            let removedRelatedOV = filter(state_selectedItem_selectedOVs, (sOV) => {
                return !sOV.startsWith(`${OGid}.${Oid}.${OVid}.${id}`)
            });
            // console.log("//relatedOV -> selectedSubOV", selectedSubOV)
            removedRelatedOV.push(selectedSubOV);




            //                 let x = chain(state.selectedItem.OptionGroups)
            // .map(
            //     (OG) => OG.optionGroup_ID === OGid && OG
            //     )
            //     .head()
            //     .get('Options')
            //     .filter((O)=> O.option_ID === Oid )
            //     .head()
            //     .get('children')
            //     .filter((c)=> c.id === id && c)
            //     .head()
            //     .get('suboptions')
            //     .filter((SO)=>  SO.id === SubOV )
            //     .head()
            //     .value();
            // console.log("//relatedOV -> x", x)


            return {
                ...state,
                'selectedItem': {
                    ...state.selectedItem,
                    'selectedOVs': (removedRelatedOV.length > 0 ? removedRelatedOV : null)    // only keep non empty array
                },
                'appError': null
            }




            return state;
        case 'CLEAR_SELECTIONS':
            return (state.selectedOVs !== null ? {
                ...state,
                'selectedItem': {
                    ...state.selectedItem,
                    'selectedOVs': null
                },
                'appError': null
            } : state);
        case 'RESET_MARKETPLACESUBSCRIBERMENUS':
            return { ...state, ...__reset_marketplace_subscribersmenus() }
        //**********************************/
        //********* HANDLE CART */
        //**********************************/ 
        case 'SET_COUPON':

            console.log("TCL: action.coupon", action.coupon)
            return { ...state, checkout: { ...state.checkout, couponResponse: action.coupon } };
        case 'ADD_ITEM':
            const selectedItem = state.selectedItem || action.item; // add state.selectedItem item if null then add actin.selectedItem
            if ((!selectedItem || !selectedItem.subscriberid)) {
                strError = (state.selectedItem ? 'Attempted to add selected item without a subscriberid as a property' : 'Attempted to add selected item without an item being selected');
                let appError, loggerError = appError = { info: strError };
                Sentry.captureException(new Error(strError));
                return { ...state, loggerError, appError };
            } else {
                const qty = (action.qty !== undefined ? action.qty : 1);
                sid = selectedItem.subscriberid;
                const itemincart = new ItemInCart(selectedItem, qty);
                if (state.carts[sid] === undefined) {    // do we already have a subscriber cart ???
                    const subscriberCartNew = new SubscriberCart([itemincart]);
                    return {
                        ...state,
                        'carts': {
                            ...state.carts,
                            [sid]: subscriberCartNew,
                        },
                        lastItemAddedToCart: itemincart
                    }
                } else {
                    subscriberCartExisting = state.carts[sid];
                    subscriberCartExisting.addItemToCart(selectedItem, qty);
                    return {
                        ...state,
                        'carts': {
                            ...state.carts,
                            [sid]: subscriberCartExisting,
                        },
                        lastItemAddedToCart: itemincart
                    }
                }
            }
        case 'REMOVE_ITEM':
            if (!action.item || !action.item.subscriberid) {
                strError = 'Attempted to removean item passing without having it as a parameter or subscriberid as a property of that item';
                let appError, loggerError = appError = { info: strError };
                Sentry.captureException(new Error(strError));
                return { ...state, loggerError, appError };
            };
            sid = action.item.subscriberid;
            if (state.carts[sid] === undefined) {
                strError = `attempted to remove an item from a subscriber cart that does not exist ${action.item.subscriberid} `;
                let appError, loggerError = appError = { info: strError };
                Sentry.captureException(new Error(strError));
                return { ...state, loggerError, appError };
            }

            subscriberCartExisting = state.carts[sid];
            let itemToRemove = action.item
            let foundItemAtIdx = subscriberCartExisting.findExactItemIdx(itemToRemove);
            if (foundItemAtIdx > -1) {
                if (subscriberCartExisting.cartItems.length === 1) {
                    return {
                        ...state,
                        'carts': omit(state.carts, [sid])
                    };
                } else {
                    subscriberCartExisting.removeItem(itemToRemove);
                    return {
                        ...state,
                        'carts': {
                            ...state.carts,
                            [sid]: subscriberCartExisting,
                        }
                    }
                }
            } else {
                strError = `Unable to find item ${itemToRemove.item_Title} in subscriber ${action.item.subscriberid} cart`;
                let appError, loggerError = appError = { info: strError };
                Sentry.captureException(new Error(strError));
                return { ...state, loggerError, appError };
                // throw new Error(`Unable to find item ${itemToRemove.item_Title} in subscriber ${ action.item.subscriberid} cart `);
            }
        case 'CHANGE_QTY':
            //***   handles change quantity and remove item ***/
            if (!action.item || !action.item.subscriberid) {
                strError = 'Attempted to change qty of an item without passing an item as a parameter or subscriberid as a property of that item'
                let appError, loggerError = appError = { info: strError };
                Sentry.captureException(new Error(strError));
                return { ...state, loggerError, appError };
            };
            const changeItem = action.item;
            sid = changeItem.subscriberid;
            if (state.carts[sid] === undefined) {
                strError = 'Attempted to change qty of an item for a subscriber that doesnot exist in the carts list';
                let appError, loggerError = appError = { info: strError };
                Sentry.captureException(new Error(strError));
                return { ...state, loggerError, appError };
            }
            if (action.qty === undefined || typeof action.qty != 'number') {
                if (action.qty === undefined) {
                    strError = 'Attempted to change qty of an item without passing a number qty parameter';
                    let appError, loggerError = appError = { info: strError };
                    Sentry.captureException(new Error(strError));
                    return { ...state, loggerError, appError };
                } else {
                    strError = `Attempted to change qty of an item with qty as not a valid number. (qty: ${action.qty})`;
                    let appError, loggerError = appError = { info: strError };
                    Sentry.captureException(new Error(strError));
                    return { ...state, loggerError, appError };
                }
            }
            const qty = action.qty;
            subscriberCartExisting = state.carts[sid];
            if (qty === 0) {
                // remove the item if it the last item, remove the whole cart
                // is it the last item??
                if (subscriberCartExisting.cartItems.length === 1) {
                    // we have to remove the whole subscriber cart
                    return {
                        ...state,
                        'carts': omit(state.carts, [sid])
                    }
                } else {
                    subscriberCartExisting.removeItem(changeItem);
                    return {
                        ...state,
                        'carts': {
                            ...state.carts,
                            [sid]: subscriberCartExisting,
                        }
                    }
                }
            } else {
                subscriberCartExisting.setItemQuantity(changeItem, qty);
                return {
                    ...state,
                    'carts': {
                        ...state.carts,
                        [sid]: subscriberCartExisting,
                    }
                }
            }
        case 'CHANGE_QTY_ITEMINCART':
            //***   handles change quantity and remove item ***/
            if (!action.itemInCart || !action.itemInCart.item_ptr) {
                strError = 'Attempted to change qty of an item without passing an item as a parameter or subscriberid as a property of that item';
                let appError, loggerError = appError = { info: strError };
                Sentry.captureException(new Error(strError));
                return { ...state, loggerError, appError };
            };
            const changeItemInCart = action.itemInCart;
            sid = changeItemInCart.item_ptr.split('.')[0];
            if (state.carts[sid] === undefined) {
                strError = 'Attempted to change qty of an item for a subscriber that doesnot exist in the carts list';
                let appError, loggerError = appError = { info: strError };
                Sentry.captureException(new Error(strError));
                try {
                    strError = `action: ${JSON.stringify(action)}. AppError: ${JSON.stringify(appError)}`;
                    throw new Error(strError);
                } catch (e) {
                    return { ...state, loggerError, appError };
                }
            }
            if (action.qty === undefined || getType(action.qty) != "number") {
                if (action.qty === undefined) {
                    strError = 'Attempted to change qty of an item without passing a number qty parameter';
                    let appError, loggerError = appError = { info: strError };
                    Sentry.captureException(new Error(strError));
                    return { ...state, loggerError, appError };
                } else {
                    strError = `Attempted to change qty of an item with qty as not a valid number. (qty: ${action.qty})`;
                    let appError, loggerError = appError = { info: strError };
                    Sentry.captureException(new Error(strError));
                    try {
                        strError = `action: ${JSON.stringify(action)}. AppError: ${JSON.stringify(appError)}`;
                        throw new Error(strError);
                    } catch (e) {
                        return { ...state, loggerError, appError };
                    }
                }
            }
            const _qty = action.qty;
            subscriberCartExisting = state.carts[sid];
            if (_qty === 0) {
                // remove the item if it the last item, remove the whole cart
                // is it the last item??
                if (subscriberCartExisting.cartItems.length === 1) {
                    // we have to remove the whole subscriber cart
                    return {
                        ...state,
                        'carts': omit(state.carts, [sid])
                    }
                } else {
                    subscriberCartExisting.removeItemInCart(changeItemInCart);
                    return {
                        ...state,
                        'carts': {
                            ...state.carts,
                            [sid]: subscriberCartExisting,
                        }
                    }
                }
            } else {
                subscriberCartExisting.setItemInCartQuantity(changeItemInCart, _qty);
                return {
                    ...state,
                    'carts': {
                        ...state.carts,
                        [sid]: subscriberCartExisting,
                    }
                }
            }

        //*** CHECKOUT RELATED */
        case 'PaymentIntent':
            return {
                ...state, paymentIntent: action.paymentIntent || null, customerId: action.customer || null,
                // ephemeralKey: action.ephemeralKey || null 
            };
        case 'UPDATE_CHECKOUT':
            return { ...state, checkout: { ...state.checkout, ...action.checkout } };
        case 'CHECKOUT_VALIDATING':
            return { ...state, checkout_validating: action.CHECKOUT_VALIDATING }
        case 'SUBMIT_ORDER_PENDING':
            return { ...state, checkout_validating: true, appError: null }
        case 'SUBMIT_ORDER_FULFILLED':
            return {
                ...state,
                paymentIntent: null,
                // ephemeralKey: null,
                paymentMethod: null,
                checkout_validating: null,
                appError: null,
                currentOrderId: action.id,
                carts: {},
                checkout: {
                    name: null,
                    email: null,
                    phone: null,
                    deliveryvalue: null,
                    deliveryinputrequired: false,
                    couponResponse: null
                },
            }
        case 'SUBMIT_ORDER_REJECTED':
            let __err = get(action, 'payload.response.data.message', null) || action.payload.message;
            strError = `Order error. ${__err}`;
            Sentry.captureException(new Error(strError));
            return {
                ...state, checkout_validating: null,
                appError: strError,
                loggerError: strError
            }


        //***************************** */
        //****** Profile RELATED *** */
        //***************************** */
        case 'SET_PROFILE':
            newState = setProfile(action.userProfile, state);
            return newState;
        case 'LOGOUT':
            // SHOULD ME MOVED TO marketplacereducer logout
            return {
                ...state,
                stripeProfile: null,
                customerId: null,
                // ephemeralKey: null, 
 

                userProfile: null,
                userProfileTrimmed: null,
                isFetchingStripeProfile: null,
                paymentIntent: null,
                paymentMode: "PaymentElement",
                paymentMethod: null,
                stripeError: null,
                selectedCard: null,
                checkout: { name: null, email: null, phone: null, couponResponse: null },
                orders: null,
                isFetchingProfileOrders: null,
            }
        case 'ClearStripeCustomer':
            return {
                ...state, stripeProfile: null, customerId: null,
                // ephemeralKey: null, 
                paymentIntent: null
            }
        //***** ADD ADDRESS TO PROFILE */
        case 'ADD_ADDRESS_TO_PROFILE_PENDING':
            return {
                ...state,
                appError: null
            };
        case 'ADD_ADDRESS_TO_PROFILE_FULFILLED':
            // if(!action.userProfile.data) return state;
            if (!has(action, 'payload.data')) return state;
            newState = setProfile(action.payload.data, state);
            return newState;
        case 'ADD_ADDRESS_TO_PROFILE_REJECTED':
            strError = `Unable to add new delivery address to profile.  ${get(action.payload, 'response.data.message', action.payload.message)}`;
            Sentry.captureException(new Error(strError));
            return {
                ...state,
                appError: strError,
                loggerError: strError
            };

        //***** GET PROFILE ORDERS */  
        case 'GET_PROFILE_ORDERS_PENDING':
            return {
                ...state,
                orders: null,
                isFetchingProfileOrders: true,
                appError: null
            };
        case 'GET_PROFILE_ORDERS_FULFILLED':
            return {
                ...state,
                orders: action.payload.data,
                isFetchingProfileOrders: false,
                profileOrdersError: null,
                appError: null
            };
        case 'GET_PROFILE_ORDERS_REJECTED':
            strError = 'Unable to retrieve profile orders. ' + action.payload.response.data.message || action.payload.message;
            Sentry.captureException(new Error(strError));
            return {
                ...state,
                isFetchingProfileOrders: false,
                profileOrdersError: (action.payload.message ? action.payload.message : action.error),
                appError: strError,
                loggerError: strError
            };

        case 'CLEAR_PROFILE_ORDERS':
            return {
                ...state,
                orders: null,
                currentOrderId: null,
                orderDetails: null,
                isFetchingProfileOrders: false,
                profileOrdersError: null,
                appError: null
            };
        //****** ***************************/    
        //****** GET PROFILE ORDER DETAILS */
        //****** ***************************/    
        case 'GET_ORDER_DETAILS_PENDING':
            return {
                ...state,
                isFetchingProfileOrders: true,
                appError: null,
                loggerError: null
            };
        case 'GET_ORDER_DETAILS_FULFILLED':
            let orderdetail = action.payload.data[0];
            newState = {
                ...state,
                orderDetails: orderdetail,
                isFetchingProfileOrders: false,
                profileOrdersError: null,
                appError: null,
                loggerError: null
            };
            return newState;
        case 'GET_ORDER_DETAILS_REJECTED':
            strError = 'Unable to retrieve profile orders. ' + action.payload.response.data.message || action.payload.message;
            Sentry.captureException(new Error(strError));
            return {
                ...state,
                isFetchingProfileOrders: false,
                profileOrdersError: (action.payload.message ? action.payload.message : action.error),
                appError: strError,
                loggerError: strError
            };
        case 'CLEAR_ORDER_DETAILS':
            return { ...state, orderDetails: null }









        //---------------------
        //***STRIPE RELATED****
        //---------------------     
        // GET STRIPE PROFILE:::: 
        case 'GET_STRIPEPROFILE_PENDING':
            return {
                ...state,
                stripeProfile: 'loading',
                // isFetchingStripeProfile: true,
                stripeError: null
                , appError: null
            };
        case 'GET_STRIPEPROFILE_FULFILLED':
            return {
                ...state,
                stripeProfile: action.payload.data,
                // isFetchingStripeProfile: false,
                stripeError: null,
                appError: null
            };
        case 'GET_STRIPEPROFILE_REJECTED':
            strError = (action.payload.message ? action.payload.message : action.error);
            Sentry.captureException(strError);
            return {
                ...state,
                stripeProfile: null,
                // isFetchingStripeProfile: false,
                stripeError: strError,
                appError: 'Unable to retrieve the user payment profile. ' + get(action, 'payload.response.data.message') || action.payload.message,
                loggerError: 'Unable to retrieve the user payment profile. ' + get(action, 'payload.response.data.message') || action.payload.message
            };
        // ADD CARD::::
        case 'GET_ADDCARD_PENDING':
            return {
                ...state,
                isFetchingAddCard: 'loading',
                addCardError: null
                , appError: null
            };
        case 'GET_ADDCARD_FULFILLED':
            return {
                ...state,
                isFetchingAddCard: false,
                stripeProfile: { ...state.stripeProfile, 'sources': [...state.stripeProfile.sources, action.payload.data] }
                , appError: null

            };
        case 'GET_ADDCARD_REJECTED':
            strError = `Unable to add card.  ${(get(action, 'payload.response.data.message') || action.payload.message)}`;
            Sentry.captureException(strError);
            return {
                ...state,
                isFetchingAddCard: false,
                addCardError: (action.payload.message ? action.payload.message : action.error),
                appError: strError,
                loggerError: strError
            };
        // DELETE CARD::::
        case 'GET_DELETECARD_PENDING':
            return {
                ...state,
                isFetchingDeleteCard: true,
                deleteCardError: null
                , appError: null
            };
        case 'GET_DELETECARD_FULFILLED':
            return {
                ...state,
                isFetchingDeleteCard: false,
                stripeProfile: { ...state.stripeProfile, 'sources': state.stripeProfile.sources.filter(({ id }) => id !== action.payload.data.id) }
                , appError: null
            };
        case 'GET_DELETECARD_REJECTED':
            strError = `Unable to delete card.  ${(get(action, 'payload.response.data.message')) || action.payload.message}`;
            Sentry.captureException(strError);
            return {
                ...state,
                isFetchingDeleteCard: false,
                deleteCardError: (action.payload.message ? action.payload.message : action.error),
                appError: strError,
                loggerError: strError
            };
        // MISC::::::
        case 'SELECT_CARD':
            return {
                ...state, selectedCard: action.card
            }

        case 'SWITCH_PAYMENT_MODE':
            newState =  { ...state, paymentMode: action.paymentMode}
            if(action.paymentMode === 'PaymentElement') newState.selectedCard = null;
            return newState



        //*** APP status related */
        // case 'RequestFullfilled':
        //     return {...state, requestFullfilled : action.requestFullfilled}
        //***** LOGGER RELATED */
        case 'SET_ERRORS':
            Sentry.captureException(new Error(action.loggerError.message || action.appError.message));
            return {
                ...state,
                loggerError: action.loggerError.message,
                appError: action.appError.message
            }
        case 'CLEAR_ERRORS':
            return {
                ...state,
                loggerError: null, appError: null
            }
        case 'RESET_SPINNERS':
            return {
                ...state,
                checkout_validating: null,
                isFetchingAddCard: null, isFetchingDeleteCard: null,
                isFetchingStripeProfile: null, isFetchingProfileOrders: null,
                sinfo_loading: null, currentSubscriberMenu_loading: null,
                loading: null
            }
        //**** EXAMPLE IN REDUX DISPATCHER */
        // {
        //   type: 'MODIFY_SPINNER', 'spinnername' : 'checkout_validating', 'spinnervalue' : 'loading'
        // }
        case 'MODIFY_SPINNER':
            return { ...state, [action.spinnername]: action.spinnervalue }
        case '__none__':
            // console.error('error here');
            return state;
        // //* SAMPLE USAEG
        // {
        //     type: "HACKYHACKHACK_UpdateAvailableSubscriber",
        //     type2: "UPDATE_SUBSCRIBERS_EXPIRY",
        //     payload: {sid: "7DE2620F-263E-4940-9BD5-0CE59908C11A", key: "latestordertime", 
        //     value: "5:20 PM"} 
        // }
        // */72
        case 'HACKYHACKHACK_UpdateAvailableSubscriber':
            let { key, value } = action.payload;
            sid = action.payload.sid;
            newAvailableSubscribers = { ...(state.availableSubscribers || {}) };
            let s_info = { ...newAvailableSubscribers[sid] };
            s_info[key] = value;
            newAvailableSubscribers[sid] = { ...newAvailableSubscribers[sid], ...s_info };
            return { ...state, availableSubscribers: newAvailableSubscribers }
        case 'HACKYHACKHACK':
            return { ...state, ...action.payload };
        case 'HACKYHACKHACK_DELETE':
            newState = Object.assign({}, state);
            delete newState[action.HACKYHACKHACK];
            return newState;
        default:
            return state;
    }




}

function __reset_marketplace_subscribersmenus() {
    return {
        selectedSubscriber: null,
        selectedItem: null, carts: {},
        availableSubscribers: null,
        marketplaceclosed: false,
        marketplacemessage: null
    };
}


function setProfile(userProfile, state) {
    if (!userProfile) return { ...state }
    let t = trimUserProfile(userProfile);
    let locations = t.locations;
    delete t.locations;
    let newState = {
        ...state,
        userProfile: userProfile || null,
        userProfileTrimmed: t,
        locations,
        orders: null,
        // requestFullfilled : true
    };
    return newState;
}
