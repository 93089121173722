import React from 'react';
import { sumBy, find } from 'lodash';
import * as aggregate from './aggregate';
import accounting from 'accounting';
import {throwIfMissing, } from '../../togonowutils'
import {calculateSelectionMarkup} from './aggregate';


// ItemInCart
//             {
//                 item: selectedItem1,
//                 qty: 1,
//                 price: x.xx,
//                 linetotal: xx.xx
//             },
export default class ItemInCart{
    constructor(item = throwIfMissing(), qty) {
        if(item['item_ptr'] !== undefined 
            && item['item_Title'] !== undefined
            && item['_price'] !== undefined  
            && item['_linetotal'] !== undefined 
            && item['selectionString'] !== undefined
            && item['qty'] !== undefined  
        ){
            Object.assign(this, item);
            if(qty && item.qty !== qty){
                this.qty =  qty; //item.qty;
            }
            this.linetotal = accounting.toFixed(this.qty * Number.parseFloat(Number.parseFloat(this._price)), 2);
            this.selectionString =  item.selectionString;
            return this;
        }
        this.item_ptr  = `${item.subscriberid}.${item.item_ID}`;    // subscriberid.itemid
        this.item_Title = item.item_Title;
        this.qty = qty || 1;
        this.selectedOVs = (item.selectedOVs ? item.selectedOVs : null);
        this.price = accounting.toFixed(Number.parseFloat(item.item_Price) + calculateSelectionMarkup(item), 2);
        this.linetotal = accounting.toFixed(this.qty * Number.parseFloat(this._price), 2);
        this.selectionString =  aggregate.optionsPrintout(item);
    };


    get price(){
        return accounting.formatMoney(this._price); // $1.23
    };

    set price(x){
        this._price = accounting.toFixed(x, 2);  // '1.23'
    };
    
    get linetotal(){
        return accounting.formatMoney(accounting.toFixed(this._linetotal, 2));
    };

    set linetotal(x){
        this._linetotal =  accounting.toFixed(x, 2);
    };

    get selections(){
        return this.selectionString;
    };

    get subscriberid(){
        return this.item_ptr.split('.')[0];
    }

    addQty(value = throwIfMissing()){
        value = Number(value);
        this.qty += value;
        this.linetotal = accounting.toFixed(this.qty * Number.parseFloat(this._price), 2);
        // this.__version++;   
    };

    removeQty(value=throwIfMissing()){
        value = Number(value);
        this.qty -= value;
        this.linetotal = accounting.toFixed(this.qty * Number.parseFloat(this._price), 2);
        // this.__version++;   
    };


    buildSelectionString(item){
        let strCache = '';
        if(item && item.OptionGroups){
            item.OptionGroups.map(oG => {
                if(oG.optionGroup_Title) strCache += oG.optionGroup_Title;
                oG.Options.map(op =>{
                    if(op.option_Title) strCache += ' ' + op.option_Title + ': ';
                    op.OptionValues.map((ov,idx) =>{
                        if(ov.optionvalue_isdefault || ov.selected){
                            if(ov.optionvalue_value === 'no'){
                                strCache +='<u>' + op.OptionValues[0].optionvalue_value + '</u>';
                            }else{
                                strCache += ' ' +ov.optionvalue_value;
                            }
                        }
                        return true;
                    })
                    return true;
                });
                return true;
            })
        }
        return strCache;
    }




/*
    OBread Choice: OVDutch Crunch; OCheeses: American.
    OGRogers Flavors:
    OVMayo, OVMustard, OVLettuce, OVPickles, OVTomatoes, OVOnion, OVSalt
*/
    buildSelectionString2(item){
        let strCache = [];
        if(item && item.OptionGroups){
            item.OptionGroups.map(oG => {
                if(oG.optionGroup_Title) strCache.push(<div>{oG.optionGroup_Title}</div>);
                oG.Options.map(op =>{
                    if(op.option_Title) strCache.push(<div>{op.option_Title + ': '}</div>);
                    op.OptionValues.map((ov,idx) =>{
                        if(ov.optionvalue_isdefault || ov.selected){
                            if(ov.optionvalue_value === 'no'){
                                strCache.push(<s>{op.OptionValues[0].optionvalue_value}</s>);
                            }else{
                                strCache.push(<div>{op.OptionValues[0].optionvalue_value}</div>);
                            }
                        }
                        return true;
                    })
                    return true;
                });
                return true;
            })
        }
        return <React.Fragment>{strCache}</React.Fragment>;
    }


    trimmed(){
        let item_ptr = this.item_ptr, 
        item_Title = this.item_Title,
        qty = this.qty,
        selectedOVs = this.selectedOVs,
        price = this.price,
        linetotal = this.linetotal,
        selectionString = this.selectionString;

        return {
            item_ptr,
            item_Title,
            qty,
            selectedOVs,
            price,
            linetotal,
            selectionString,

        };
    }

};
