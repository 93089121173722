import React from 'react';
// import SavedPayments from './SavedPayments';
import SelectedCard from './SelectedCard';
import CardList from './CardList';

export default class PaymentList extends React.Component { 

    constructor(props) {
        super(props);
        this.state = { 
            collapse: false, 
            AddPaymentModalOpen: false,
        };
        this.toggle = this.toggle.bind(this);
        this.selectCardAction = this.selectCardAction.bind(this);
        this.toggleAddPaymentModalOpen = this.toggleAddPaymentModalOpen.bind(this);
        this.goPayment = this.goPayment.bind(this);
        this.setStripeElement = this.setStripeElement.bind(this);
    }


    toggleAddPaymentModalOpen (){
        this.setState({ AddPaymentModalOpen: !this.state.AddPaymentModalOpen });
    }   
    

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    };

    selectCardAction(card){
        this.props.selectCard && this.props.selectCard(card);
        this.toggle();
    };

    goPayment(){
        this.props.history.push({pathname: '/payment'});
        // this.props.history.go('/payment');
    }

    setStripeElement(stripe_element){
        this.setState({'stripe_element': stripe_element});
    }


    
    render(){ 
        const {
            selectedOrDefaultCard, stripeProfile, presentAsCard, 
            cardStyle, addCard, deleteCard, setStripeElement,
            handleClickAddPayment, setToken,
            allowAddNewPayment,
            errorCard, history
        } = this.props;
        const {removeCardModalIsOpen} = this.state;
        const toggleAddPaymentModalOpen = this.toggleAddPaymentModalOpen
        const collapse = this.state.collapse;
        const selectCardAction = this.selectCardAction;
        const toggle = this.toggle, AddPaymentModalOpen = this.state.AddPaymentModalOpen
        const goPayment = this.goPayment;
        
        return(
            presentAsCard ? // will display as boxes or cards
                    <div className="container-row">
                        <div className="row">
                            <CardList {...{ 
                                collapse, stripeProfile, selectedOrDefaultCard, selectCardAction, cardStyle, presentAsCard
                                , toggleAddPaymentModalOpen, AddPaymentModalOpen
                                , addCard, deleteCard
                                , setStripeElement, setToken, handleClickAddPayment, allowAddNewPayment
                                , removeCardModalIsOpen
                                , errorCard
                                , history
                                }}  />  
                        </div>
                    </div>
                :   // will display as a dropdown
                    <div className="d-flex flex-column divStyle2">
                    <React.Fragment>
                        <SelectedCard {...{toggle, selectedOrDefaultCard}} />
                        <CardList {...{ collapse, stripeProfile, selectedOrDefaultCard, 
                            toggleAddPaymentModalOpen, 
                            goPayment, removeCardModalIsOpen,
                            selectCardAction, addCard, errorCard, history
                            }} />  

                    </React.Fragment>
                    </div>
        )

    }
}