
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';

import {
    selectCard as __selectCard,
    updateCheckoutAction as __updateCheckoutAction,
    submitOrderFulfilled as __submitOrderFulfilled,
    SetPaymentIntentAction,
} from '../../redux/actions/marketplaceActions';
import { CheckoutDelivery, CheckoutContact, PaymentList, PaymentForm, CartWidget } from './';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectedOrDefaultCard as _selectedOrDefaultCard,
    cartsAccounting as _cartsAccounting,
    orderSubmissionObject as _orderSubmissionObject
} from '../../redux/selectors';
import { AuthContext } from "../../Auth.js";
import { BackTo } from '../tinyparts/BackTo';
import { ButtonSpinner, Errored, Spinner } from '../tinyparts/Spinners';
import { AlertLine } from '../AlertLine';
import { useResponsiveFontSize } from '../../togonowutils';

import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
    Button,
    Form,
} from 'reactstrap';
import PaymentElementWrapper from './PaymentElementWrapper';


const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                },

            }
        }),
        [fontSize]
    );

    return options;
};


export default ({ setRedirect, hydatePaymentIntent }) => {
    const mql = window.matchMedia(`(min-width: 800px)`);
    let sizeOfInput = (mql.matches ? '-lg' : '');
    let sizeOfFA = (mql.matches ? '-3x' : '');

    // ********************** store PROPS and Selectors
    const {
        selectedCard, paymentMode,
        carts,
        checkout, stripeProfile,
        customerlocation,
        customerId,
        paymentIntent,
        // ephemeralKey,
        selectedOrDefaultCard,
        cartsAccounting,
    } = useSelector((state) => ({
        carts: state.marketplaceReducer.carts,
        checkout: state.marketplaceReducer.checkout,
        stripeProfile: state.marketplaceReducer.stripeProfile,
        customerId: state.marketplaceReducer.customerId,
        customerlocation: state.marketplaceReducer.customerlocation,

        paymentIntent: state.marketplaceReducer.paymentIntent,
        paymentMode: state.marketplaceReducer.paymentMode,
        selectedCard: state.marketplaceReducer.selectedCard,
        cartsAccounting: _cartsAccounting(state),
        orderSubmissionObject: _orderSubmissionObject(state),
        selectedOrDefaultCard: _selectedOrDefaultCard(state),
    }));

    const dispatch = useDispatch();
    const selectCard = (card) => dispatch(__selectCard(card))
    const updateCheckoutAction = (updateObject) => dispatch(__updateCheckoutAction(updateObject))
    const submitOrderFulfilled = (orderRequestObject) => dispatch(__submitOrderFulfilled(orderRequestObject))
    const { currentUser, idToken, authLoading } = useContext(AuthContext);
    const options = useOptions();
    const stripe = useStripe();
    const elements = useElements();






    // ********************** Local State 
    //  loading related
    let [processing, setProcessing] = useState(false);  // e.g. submitting order 
    let [errorProcessing, setErrorProcessing] = useState(false); // error sign in, error sign out, error get payment intent error submit order, error 
    let [errorCorrectAction, setErrorCorrectAction] = useState(null); // Action to resolve error sign in, error sign out, error get payment intent error submit order, error 
    let [errorCorrectLabel, setErrorCorrectLabel] = useState(null); // Label for the Action avoveerror sign in, error sign out, error get payment intent error submit order, error 
    let [errorName, setErrorName] = useState(null);
    let [errorEmail, setErrorEmail] = useState(null);
    let [errorPhone, setErrorPhone] = useState(null);
    let [errorDeliveryValue, setErrorDeliveryValue] = useState(null);
    let [paymentElementLoading, setPaymentElementLoading] = useState('loading');
    // ********************** Local State 


    function selectDeliveryValue(v) {
        // updateCheckoutAction({ deliveryvalue: { value: v } })
        return { deliveryvalue: { value: v } }
    }

    function editDeliveryValue(e) {
        switch (e.target.id) {

            case 'aptsuitefloor':
                let aptsuitefloor = e.target.value;
                updateCheckoutAction({ deliveryvalue: { ...checkout.deliveryvalue, aptsuitefloor } });
                break;
            case 'deliverynotes':
                let deliverynotes = e.target.value;
                updateCheckoutAction({ deliveryvalue: { ...checkout.deliveryvalue, deliverynotes } });
                break;
            default:
                return null;
            // do nothing
        }
    }

    function valedatePhoneNumber(inputtxt) {
        var phoneno = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
        return (inputtxt.match(phoneno));
    }


    function validateNBundleInputs() {
        // This is a gate keeper
        // since useState (setState) is async, we need to keep track if everything is valid or not since the state won't be updated by the time we check the state values
        let allValid = true;
        const { name, email, phone, deliveryvalue, deliveryinputrequired } = checkout;
        let nameValid = name !== null && name.length > 3;
        if (!nameValid) {
            setErrorName('Please enter your first and last name.');
            allValid = false;
        }

        let emailValid = email !== null && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
        if (!emailValid) {
            setErrorEmail('Please enter a valid email address.');
            allValid = false;
        }

        let phoneValid = phone !== null && phone.length > 3 && valedatePhoneNumber(phone) !== null;
        if (!phoneValid) {
            setErrorPhone('Please enter a valid phone number. e.g. 123-456-7890');
            allValid = false;
        }

        let deliveryvalueValid = !deliveryinputrequired || deliveryvalue !== null;
        if (!deliveryvalueValid) {
            setErrorDeliveryValue('A delivery location is required.');
            allValid = false;
        }

        return allValid;
    }

    function resetErrors() {
        setErrorName(null);
        setErrorEmail(null);
        setErrorPhone(null);
        setErrorProcessing(null);
        setErrorCorrectAction(null);
        setErrorCorrectLabel(null);
    }


    async function handleSubmit(e) {
        e.preventDefault();


        try {


            setProcessing(true);
            resetErrors();

            if (!validateNBundleInputs()) {
                setProcessing(false);
                return;
            };
            const { name, email, phone, deliveryvalue } = checkout;
            const bundledInputs = { name, email, phone, deliveryvalue };
            debugger;
            let hydrated = await hydatePaymentIntent(bundledInputs);
            if (!hydrated) {
                setProcessing(false);
                return;
            }


            // finalize and sbumit payment intent to carts database

            switch (paymentMode) {

                case 'PaymentElement':
                    stripe.confirmPayment({
                        elements,
                        redirect: 'if_required',
                        confirmParams: { return_url: window.location.href },
                    }).then((res) => {
                        setProcessing(false);
                        if (res.error) {
                            console.error('💣', res.error);
                            setErrorProcessing(res.error.message)
                            setProcessing(false)
                        } else {
                            setRedirect(res.paymentIntent.id)
                        }
                    }).catch((e)=>{
                         throw e
                    })
                    break;
                case 'SavedPayment':
                    stripe.confirmCardPayment(
                        paymentIntent.client_secret, { payment_method: selectedCard.id }
                    ).then(function (res) {
                        if (res.error) {
                            console.error('💣', res.error);
                            setErrorProcessing(res.error.message)
                            setProcessing(false)
                        } else {
                            setRedirect(res.paymentIntent.id)
                        }
                    }).catch((e)=>{
                        throw e
                   });
                    break;
                default:
                    console.error('💣Payment Mode is not set - PaymentMode =', PaymentMode)
                    setErrorProcessing('Unknown error, Payment Mode is not set!')
                    setProcessing(false)
            }
        } catch (e) {

            setErrorProcessing(e.message)
            setProcessing(false)
        }
    }



    return <Form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
        <AlertLine {...{ appError: errorProcessing, errorCorrectAction, errorCorrectLabel }} />
        <div className="container">
            <div className="row">
                <div className="col-md">
                    <BackTo {...{ history }} />
                    {/********************************* Delivery ***************************************/}
                    <CheckoutDelivery className="bg-primary text-white"
                        {...{
                            updateCheckoutAction,
                            errorDeliveryValue,
                            selectDeliveryValue, editDeliveryValue
                        }} />
                    {/********************************* Contact ***************************************/}
                    <CheckoutContact {...{
                        checkout, errorName, errorEmail,
                        errorPhone, updateCheckoutAction, sizeOfFA, sizeOfInput
                    }} />
                    <>
                        <div className="d-flex mb-4 p-2">
                            <div className="flex-grow-1 border-left border-thick border-success p-2" >
                                <h3 className="font-weight-bold text-muted">
                                    <FontAwesomeIcon icon={faCreditCard} className='me-2' color="green" />
                                    Payment
                                </h3>
                                <PaymentElementWrapper stripeProfile={stripeProfile} />

                            </div>
                        </div>

                        <div className="mt-1 mx-auto w-75 mb-4">
                            <Button type="submit" disabled={processing === true} className={'btn btn-outline btn-success w-100'} >
                                {processing && <ButtonSpinner />}
                                Place Order {cartsAccounting && `$${cartsAccounting.accounting.total}`}
                            </Button>
                        </div>
                    </>
                    {/**********************CART HERE **********************************/}
                </div>
                <CartWidget />
            </div>
        </div>
    </Form>


};
