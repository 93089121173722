import React, { useContext, useState, useEffect } from "react";
import {BackTo} from './tinyparts/BackTo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faUser} from '@fortawesome/free-regular-svg-icons';
import {faKey} from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from "../Auth";
import {isEqual} from 'lodash';
import { ButtonSpinner } from "./tinyparts/Spinners";
 
const Profile = ({sizeOfInput}) => {
 
    const { currentProfile, loading, UpdateProfile, currentUser, UpdatePassword} = useContext(AuthContext);
    const [profile, setProfile] = useState({});
    const [editing, setEditing] = useState(false);
    // const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [changingPassword, setChangingPassword] = useState(false);


    useEffect(() => {
        document.title = `ToGoNow Profile`;
    })

    useEffect(() => {
        setProfile(currentProfile);
        setEditing(false);
    }, [currentProfile]);

    // useEffect(() => {
    //     setEmail(currentUser ? currentUser.email : '');
    // }, [currentUser]);

    const valuesChanged = (a, b)=> {
        return !isEqual(a,b)};



    if(loading || (currentUser && !profile)) return <ButtonSpinner />    // waiting for profile to load
    const formstyle = `form-control form-control-lg`;
    
    return (
    <div className="container p-0">  
        <div className="col-sm">
            <BackTo   />
            <div className="d-flex  p-2">
                <div className="border-left border-thick border-success p-2">
                    <h3 className="font-weight-bold text-muted mb-4">
                        <FontAwesomeIcon icon={faUser}  className='me-2'  color="green" />
                        Profile info
                    </h3>
                    <div className="form-group">
                        {false && <span id="phoneError" className="form-text text-danger">{'   '}</span>}
                        <label htmlFor="phone">Phone:</label>
                        <input type="phone" className={formstyle} id="phone" 
                            value={profile.phone ? profile.phone : '' }
                            aria-describedby="phone" 
                            onChange={e => {
                                e.preventDefault();
                                let _profile = {...profile , phone : e.target.value};
                                setProfile(_profile); 
                                setEditing(valuesChanged(currentProfile, _profile));}}
                             />
                        <small id="phoneHelp" className="form-text text-muted">If an issue arises with your order, we will call or text this number.</small>
                    </div>


                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" className={formstyle} id="name" 
                            value={profile.name || ''}
                            aria-describedby="new password" 
                            onChange={e => {
                                e.preventDefault();
                                let _profile = {...profile , name : e.target.value};
                                setProfile(_profile); 
                                setEditing(valuesChanged(currentProfile, _profile));
                            }}
                             />
                        <small id="nameHelp" className="form-text text-muted">
                            Will be printed on the bag label.</small>
                    </div>

                    <div  className="mt-1 mx-auto w-75 mb-4">

                        <button type="button"  
                          disabled={!editing}
                          className={'btn btn-success btn-lg btn-block'}
                          onClick={e =>UpdateProfile(profile)}>{loading && '... '}Update Profile</button>
                    </div>
                </div>
            </div>


















            <div className="d-flex  p-2">
                <div className="border-left border-thick border-success p-2">
                    <h3 className="font-weight-bold text-muted mb-4">
                        <FontAwesomeIcon icon={faKey}  className='me-2'  color="green" />
                        Change Password
                    </h3>
                    <div className="form-group">

                        <label htmlFor="currentpassword">Current Password:</label>
                        <input type="password" className={formstyle} id="currentpassword" 
                            value={currentPassword }
                            autoCapitalize="none" 
                            aria-describedby="currentpassword" 
                            onChange={e => {
                                e.preventDefault();
                                setCurrentPassword(e.target.value)
                            }}  />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">New Password:</label>
                        <input type="password" className={formstyle} id="newpassword" 
                            value={newPassword }
                            autoCapitalize="none" 
                            aria-describedby="newpassword" 
                            onChange={e => {
                                e.preventDefault();
                                setChangingPassword(valuesChanged(e.target.value, ''))
                                setNewPassword(e.target.value); 
                            }} />                            

                    </div>
                    <div  className="mt-1 mx-auto w-md-75 mb-4">

                        <button type="button"  
                          disabled={!changingPassword}
                          className={'btn btn-success btn-lg btn-block'}
                          onClick={e =>UpdatePassword(currentPassword, newPassword)}>{loading && '... '}Change Password</button>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};



export default Profile