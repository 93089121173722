import React from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";

export const goMainMenu = (history)=>{
    history.push({pathname: '/menu'});
}


export const BackTo = ({lbl = 'Menu', lnk = '/menu' }) =>  {
    let history = useHistory();
    return <h4 className="d-flex justify-content-between align-items-center mb-3">
        <Link to={lnk} 
        onClick={e=> {
            goMainMenu(history )}}  
        className="flex text-success"> 
        <FontAwesomeIcon icon={faArrowLeft} className='me-2'    color="green"  transform="shrink-4 down-1" style={{cursor: 'pointer'}}/>{lbl}
        </Link>
    </h4>}