import React, {useEffect, useState, useContext} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import {get} from 'lodash';
// import { ClipLoader } from 'react-spinners';
import {Spinner} from '../tinyparts/Spinners'
import { AuthContext } from "../../Auth.js";
import {
    updateCheckoutAction as __updateCheckoutAction,
    // getPlaceByID as __getPlaceByID,
} from '../../redux/actions/marketplaceActions';

export default function CheckoutDelivery({
    updateCheckoutAction, 
    errorDeliveryValue,
    selectDeliveryValue , editDeliveryValue}){
        
    // State
    const { customerlocation, deliveryvalue} = useSelector(({ marketplaceReducer }) => ({
        customerlocation: marketplaceReducer.customerlocation,
    }));

    // **********************  STATE ACTIONS
    // const dispatch = useDispatch();
    // const updateCheckoutAction = (updateObject) => dispatch(__updateCheckoutAction(updateObject))
    
    // Hooks
    const [loading] = useState(null);

    const { deliverto} = customerlocation;
    
    return (customerlocation ?             
    <div className="d-flex mb-2 p-2">
        <div className="border-left border-thick border-success p-2">
            <h3 className="font-weight-bold text-muted">
                <FontAwesomeIcon icon={faMapMarkerAlt}  className='me-2'  color="green" />
                Delivery</h3>
            <address>
                {customerlocation.name && <strong>{customerlocation.name}<br /></strong>}
                {customerlocation.address}
            </address>
            <DeliverTo {...{deliverto,  deliveryvalue, selectDeliveryValue, errorDeliveryValue, editDeliveryValue, loading}} />
        </div>
    </div>
    : 
    <div>no customerlocation selected</div>)
    
};

const DeliverTo = ({  
    errorDeliveryValue, editDeliveryValue, sizeOfInput, loading 
} )=>{




    //State
    const {  deliveryvalue, deliverto, placeId } = useSelector(({ marketplaceReducer }) => ({
        deliveryvalue: get(marketplaceReducer, 'checkout.deliveryvalue', null),
        deliverto: get(marketplaceReducer, 'customerlocation.deliverto', null),
        placeId: get(marketplaceReducer, 'customerlocation.placeId', null),
    }));
    
    //Actions
    const dispatch = useDispatch();
    const updateCheckoutAction = (v) => dispatch(__updateCheckoutAction({ deliveryvalue: { value: v }}));
    
    // AUTH 
    const { currentProfile } = useContext(AuthContext);

    useEffect(()=>{
        // if we recieve the both the location delivery settings, and the profile deliveryvalue,
        // and the user hasn't picked up the delivery valu, let's set it up for him
        if(!deliveryvalue && deliverto && placeId && currentProfile){ // no default delivery value, we recieved the profile deliverto and it's the same placeId
            let profilePlaceId = get(currentProfile, 'deliveryvalue.placeId')
            let profiledeliveryvalue = get(currentProfile, 'deliveryvalue.value')
            if(placeId === profilePlaceId){
                updateCheckoutAction(profiledeliveryvalue);
            }

        }
    }, [deliverto, deliveryvalue])
    
    
    if(loading){
        return             <div className="spinner vh-100">      
        <Spinner />
        </div>  
    }        





    const {deliverynotes, field, required, type, value  } = deliverto ||{};
    switch(type){
        case 'Label':
            return  value && 
                <React.Fragment>
                    <strong>Delivery to: </strong> 
                    {errorDeliveryValue &&  <p id="deliveryvalueError" className="form-text text-danger">{errorDeliveryValue}</p>}
                    {value}
                    <div className='text-muted'>{deliverynotes || ''}</div>
                </React.Fragment>
        case 'DropDown':
            return  value && <React.Fragment>
                <strong>Deliver to: {required && '*'} {field || ''}</strong> 
                {errorDeliveryValue &&  <p id="deliveryvalueError" className="form-text text-danger">{errorDeliveryValue}</p>}
                <div className="list-group ms-4 ">
                    <div>
                        {value && value.map(v=>(
                            <button 
                                key={v}
                                onClick={e => updateCheckoutAction(v)}
                                className={`me-1 mb-1 btn ${deliveryvalue && v === deliveryvalue.value  ? 'btn-success' : 'btn-outline-secondary btn-sm'}`} >
                                    {v}
                            </button>
                        ))}
                    </div>
                    {deliverynotes && <div className="text-strong text-success">{deliverynotes}</div>}
                </div>
            </React.Fragment>;
          default:
              return <DefaultDelivery {...{sizeOfInput, deliveryvalue, editDeliveryValue, errorDeliveryValue }}/>
            
            
    }


}
const DefaultDelivery = ({sizeOfInput, deliveryvalue ={}, editDeliveryValue, errorDeliveryValue })=>{
    const formstyle = `form-control form-control${sizeOfInput ||''}`; 
    return   <React.Fragment>
        {errorDeliveryValue &&  <p id="deliveryvalueError" className="form-text text-danger">{errorDeliveryValue}</p>}
        <div className="form-group">
            <label htmlFor="aptsuitefloor">Apartment, suite or floor:</label>
            <input type="text" className={formstyle}
                value={get(deliveryvalue, 'aptsuitefloor', '')}
                onChange={editDeliveryValue}
                id="aptsuitefloor" aria-describedby="aptsuitefloorHelp"  />
        </div>                   
        <div className="form-group">
            <label htmlFor="deliverynotes">Notes:</label>
            <input type="text" className={formstyle}
                value={get(deliveryvalue, 'deliverynotes','')}
                onChange={editDeliveryValue}
                id="deliverynotes" aria-describedby="deliverynotesHelp"  />
        </div>                   
    </React.Fragment> 
}