import React from 'react';
import { useEffect, useState } from "react";
import moment from 'moment-timezone';

import parser from 'parse-address';
import * as Sentry from '@sentry/browser';


// This function will retry any promise n times the promise function should have up to 3 parameters
// export const retry = async function (fn, n, param1, param2, param3) {
//   var lastError;
//   for (let idx = 0; idx < n; idx++) {
//     try {
//       // console.log(`Trial Number ${idx}`)
//       if (idx > 0) {
//         console.log('*** Creating Profile!')
//         await sleep(2000);
//       }
//       return await fn(param1, param2, param3);
//     } catch (e) {
//         console.log('retry::: 💣💣', e.message)
//       lastError = e;
//     }
//   }
//   throw lastError;
// }


/**
 * Retries the given function until it succeeds given a number of retries and an interval between them. They are set
 * by default to retry 5 times with 1sec in between. There's also a flag to make the cooldown time exponential
 * @param {Function} fn - Returns a promise
 * @param {Number} retriesLeft - Number of retries. If -1 will keep retrying
 * @param {Number} interval - Millis between retries. If exponential set to true will be doubled each retry
 * @param {Boolean} exponential - Flag for exponential back-off mode
 * @return {Promise<*>}
 */
export async function retry(fn, retriesLeft = 5, param1, param2, param3, interval = 1000, exponential = false) {
    try {
        const val = await fn(param1, param2, param3);
        // console.log(`🍓🍓🍓 SUCCESS REOLVED after retry ${retriesLeft} and function: \n\n ${fn} \n\n and val: ${JSON.stringify(val)}`)
        return val;
    } catch (error) {
        if (retriesLeft) {
            await new Promise(r => setTimeout(r, interval));
            return retry(fn, retriesLeft - 1, param1, param2, param3, exponential ? interval * 2 : interval, exponential);
        } else {
            let newError = new Error('Max retries Reached', fn)
            Sentry.captureException(newError);
            throw newError
        };
    }
}


//************************ 
//** Time Calculations ********* 
//************************ 
export const sleep = function (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//*** e.g. momCurrentdatetime: 1/5/2018 11:45AM,   
//***   strReferenceTime: "12:00 PM"   returns moment object of 1/5/2018 12:00 PM based on restaurant current timezone and current date  */
export const momentFromstrTime_RestaurantZone = (strReferenceTime) => {
    let todaysDate_RestaurantTimeZone = moment.tz('America/Los_Angeles').format('l').toString(); //date part for today at the restaurant time zone: 1/1/2018;  
    let combined = `${todaysDate_RestaurantTimeZone} ${strReferenceTime}`;
    let combinedFormat = 'MM/DD/YYYY h:mm A';
    let mom_restauantOpen_RestaurantTimeZone = moment.tz(combined, combinedFormat, 'America/Los_Angeles');
    return mom_restauantOpen_RestaurantTimeZone;
}

//*** e.g. momCurrentDatetime: 1/5/2018 11:45 AM strReferenceTime: "12:00 PM"  return 1/5/2018 12:00PM */
//*** e.g. momCurrentDatetime: 1/5/2018 12:45 AM strReferenceTime: "12:00 PM"  return 1/6/2018 12:00PM */
export const getLatestOrderTimeForRestaurant = (strReferenceTime, withinXHours = 8) => {
    let momLatestOrderTime = momentFromstrTime_RestaurantZone(strReferenceTime);
    let momCurrentdatetime = moment();
    let isBeforeDelivery = momCurrentdatetime.isBefore(momLatestOrderTime);
    if (!isBeforeDelivery) momLatestOrderTime = momLatestOrderTime.add(1, 'days');

    let todaysDate_RestaurantTimeZone = moment.tz('America/Los_Angeles').format('l').toString(); //date part for today at the restaurant time zone: 1/1/2018;  

    const isSameDay = (todaysDate_RestaurantTimeZone === momLatestOrderTime.format('l'));
    const isWithinXHours = (momLatestOrderTime.diff(momCurrentdatetime, 'hours') === withinXHours);

    return { momLatestOrderTime, isSameDay, isWithinXHours };
}



export const trimAddress = (strAddress) => strAddress.substring(0, strAddress.length - 5);
export const parseAddress = (strAddress) => {
    const parsed = parser.parseLocation(strAddress);
    const { number, prefix, street, type } = parsed;
    const streetpart = [number, prefix, street, type].filter(Boolean).join(" ");
    return streetpart

}





//**  e.g 1 hour vs 6 hours */
export const plural = (x) => x > 1 ? 's' : '';

export const classFitCrop = (w = 300, h = 300) => `auto=compress&fit=crop&w=${w}&h=${h}`;
export const getImgexFitCrop = (endpoint = throwIfMissing(), ImgWithExtension = throwIfMissing(), w = 300, h = 300) => `https://${endpoint}.imgix.net/${ImgWithExtension}?${classFitCrop(w, h)}`;
export const getImgexAnySize = (endpoint = throwIfMissing(), ImgWithExtension = throwIfMissing(), w, h) => `https://${endpoint}.imgix.net/${ImgWithExtension}?w=${w}&h=${h}`;
export const ImgTagIconCircle = ({ Endpoint = throwIfMissing(), ImgWithExtension = throwIfMissing(), W = 35, H = 35, alt = 'restaurant' }) => (
    <img
        className="shadow-sm rounded-circle d-inline me-2" alt={alt}
        src={getImgexAnySize(Endpoint, ImgWithExtension, W, H)} />
);

export const isJson = function (str, err = {}) {
    try {
        JSON.parse(str);
    } catch (e) {
        err.error = e;
        return false;
    }
    return true;
}


export const getType = (function () {

    var objToString = ({}).toString,
        typeMap = {},
        types = [
            "Boolean",
            "Number",
            "String",
            "Function",
            "Array",
            "Date",
            "RegExp",
            "Object",
            "Error"
        ];

    for (var i = 0; i < types.length; i++) {
        typeMap["[object " + types[i] + "]"] = types[i].toLowerCase();
    };

    return function (obj) {
        if (obj == null) {
            return String(obj);
        }
        // Support: Safari <= 5.1 (functionish RegExp)
        return typeof obj === "object" || typeof obj === "function" ?
            typeMap[objToString.call(obj)] || "object" :
            typeof obj;
    }
}());


export function throwIfMissing() {
    throw new Error('Missing parameter');
}

export const SendSentryException = (Sentry, errorMessage, variableName, variableValue) => {
    Sentry.withScope(function (scope) {
        if (variableName !== undefined && variableValue !== undefined) scope.setTag("ErrorVariable", JSON.stringify({ [variableName]: variableValue }));
        Sentry.captureException(new Error(errorMessage),);
    });
}







export const useResponsiveFontSize = () => {
    const getFontSize = () => (window.innerWidth < 450 ? "20px" : "24px");
    // const getFontSize = () => (window.innerWidth < 450 ? "10px" : "18px");
    const [fontSize, setFontSize] = useState(getFontSize);

    useEffect(() => {
        const onResize = () => {
            setFontSize(getFontSize());
        };

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    });

    return fontSize;
}


