import React, { useContext } from 'react';
import { InsightsContext } from "../Insights";
import './Restaurant.css';
import { DeliveryCountdown } from './DeliveryCountdown';

import { has, get } from 'lodash';
import { isJson } from '../togonowutils';
import { func_isEnabled } from '../redux/utils/aggregate'

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import Image from 'react-bootstrap/Image'
import { Link as RouterLink } from "react-router-dom";


const useStyles = makeStyles({
    root: {
        outline: 'none'
        // flexGrow: 1,
        // maxWidth: 500,
    },
    botton: {
        outline: 'none'
    },
    bodystyle: {

        // marginTop: -22,
        marginTop: '-12px',
        position: 'relative',

    },
    iconavatar: {
        width: 170,
        height: 170,
        // position: 'relative',
        // bottom: '24px',
        right: '-80px',
        maxWidth: '200px',

        // position: 'relative',
        // top: '8px',
        // right: '16px',
        // width: '200px',
        // height: '200px'

    },
    grayout: {
        opacity: '0.4',
        filter: 'alpha(opacity = 60)'
    }
});




function Restaurant({ subscriberid, selectSubscriber, locationSubscriberInfo, marketplaceclosed = false }) {
    const { appInsights } = useContext(InsightsContext);
    const restaurant_logourl = `https://togonowloc.imgix.net/${subscriberid}.jpg?w=200`; //(id, size = 250) => (`https://togonowloc.imgix.net/${id}.jpg?w=${size * 2}`);

    const isEnabled = func_isEnabled(locationSubscriberInfo, marketplaceclosed)
    const background = `https://togonowloc.imgix.net/${subscriberid}_background.png?w=600`;
    const { info, name } = locationSubscriberInfo;
    const classes = useStyles();


    return (
        <Grid item
            xs={6} sm={3}
            className={isEnabled || true ? '' : 'grayout'}
            style={{ cursor: 'pointer' }}>
            <CardActionArea component={RouterLink} to={`/s/${subscriberid}`}>
                <Card style={{ height: '100%' }} className={isEnabled ? '' : classes.grayout}             >
                    <CardMedia
                        component="img"
                        alt={name}
                        height="180"
                        image={background}
                        title={name} />

                    <Grid container alignItems="flex-start" justify="flex-end" direction="row" className={classes.bodystyle + " p-2 row justify-content-between"}>
                        {/* <Image src={restaurant_logourl}
                            className="shadow-sm p-1 mb-5 bg-white  rounded-circle "

                            style={{
                                position: "absolute", top: '-10px', right: '10px', width: "70px"
                            }} alt="Avatar" /> */}
                        <StoreInfo {...{ locationSubscriberInfo, marketplaceclosed }} />
                    </Grid>
                </Card>
            </CardActionArea>
        </Grid>
    )
}

const StoreInfo = ({ locationSubscriberInfo, marketplaceclosed }) => {
    const isEnabled = func_isEnabled(locationSubscriberInfo, marketplaceclosed);

    if (has(locationSubscriberInfo, 'info')) {
        const { info, name } = locationSubscriberInfo;
        const subtitle = isJson(info) ? JSON.parse(locationSubscriberInfo.info) : locationSubscriberInfo.info;

        return <CardContent>
            <span><Typography gutterBottom component={"h2"} variant={"h2"}>{name}</Typography>{isEnabled || <small className="text-danger  "><strong>closed!</strong></small>}</span>
            <Typography variant="body2" color="textSecondary" component="p">{subtitle}</Typography>
        </CardContent>
    }
    return <p className="mx-auto text-center storename text-center h4 text-wrap">...loading</p>;
};

export default Restaurant;
