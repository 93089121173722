import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import * as Sentry from '@sentry/browser';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
// import {SendSentryException} from '../togonowutils';
import './Menu.css';
// import ItemDetail from './ItemDetail';
import TimedPopOver from './TimedPopOver';
import { MarketplaceBanner } from './MarketplaceBanner';
import { AlertLine } from './AlertLine';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
// import { ClipLoader } from 'react-spinners';
import { Spinner } from '../components/tinyparts/Spinners'
import { Link } from 'react-router-dom';
// import NotificationSystem from 'react-notification-system';
import MarketplaceMessage from './tinyparts/MarketplaceMessage';


moment.locale();

// const __lgScreenDock = 0.4;
// const __smScreenDock = 0.9;

export default function ({ history }) {
    const mql = window.matchMedia(`(min-width: 800px)`);
    const notificationSystem = React.createRef();
    // const [cartDockVisible, setcartDockVisible] = useState(false);
    const [addedToCartPopOver] = useState(false);
    // const [addedItemTobag, setaddedItemTobag] = useState(false);
    // const [accountDropDownVisible, setaccountDropDownVisible] = useState( (mql.matches ? __lgScreenDock : __smScreenDock));
    // const [sizeOfDock, setsizeOfDock] = useState(false);
    const [smallScreen] = useState(!mql.matches);
    const [showSpinner, setshowSpinner] = useState(false);

    const { lastItemAddedToCart, availableSubscribers,
        appError, marketplacemessage, selectedSubscriber,
        marketplaceclosed, marketplaceinfo,
        loading, sinfo_loading, currentSubscriberMenu_loading
    } = useSelector(({ marketplaceReducer }) => ({
        lastItemAddedToCart: get(marketplaceReducer, 'lastItemAddedToCart'),
        availableSubscribers: get(marketplaceReducer, 'availableSubscribers'),
        appError: get(marketplaceReducer, 'appError'),
        marketplacemessage: get(marketplaceReducer, 'marketplacemessage'),
        selectedSubscriber: get(marketplaceReducer, 'selectedSubscriber'),
        marketplaceclosed: get(marketplaceReducer, 'marketplaceclosed'),
        marketplaceinfo: get(marketplaceReducer, 'marketplaceinfo'),
        loading: get(marketplaceReducer, 'loading'),
        sinfo_loading: get(marketplaceReducer, 'sinfo_loading'),
        currentSubscriberMenu_loading: get(marketplaceReducer, 'currentSubscriberMenu_loading'),

    }));

    useEffect(() => {
        let s = loading === 'loading' || sinfo_loading === 'loading' || currentSubscriberMenu_loading === 'loading';
        setshowSpinner(s);
    }, [loading, sinfo_loading, currentSubscriberMenu_loading])


    const addNotification = (event) => {
        if (event && event.preventDefault) event.preventDefault();
        let title = (lastItemAddedToCart && availableSubscribers && availableSubscribers[lastItemAddedToCart.subscriberid] ? availableSubscribers[lastItemAddedToCart.subscriberid].name + ' added' : '- - -');
        let message = (lastItemAddedToCart ? lastItemAddedToCart.qty + ' x ' + lastItemAddedToCart.item_Title + ' ' + lastItemAddedToCart.linetotal : 'nothing has been added');
        const notification = notificationSystem.current;
        notification.addNotification({
            message, title,
            level: 'success',
            position: 'tc',
            action: {
                label: 'Checkout >>',
                callback: () => {
                    history.push('/checkout');
                }
            }
        });
    };


    function toggle() {
        setTimeout(addNotification, 750);
    }


    return (<>
        <div id="bag"></div>
        <TimedPopOver toggle={toggle} >
            <Popover placement="bottom" isOpen={addedToCartPopOver} target="bag" >
                <PopoverHeader>{(lastItemAddedToCart && availableSubscribers && availableSubscribers[lastItemAddedToCart.subscriberid] ? availableSubscribers[lastItemAddedToCart.subscriberid].name + ' added' : '- - -')}</PopoverHeader>
                <PopoverBody>
                    {lastItemAddedToCart ? lastItemAddedToCart.qty + ' x ' + lastItemAddedToCart.item_Title + ' ' + lastItemAddedToCart.linetotal : 'nothing has been added'}
                    <div className="mx-auto">
                        <Link to='/checkout'>
                            <button type="button" onClick={() => history.push('/checkout')} className="btn-success btn-block">Checkout ></button>
                        </Link>
                    </div>
                </PopoverBody>
            </Popover>
        </TimedPopOver>
        <AlertLine {...{ appError }} />
        <MarketplaceMessage {...{ marketplacemessage }} />
        {!marketplaceclosed && <MarketplaceBanner  {...{ ...marketplaceinfo, smallScreen }} />}
        {showSpinner &&
            <div className="spinner vh-100">
                <Spinner />
            </div>}

    </>)
}