import React, { useState, useEffect } from 'react';
import { map, sortBy, values } from 'lodash';
// import MenuItem from './MenuItem';
import Restaurant from './Restaurant';
// import SubscriberHeader from './SubscriberHeader';
// import { MarketplaceSection } from './MarketplaceSection';
import { func_isEnabled } from '../redux/utils/aggregate'

import Grid from '@mui/material/Grid';


export default function ({  availableSubscribers, addItem, addedItemTobag, marketplaceclosed, selectItem, clearSelectedSubscriber, selectSubscriber }) {

  if (! availableSubscribers) return null;

  const [sections] = useState(null)
  const [sorted_availableSubscribers, setSorted_availableSubscribers] = useState(null)


  useEffect(() => {

    // let idx = 0;
    let __shift_availableSubscribers = sortBy(values(availableSubscribers), subscriberinfo => {
      return !func_isEnabled(subscriberinfo, marketplaceclosed);
    })
    setSorted_availableSubscribers(__shift_availableSubscribers);


  }, [ availableSubscribers])

  let idx = 0;

  return <>
    <div className=" m-sm-0   justify-content-center cccc" >
        <h2 className={`mb-4 text-center ${marketplaceclosed ? 'text-muted' : ''}`}>Order from Multiple Restaurants</h2>
        <Grid container spacing={1} alignItems="stretch" style={{margin: 0, width: '100%',}}>
          {map(sorted_availableSubscribers, (locationSubscriberInfo) => {
            if (locationSubscriberInfo.isHidden === true) return null;
            const grayout = func_isEnabled(locationSubscriberInfo, marketplaceclosed);  //marketplaceclosed || false;
            idx = idx + 1;
            const { subscriberid } = locationSubscriberInfo;
            return (
              <Restaurant key={subscriberid} {...{
                subscriberid, selectSubscriber,
                locationSubscriberInfo,
                clearSelectedSubscriber,
                marketplaceclosed,
                grayout
              }} />
            )
          })}
        </Grid>
      {sections && sections}
    </div>
  </>
}