import React, { useEffect, useState } from 'react'
import { Errored, Spinner } from '../tinyparts/Spinners';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { selectCard, switchPaymentMode } from '../../redux/actions/marketplaceActions';
import { Nav, NavItem, NavLink, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, TabContent, TabPane } from 'reactstrap'
import { getType } from '../../togonowutils'; 
import Card from './Card.js';

export default ({ stripeProfile }) => {

    let [errorPE, setErrorPE] = useState(null);
    const [activeTab, setActiveTab] = useState('PaymentElement');  //
    const [dropdownOpen, setDropdownOpen] = useState(false);



    const toggle = () => setDropdownOpen(!dropdownOpen);

    const { selectedCard, paymentMode } = useSelector((state) => ({
        paymentMode: state.marketplaceReducer.paymentMode || 'PaymentElement',
        selectedCard: state.marketplaceReducer.selectedCard 
    }));
    const dispatch = useDispatch();

    const selectSavedPayment = (e) => {
        let card = stripeProfile.sources.find((c) => c.id === e.currentTarget.getAttribute("paymentid"))
        dispatch(selectCard(card))
    }

    // When 
    useEffect(() => {
        setActiveTab(selectCard ? "SavedPayment" : "PaymentElement")
    }, [selectedCard])

    useEffect(() => {
        // if paymentMode is null, decide if we need to show apple pay or saved payments
        if(paymentMode === null){
            // does the user have saved payments
            if(stripeProfile && stripeProfile.sources.length > 0){
                dispatch(switchPaymentMode('SavedPayment'))
            }else{
                dispatch(switchPaymentMode('PaymentElement'))
            }
        }
        paymentMode === 'PaymentElement' ? setActiveTab("PaymentElement") : setActiveTab("SavedPayment")
        
    }, [paymentMode])

    const switchTab = (e) => {
        e.preventDefault();
        dispatch(switchPaymentMode(e.currentTarget.getAttribute("tabid")))
    }
    

    return <>
        <Nav tabs  >
            <NavItem  >
                <NavLink onClick={switchTab} tabid={"PaymentElement"} active={activeTab === "PaymentElement"}>Credit Card & Mobile Pay</NavLink>
            </NavItem>

            {stripeProfile && <Dropdown onClick={switchTab} tabid={"SavedPayment"} nav isOpen={dropdownOpen} toggle={toggle} >
                <NavLink className='p-0 m-0' active={(activeTab === "SavedPayment")}>
                    <DropdownToggle nav caret  >
                        {stripeProfile === 'loading' && <Spinner />}
                        Saved Payment
                    </DropdownToggle>
                </NavLink>
                <DropdownMenu >
                    {getType( stripeProfile) === 'object' && stripeProfile.sources.map(({ id, card }, index) => (
                        <DropdownItem key={id} paymentid={id}
                            onClick={selectSavedPayment}
                            className='m-2'>{card.brand.charAt(0).toUpperCase() + card.brand.slice(1)} {card.last4}</DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            }
        </Nav>

        <TabContent activeTab={activeTab}>
            <TabPane tabId="PaymentElement" className={'b-1'}>
                {!!errorPE && <Errored message={errorPE.message} />}
                <PaymentElement onLoadError={(e) => setErrorPE = e} />
            </TabPane>
            <TabPane tabId="SavedPayment" className={'b-1'} >
                {getType( stripeProfile) === 'object' && (!!selectedCard ?

                    <div className={'d-flex flex-grow-3 justify-content-center align-items-center'} style={{ height: '100px' }}>
                        <Card source={selectedCard} />
                    </div>
                    : <div className={'d-flex align-items-center justify-content-center'} style={{ height: '100px' }}>select or add payment</div>)}
                {stripeProfile === 'loading' && <Spinner />}
            </TabPane>
        </TabContent>
    </>

}





