import moment from 'moment-timezone';

const Expiry = (subscriberInfo)=>{

    const {latestordertime, deliverytime} = subscriberInfo;
    let nextupdate = null, nextDeliveryBy = null, nextOrderBy = null;
    let now = moment().tz("America/Los_Angeles"); 
    //** Calculate the order by for today */
    let tempOrderbyToday = moment(latestordertime, 'H:mm A').tz("America/Los_Angeles");
    let tempDeliveryByToday = moment(deliverytime, 'H:mm A').tz("America/Los_Angeles");

    let timeLeft_MS_ToOrderToday = tempOrderbyToday.diff(now, 'seconds');
    let timeLeft_MS_ToDeliveryToday = tempDeliveryByToday.diff(now, 'seconds');
    let orderby_date = tempOrderbyToday.format('L') 
    let now_date = now.format('L') 
    let delivery = null;
    let fulfill_today = timeLeft_MS_ToOrderToday > 0 && (orderby_date === now_date);
    let canOrder = fulfill_today && timeLeft_MS_ToOrderToday > 0;
    if(canOrder){
        delivery = tempDeliveryByToday;
        nextupdate = timeLeft_MS_ToOrderToday;
    }else{
        nextDeliveryBy = moment(tempDeliveryByToday).add(1,'day');
        nextOrderBy = moment(tempOrderbyToday).add(1,'day');
        if( timeLeft_MS_ToOrderToday<= 0){  // passed the order by time
            if(timeLeft_MS_ToDeliveryToday >= 0){   // between order time and delivery
                nextupdate = nextOrderBy.diff(now,"seconds");
                // nextupdate = 'a'
            }else if(timeLeft_MS_ToDeliveryToday < 0){  // after order time and delivery time
                nextupdate = nextOrderBy.diff(now, 'seconds');
            }else{
                throw new Error('somthing wrong');
            }
        }else{
            return {
                tempOrderbyToday,
                timeLeft_MS_ToOrderToday, now}
        }
    }

    return {
        fulfill_today : fulfill_today, 
        delivery : canOrder ? delivery.utc().format(): null, 
        delivery_f : canOrder ? delivery.tz("America/Los_Angeles").format('LT'): null, 
        orderby : canOrder ? tempOrderbyToday.utc().format(): null,
        orderby_f : canOrder ? tempOrderbyToday.tz("America/Los_Angeles").format('LT') : null,
        nextupdate,
        nextDeliveryBy : nextDeliveryBy ? nextDeliveryBy.utc().format() : null,
        nextDeliveryBy_f : nextDeliveryBy ? nextDeliveryBy.tz("America/Los_Angeles").calendar() : null,
        nextOrderBy:  nextOrderBy ? nextOrderBy.utc().format() : null,
        nextOrderBy_f:  nextOrderBy ? nextOrderBy.tz("America/Los_Angeles").calendar() : null
    }
}
export {Expiry}