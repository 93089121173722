import React, { useContext, useState, useMemo } from 'react';
import { InsightsContext } from "../Insights";
import accounting from 'accounting';
import { has, get } from 'lodash';
import { getImgexFitCrop, throwIfMissing } from '../togonowutils';
import './MenuItem.css';
import { DeliveryCountdown } from './DeliveryCountdown';
import { useParams, useLocation } from "react-router-dom";
import {
    selectItem,
    addItem,
} from '../redux/actions/marketplaceActions';
import { useSelector, useDispatch } from 'react-redux';


//TODO handle add quickly
function MenuItem({ menuItem = throwIfMissing(), addedItemTobag, appBoundary }) {

    const [showAddQuickly, setShowAddQuickly] = useState(false);
    const [showHover, setShowHover] = useState(false);
    const { appInsights } = useContext(InsightsContext);

    const dispatch = useDispatch();
    let params = useParams();

    const { marketplaceclosed, subscriberinfo, } = useSelector(({ marketplaceReducer }) => ({
        marketplaceclosed: get(marketplaceReducer, 'marketplaceclosed'),
        subscriberinfo: get(marketplaceReducer, `availableSubscribers[${get(marketplaceReducer, `selectedSubscriber`)   || params['subscriberid']    }]`),
        // subscribermenu: get(marketplaceReducer, `availableSubscribers[${get(marketplaceReducer, `selectedSubscriber`) || params['subscriberid'] }].menu`),
        // selectedSubscriber: get(marketplaceReducer, `selectedSubscriber`),
        // currentSubscriberMenu_loading: get(marketplaceReducer, `currentSubscriberMenu_loading`),
    }));

    function handleSelectItem(e) {
        if (e) e.preventDefault();
        dispatch(selectItem(menuItem));
        const { name: Restaurant } = subscriberinfo;
        const { item_ID: Id, item_Title: Item } = menuItem;
        appInsights.trackEvent({ name: 'ItemSelected' }, { Id, Item, Restaurant });
    }


    function restaurant_tinylogourl(id: string) { return (`https://togonowloc.imgix.net/${id}.jpg?w=45`) };
    const { item_Description, item_Image } = menuItem;



    const fulfillToday = get(subscriberinfo, 'expiry.fulfill_today', true);
    const dte_nextorderbytoday = get(subscriberinfo, 'expiry.orderby', null);
    const subscriberDisabled = get(subscriberinfo, 'disabled', false);

    const isEnabled = fulfillToday && !marketplaceclosed && !subscriberDisabled;




    return (
        <div id="menuitem"
            style={{
                'boxShadow': (showHover ? '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(75, 181, 67, 0.19)' : '')
            }}
            onMouseEnter={e => { setShowAddQuickly(true); setShowHover(true); }}
            onMouseLeave={e => { setShowAddQuickly(false); setShowHover(false); }}
            className={`${!isEnabled ? 'grayout' : ''}  card col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4 mx-1 px-0`}
        >
            {item_Image &&
                <div className="itemimage_wrapper" onClick={(e) => handleSelectItem(e)} >
                    <img className="card-img-top  img-fluid p-1 bg-white itemimage" src={getImgexFitCrop('togonow', item_Image)} alt={menuItem.item_Title} />
                    {/* {subscriberinfo && <img alt={subscriberinfo && subscriberinfo.name} className="tinylogo shadow-sm rounded-circle" src={restaurant_tinylogourl(subscriberinfo.subscriberid)} />} */}
                </div>
            }


            <p
                onClick={(e) => handleSelectItem(e)}
                className="px-2 pt-2 mb-0 itemtitle">
                {menuItem.item_Title}
            </p>
            {item_Description &&
                <div
                    onClick={(e) => handleSelectItem(e)}
                    className="card-body px-2 py-2 itemdescription text-left font-weight-light text-muted overflow-hidden">
                    {item_Description}
                </div>
            }
            <div className="px-2 itemprice mb-1 mt-auto">
                <div className='mt-2'>
                    {accounting.formatMoney(menuItem.item_Price)}
                    {subscriberinfo && subscriberinfo.expiry &&
                        <React.Fragment>
                            {subscriberinfo.expiry.delivery_f && <small> | Delivery at {subscriberinfo.expiry.delivery_f}</small>}
                            {subscriberinfo.expiry.nextDeliveryBy_f && <small className={isEnabled ? `text-success` : 'font-italic'}> Delivery {subscriberinfo.expiry.nextDeliveryBy_f}</small>}
                            <DeliveryCountdown textFormat={'font-weight-bold'} nextOrderbyToday={dte_nextorderbytoday} />
                        </React.Fragment>
                    }
                </div>
            </div>
            {!marketplaceclosed && <AddQuickly {...{ menuItem, showAddQuickly, handleSelectItem, addedItemTobag, subscriberinfo, appBoundary }} />}
        </div>
    )


}


const AddQuickly = ({ menuItem, showAddQuickly, handleSelectItem, addedItemTobag, subscriberinfo, appBoundary }) => {
    const shouldCustomize = has(menuItem, 'OptionGroups') && menuItem.OptionGroups.length > 0;
    const dispatch = useDispatch();
    const { appInsights } = useContext(InsightsContext);
    if (!menuItem.subscriberid) menuItem.subscriberid = subscriberinfo.subscriberid;
    const handleAddQuickly = () => {
        const qty = 1
        dispatch(addItem(menuItem, qty));

        appBoundary.toggleAddedToCart(subscriberinfo.name, `${qty} x ${menuItem.item_Title}.`)
        appInsights.trackEvent({ name: 'ItemAddedToCart' },
            {
                id: menuItem.item_ID,
                title: menuItem.item_Title,
                price: menuItem.item_Price,
                qty: 1,
                subscriberid: menuItem.subscriberid,
                addquickly: 'addquickly'
            });


    }
    return (
        <div className={`addQuickly_wrapper text-center bg-white ${!showAddQuickly && 'd-none'}`} >
            <button type="button" className="btn btn-success" onClick={() => {
                (shouldCustomize ? handleSelectItem() : handleAddQuickly())
            }} >
                <span className="">{shouldCustomize ? 'Customize' : 'Add Quickly'}</span>
            </button>
        </div>
    )
};

export default MenuItem;