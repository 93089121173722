import { createStore, applyMiddleware , compose} from 'redux';
import togonowReducer from './reducers';
import promise from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = [thunkMiddleware, promise]
// *** DO NOT DELETE, this disabled redux logger
// if (process.env.NODE_ENV !== 'production') {
//   const {logger} = require('redux-logger')
//   middleware = [...middleware, logger]
// }

export default createStore(
  togonowReducer,
  composeEnhancers(
    applyMiddleware(...middleware)
  )
); 