import React from 'react';
import './Receipt.css';
import moment from 'moment';
import * as A from 'accounting';
import { plural } from '../togonowutils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowRight, faCircle,
    faReceipt, faColonSign,
    faHandDots,
    faThumbsUp, faBan, faIceCream, faHandHoldingUsd,
    faClock, faDollarSign, faNetworkWired
} from '@fortawesome/free-solid-svg-icons'
import { ImgTagIconCircle } from '../togonowutils';
import { AlertLine } from './AlertLine';
import { sumBy, find } from 'lodash';
import accountingLibrary from 'accounting';


export const ReceiptHeader = ({ accounting, order, locationinfo, showDetails = false, goToOrderDetailAction }) => {
    if (!accounting) return;
    const { createdat } = order;
    let sid_count = order.subscriberids.length
    return <div className='row mb-2'>
        <div className="card col-md-6 mx-auto px-0">
            <div className="card-header d-flex align-items-center">
                <div>
                    {order.subscriberids.map((sid, idx) => (
                        <React.Fragment key={idx}>
                            {idx > 0 && idx <= sid_count && <span color='text-secondary'> • </span>}
                            <ImgTagIconCircle key={sid} Endpoint="togonowloc" ImgWithExtension={sid + '.jpg'} />
                        </React.Fragment>
                    ))}
                </div>
                <div className="ms-auto">{moment(createdat).format('LLLL')}</div>
            </div>
            <div className="card-body">
                <div className="container text-center">
                    <div className="d-flex justify-content-end">
                        <p className="me-1 ">Subtotal:</p>
                        <p className="me-2">{A.formatMoney(accounting.subtotal)}</p>
                        <p className="me-1 ">Tax:</p>
                        <p className="me-2">{A.formatMoney(accounting.tax)}</p>
                        <p className="me-1 fw-bolder">Total:</p>
                        <p className="me-2 fw-bolder"> {A.formatMoney(accounting.total)}</p>
                    </div>
                    {showDetails && <div className='d-flex justify-content-end'>
                        <button type="submit" onClick={() => goToOrderDetailAction(order)} className="btn btn-success  ">
                            <FontAwesomeIcon icon={faReceipt} color="white" transform="grow-4 left-2" />{'  '}Reciept</button>
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export const ReceiptSubscriber = ({ carts, order, accounting }) => {
    if (!carts || !order || !accounting) return <AlertLine  {...{ appError: new Error('Invalid order details') }} />;
    return Object.keys(carts).map((sid) => {
        const cart = carts[sid];
        const cartItems = cart.cartItems
        const count_items = cartItems.length;
        const sid_subtotal = accountingLibrary.formatMoney(sumBy(cartItems, ci => {
            var __linetotal = Number(ci.linetotal.replace(/[^0-9.-]+/g, ""));
            return __linetotal
        }))
        const { name, Delivery } = cart;
        return (
            <div className="row" key={sid}>
                <div className="card col-md-6 mx-auto  mb-2 px-0">

                    <div className="card-header d-flex text-middle ">
                        <ImgTagIconCircle key={sid} Endpoint="togonowloc" ImgWithExtension={sid + '.jpg'} />{name && name}
                        <div className="ms-auto ">{Delivery && `Delivery: ${Delivery}`}</div>
                    </div>
                    <div className="card-body" >
                        {/* <table className="table table-hover"> */}
                        <div className="container">
                            {carts[sid].cartItems.map(
                                (cartItem, idx) =>
                                    <div className="d-flex" key={idx}>
                                        <div className="col-md-8">{cartItem.qty} x {cartItem.item_Title}</div>
                                        <div className="col-md-4">{cartItem.linetotal}</div>
                                    </div>
                            )}
                            <div className="d-flex  fw-bolder border-top " >
                                <div className="text-muted col-md-8">{count_items} Item{plural(count_items)}</div>
                                <div className="col-md-4">{sid_subtotal}</div>
                            </div>
                        </div>
                        {/* </table> */}
                    </div>
                </div>
            </div>
        )
    }

    )
};


export const OrderList = ({ orders, goToOrderDetailAction }) => orders.map(currentOrder => (
    // <React.Fragment key={currentOrder.id}>
        <ReceiptHeader key={currentOrder.id}  {...{
            goToOrderDetailAction,
            accounting: { subtotal: currentOrder.subtotal, tax: currentOrder.taxamount, total: currentOrder.total },
            order: currentOrder,
            showDetails: true
        }} />

    // </React.Fragment>
    )
);
