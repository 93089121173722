// Import FirebaseAuth and firebase.
import React, {useContext, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { BackTo } from './tinyparts/BackTo';
import { withRouter } from "react-router-dom";
import {InsightsContext}  from "../Insights";

const Privacy = () => {
  let history = useHistory();
  const {appInsights} = useContext(InsightsContext);
  appInsights.trackEvent({name: 'PageLoaded', value: 'Privacy'});
  useEffect(() => {
    document.title = `ToGoNow Privacy Policy`;
  });
  return (
    <div className="container">
      <div className="row">
        <div className="col-md">
          <BackTo {...{ history }} />
          <div className="card-header mb-2">Our Privacy Policy</div>
          ToGoNow | Privacy Policy
        </div>
      </div>
    </div>
  );
}

export default withRouter(Privacy);