import React, {Fragment} from 'react';
import accounting from 'accounting';

export const ShowMarkupTag = ({calculatedmarkup})=>(calculatedmarkup > 0 ?  <span className="text-success small">({accounting.formatMoney(calculatedmarkup)})</span> : null)

export const FormatedOptionValue = ({optionvalue_value})=>{
    let arr = [], c = 0;
    optionvalue_value.split(/([()])/).filter(Boolean).forEach(e =>
        e === '(' ? c++ : e === ')' ? c-- : c > 0 ? arr.push('(' + e + ')') : arr.push(e)
        );
    if(arr.length === 2){   
        return <Fragment>
            {arr[0]}
            {<small>{arr[1]}</small>}
            </Fragment>;
    }
    return <Fragment>{optionvalue_value}</Fragment>
}


//*** CREDIT CARD ICONS */
export function CCIcon({brand, height='75', width=null}){
    if(!brand) return;
    let cc = brand.toLowerCase();
    let strHeight = `height=${height}` 
    return <img 
      src={`https://togonowassets.imgix.net/cc_${cc}.png?${strHeight}`}
      alt={cc}
      style={{height, width}} />  
  };


 export function showChatWidget(e){
    e.preventDefault();
    eval("$zoho.salesiq.floatwindow.visible('show')");
  }