import { chain, keys, map, forOwn, size, mapValues, find, get} from 'lodash';

import {accounting as acct}   from 'accounting';
import { createSelector } from 'reselect';
import memoize from 'lodash.memoize'

import {getSubscriberIdsFromMarketplaceMenu} from '../utils/aggregate';
import {throwIfMissing} from '../../togonowutils'
const marketplacemenu = state => state.marketplaceReducer.marketplacemenu;
const stateSCarts = state => state.marketplaceReducer.carts;
const stripeProfile = state => state.marketplaceReducer.stripeProfile;
const selectedCard = state => state.marketplaceReducer.selectedCard;
const availableSubscribers = state => state.marketplaceReducer.availableSubscribers;

//** used to generate order object.
const email = state => get(state, 'marketplaceReducer.checkout.email', null);
const name = state => get(state, 'marketplaceReducer.checkout.name', null);
const phone = state => get(state, 'marketplaceReducer.checkout.phone', null);
const deliveryvalue = state => get(state, 'marketplaceReducer.checkout.deliveryvalue', null);
const couponResponse = state => get(state, 'marketplaceReducer.checkout.couponResponse', null);
const carts = state => get(state, 'marketplaceReducer.carts', null);
const locationid = (state) =>    get(state, 'marketplaceReducer.customerlocation.id', null);
const customerlocation = state => get(state, 'marketplaceReducer.customerlocation', null);
const accounting = state =>  calculateCartAccounting(carts(state), couponResponse(state)).accounting;
const subscribers_totals = state =>  calculateCartAccounting(carts(state)).subscribers_totals;

export const menuSections = createSelector(
  [marketplacemenu],
  (marketplacemenu) => {
    return keys(marketplacemenu);
  }
)

export const cartItemCount = createSelector(
    stateSCarts,
    (stateSCarts) => {
        let count = 0
        map(stateSCarts,  (sCartObject, sid) =>{
            sCartObject.cartItems.map((cartItem)=>{
                return count = count + Number(cartItem.qty)
            })

        });
        return count;
    }
)

export const subscriberCartsArray = createSelector([stateSCarts], (stateSCarts)=>{
    const  scarts = [];
    forOwn(stateSCarts, (scart, sid)=>{
      scarts.push(scart);
    });
    return scarts;
})

export const marketplaceSubscriberIds = createSelector(
    [marketplacemenu],
    (marketplacemenu)=> {
        return getSubscriberIdsFromMarketplaceMenu(marketplacemenu);
    }
)

export const expensiveSubscriberInfosSelector = createSelector(
    state => state.availableSubscribers,
    availableSubscribers => memoize(
      (subscriberid=throwIfMissing()) => get(availableSubscribers, subscriberid, null)
    )
)

export const expensiveSubscriberInfo = (__state, subscriberid)=> expensiveSubscriberInfosSelector(__state)(subscriberid);

export const expensiveSubscriberExpiry = (__state, subscriberid)=> get(expensiveSubscriberInfosSelector(__state)(subscriberid), 'expiry', null);
//***************************** */
//** Delivery info for locaiton */
//***************************** */
export const deliverytimes = createSelector([availableSubscribers], (availableSubscribers)=>{
    return chain(availableSubscribers).map('deliverytime').uniq().sort().value();
});


export const deliverytimes_utc = createSelector([availableSubscribers], (availableSubscribers)=>{
    return chain(availableSubscribers).map('deliverytime').uniq().sort().value();
});



/*************************
 * GLOBAL CART Accounting
**************************/
export const cartsAccounting = createSelector(
  [stateSCarts, couponResponse],
  (stateSCarts, couponResponse)=>{
        return calculateCartAccounting(stateSCarts, couponResponse);
  }  
);



export const checkoutPageEmpty = createSelector([email, name, phone, deliveryvalue], (email, name, phone, deliveryvalue)=>{
    return (email === null &&  name === null &&   phone === null &&   deliveryvalue === null  )
})


export const orderSubmissionObject = createSelector(
    [email, name, phone, deliveryvalue, carts,  locationid, customerlocation, accounting, couponResponse, subscribers_totals], 
    (email, name, phone, deliveryvalue, carts, locationid, customerlocation, accounting, couponResponse, subscribers_totals)=>{
        const cartsTrimmed = carts &&  mapValues(carts, c => c.trimmed());
        let _O = {
            order: {email, name, phone ,locationid, location: customerlocation, deliveryvalue},
            carts : cartsTrimmed,
            accounting,
            subscribers_totals,
        };
        if(couponResponse){
            _O.coupon = couponResponse.code
        }
        return _O
    }
);

/*********************
 ** STRIPE RELATED **
*********************/
export const selectedOrDefaultCard = createSelector(
    // [],
    [stripeProfile, selectedCard],
    (s_profile, s_card) => {
        if(s_card){
            return s_card;
        }else if(s_profile && s_profile.default_source){
            return   find(s_profile.sources, ['id', s_profile.default_source]);
        }
        return undefined;
    } 
);

export const calculateCartAccounting = (stateSCarts, couponResponse) => {

    let accounting_obj = {
        subscribers_totals: {},
        accounting: {
            subtotal: "0.00",
            coupon: "0.00",
            tax: "0.00",
            total: "0.00"
        }
    };
    if(!stateSCarts) return accounting_obj;
    Object.keys(stateSCarts).map((scartkey, index) => {
        let scart = stateSCarts[scartkey];
        accounting_obj.subscribers_totals[scartkey] = scart.accounting_getSubscriberTotal;
        accounting_obj.accounting.subtotal = acct.toFixed(Number.parseFloat(accounting_obj.accounting.subtotal) + Number.parseFloat(scart.accounting_getSubscriberTotal), 2);
        return accounting_obj;
    });
    if(couponResponse){
        accounting_obj.accounting.coupon = discountAmount(accounting_obj.accounting.subtotal, couponResponse);
        accounting_obj.accounting.subtotal = acct.toFixed(Number.parseFloat(accounting_obj.accounting.subtotal)  - Number.parseFloat(accounting_obj.accounting.coupon), 2);
    }
    accounting_obj.accounting.tax = acct.toFixed(Number.parseFloat(accounting_obj.accounting.subtotal) * 0.09, 2);
    accounting_obj.accounting.total = acct.toFixed(Number.parseFloat(accounting_obj.accounting.subtotal) + Number.parseFloat(accounting_obj.accounting.tax), 2);
    return accounting_obj;
}

const discountAmount = function(strAmount, {amount, amounttype}){
    let amtFloat = Number.parseFloat(strAmount);
    let couponFloat = Number.parseFloat(amount);

    switch (amounttype){
        case 'percent':
            let p = acct.toFixed(amtFloat * (couponFloat/100),2);
            return p
        case 'fixed':
            return acct.toFixed(couponFloat, 2);
        default: 
        return '0.00';
    }
}