import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Link, Redirect } from 'react-router-dom';
import configFile from '../config';

import {
    selectCard as __selectCard,
    updateCheckoutAction as __updateCheckoutAction,
    submitOrderFulfilled,
    SetPaymentIntentAction,
    getStripeProfile
} from '../redux/actions/marketplaceActions';

import { useSelector, useDispatch } from 'react-redux';
import {
    selectedOrDefaultCard as _selectedOrDefaultCard,
    cartsAccounting as _cartsAccounting,
    orderSubmissionObject as _orderSubmissionObject
} from '../redux/selectors';
import { AuthContext } from "../Auth.js";
import { mapValues, pick } from 'lodash';
import { Errored } from './tinyparts/Spinners';
import { Elements, } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutParts/CheckoutForm';
import { getType } from '../togonowutils';

export default function () {
    // ********************** store PROPS and Selectors
    const {
        carts, checkout, stripeProfile, customerlocation, customerId,
        paymentIntent,
        selectedOrDefaultCard, cartsAccounting,
    } = useSelector((state) => ({
        carts: state.marketplaceReducer.carts,
        checkout: state.marketplaceReducer.checkout,
        stripeProfile: state.marketplaceReducer.stripeProfile,
        customerId: state.marketplaceReducer.customerId,
        customerlocation: state.marketplaceReducer.customerlocation,
        paymentIntent: state.marketplaceReducer.paymentIntent,

        cartsAccounting: _cartsAccounting(state),
        orderSubmissionObject: _orderSubmissionObject(state),
        selectedOrDefaultCard: _selectedOrDefaultCard(state),
    }));
    // const { idToken } = useContext(AuthContext);
    const { currentProfile, loading, idToken } = useContext(AuthContext);

    const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))
    const dispatch = useDispatch();
    const [error, setError] = useState(null)
    const [redirectUrl, setRedirectUrl] = useState(null)


    const setRedirect = (currentOrderId) => {
        dispatch(submitOrderFulfilled(currentOrderId));
        setRedirectUrl(`/orders/id/${currentOrderId}?delayed=true`)
    }

    async function hydatePaymentIntent(bundledInputs = {}) {
        try {
            if(bundledInputs.name){
                debugger;
            }
            let objBody = {
                ...cartsAccounting,
                ...bundledInputs,
                carts: mapValues(carts, c => c.trimmed()),
                order: { location: { id: customerlocation.id } },
                customerlocation: pick(customerlocation, ['address', 'structured_formatting', 'id', 'latLng', 'placeId']),
            };

            if (paymentIntent) {
                objBody.paymentIntentId = paymentIntent.id;
            }
            // make sure the selected Card is part of the stripe profile
            if (selectedOrDefaultCard && getType( stripeProfile) === 'object') {
                if (selectedOrDefaultCard in stripeProfile.sources) {
                    objBody.payment_method = selectedOrDefaultCard.id; //'pm_1K3lHsCehxCgI1R19gVAv7VY';
                }
            }

            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_DOMAIN}/api/payment/payment-intent`,
                data: objBody
            };
            if (!!idToken) config.headers = { 'Authorization': 'Bearer ' + idToken }
            const response = await configFile.Axios(config);

            if (response.data.status === 'succeeded') {
                // if the payment intent is the same as what we have in session, we need to get a new one
                if (paymentIntent.id === response.data.id) {
                    // clear the cart
                    dispatch(submitOrderFulfilled(response.data.id));
                } else {
                    delete objBody.paymentIntentId;
                    const r = await configFile.Axios(config);
                    dispatch(SetPaymentIntentAction(r?.data));
                }
            } else {
                dispatch(SetPaymentIntentAction(response.data));
                setError(null)
            }
            return true


        } catch (error) {
            setError(error.message)
            console.log('💣error', error)
            console.log('💣error.stack', error.stack)
            return false
        }
    }

    useEffect(() => {
        hydatePaymentIntent()
        document.title = `ToGoNow Checkout`;
        
    }, [])

    useEffect(()=>{
        if (currentProfile) {
            idToken && dispatch(getStripeProfile(idToken));
        }
        
    }, [currentProfile])
    

    if (error) {
        return <Errored message={error} size={'2x'}  >
            {/* <button type="button" onClick={()=>hydatePaymentIntent()} className="btn btn-link">Retry</button> */}
        </Errored>
    }

    if (redirectUrl) {
        return <Redirect to={redirectUrl} />
    }

    return paymentIntent ? <Elements stripe={stripePromise} options={{ clientSecret: paymentIntent.client_secret }}>
        <CheckoutForm setRedirect={setRedirect} hydatePaymentIntent={hydatePaymentIntent} />
    </Elements> : 'loading'
}
