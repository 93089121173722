import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';

export default ({isOpen, toggle, title, body, footer, strOk ='Ok', strCancel, card})=> <Modal {...{isOpen, toggle}} 
          backdrop={true}>
          {title && <ModalHeader 
          >{title}</ModalHeader>}
          {body && <ModalBody>
            {body}
          </ModalBody>}
          <ModalFooter>
            <Button id='ok' color="primary" 
              card={card}
              onClick={toggle}
            >{strOk}</Button>
            {strCancel && <Button id='cancel' color="secondary" onClick={toggle}>{strCancel}</Button>}
          </ModalFooter>
          </Modal>