import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import * as Sentry from '@sentry/browser';

import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import { useHistory } from "react-router-dom";
import { trimAddress, parseAddress, SendSentryException, getType } from '../togonowutils';

const SearchForNewAddress = () => <DropdownItem className={'p-1'} id="searchaddress">Enter delivery address</DropdownItem>;


function LocationSelection(props) {

  const { customerlocation, locations, color, setLocation, getPlaceByID } = props;
  let history = useHistory();
  const [locationDropDownOpen, setlocationDropDownOpen] = useState(false);

  function handleContinue(event) {
    event.preventDefault();
    let placeId = event.target.value || null;
    if (setLocation && placeId && (placeId !== 'false' && placeId !== false)) {
      let location = find(locations, { placeId });
      setLocation(location);
      getPlaceByID(placeId)
      history.push({ pathname: `/menu` })
    } else {
      SendSentryException(Sentry, 'Attempting to set a location with an invalid placeId', 'placeId', placeId)
    }
  }


  const toggle_locationDropDown = (e) => {
    setlocationDropDownOpen(locationDropDownOpen => !locationDropDownOpen);
    switch (e.target.id) {
      case 'searchaddress':
        history.push({ pathname: `/address` });
        break;
      default:
    }
  }


  if(!customerlocation) return null;

  return (
    <div>
      <Dropdown isOpen={locationDropDownOpen} toggle={toggle_locationDropDown} className={'mb-2 text-right'}>
        <DropdownToggle color={color || 'outline-light'} className={'border-0'} >
            {props.children || <span className={'d-none d-sm-inline'}>Delivery: </span>} 
            <span className='dottedUnderline '>{customerlocation && parseAddress(customerlocation.address)}</span>
        </DropdownToggle>
        <DropdownMenu >
          {getType(locations) === "array" ? (
           locations.map((l, idx) => <DropdownItem
                onClick={handleContinue}
                key={l.placeId || `location_${idx}`}
                value={l.placeId} >
                {trimAddress(l.address)}
              </DropdownItem>).concat(<SearchForNewAddress key={'searchfornewaddress'} />)
            ): <SearchForNewAddress />}
        </DropdownMenu>
      </Dropdown>
    </div>
  )

}



export default LocationSelection;