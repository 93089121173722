import React, {  useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function PopUp({popupTitle, popupSubtitle}){
    const [addedToCart, setAddedToCart] = useState(false);
    const [title, setTitle] = useState(null);
    const [subtitle, setSubtitle] = useState(null);
  

    const history = useHistory();


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAddedToCart(false)
        setTitle(null);
        setSubtitle(null);
    };

    useEffect(()=>{
        if(popupTitle){
            setAddedToCart(true);
            setTitle(popupTitle)
            setSubtitle(popupSubtitle)
        }
    }, [popupTitle, popupSubtitle])

    return <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={addedToCart}
        autoHideDuration={6000}
        onClose={handleClose} >
            <Alert onClose={handleClose} 
                // action={<Button color="inherit" size="small" onClick={() => {history.push('/checkout'); handleClose();}}>Checkout</Button>}
                action={
                <IconButton color="inherit" aria-label="go to checkout" onClick={() => {history.push('/checkout'); handleClose();}}>
                    <AddShoppingCartIcon /> <ChevronRightIcon />
                </IconButton>
                }
                severity="success" elevation={6} 
                variant="filled" >
                <AlertTitle><strong>{title}</strong> added</AlertTitle>
                {subtitle}
            </Alert>




            {/* <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton> */}


      </Snackbar>
    
}