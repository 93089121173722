import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrashAlt} from '@fortawesome/free-solid-svg-icons'

const QtyStepper = (props) => {
    const {qty, changeQtyItemInCart, itemInCart} = props;
    return (
        <div>
            <small className="Media btn-group  btn-group-sm" role="group" aria-label="Basic example">
                        {/* <button type="button" className="btn btn-success  btn-lg" onClick={(e) => changeQtyItemInCart(itemInCart, +qty + +1)}>
                            <FontAwesomeIcon icon={faMinus} color="white" transform={`grow-2x`} />
                        </button> */}
                {   qty > 1 ? 
                
                    <button type="button" className="btn btn-success btn-lg" onClick={(e) => changeQtyItemInCart(itemInCart, +qty - +1)} >
                         <FontAwesomeIcon icon={faMinus} color="white" transform={`grow-2x`} />
                    </button>
                    :
                    <button type="button" className="btn btn-success btn-lg" onClick={(e) => changeQtyItemInCart(itemInCart, +qty - +1)} >
                        <FontAwesomeIcon icon={faTrashAlt} color="white" transform={`grow-2x`} />
                    </button>
                }
                <button type="button" className="btn btn-light" disabled>{qty}</button>
                <button type="button" className="btn btn-success btn-lg" onClick={(e) => changeQtyItemInCart(itemInCart, +qty + +1)}> 
                    <FontAwesomeIcon icon={faPlus} color="white" transform={`grow-2x`} />
                </button>
            </small>    

        </div>      
    )
}
export default QtyStepper;