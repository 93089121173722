
import React from "react";
import appInsights from "./Insightsbase";


export const InsightsContext = React.createContext();

export const InsightsProvider = ({ children }) => {
  

  return (
    <InsightsContext.Provider
      value={{
        appInsights
      }}
    >
      {children}
    </InsightsContext.Provider>
  );
};