import React, { useContext, useState, useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSms } from '@fortawesome/free-solid-svg-icons'
import { AlertLine } from './AlertLine';
import { ReceiptHeader, ReceiptSubscriber } from './Receipt';
import { useParams, useLocation } from "react-router-dom";
import { AuthContext } from '../Auth';
import configFile from '../config';
import { Spinner } from './tinyparts/Spinners'
import { BackTo } from './tinyparts/BackTo';
import { showChatWidget } from './tinyparts'
import { auth } from '../base';
import { connectFirestoreEmulator } from 'firebase/firestore';
// import { useLocation } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}


// ***** sample ORDERS
// https://localhost:3000/orders/order/52606C4A-D4CB-4B2A-A53B-5733264C88DA
//http://localhost:3010/api/order/id/4D494E66-8720-4506-B169-F8C0D5FE74CC
// http://localhost:3000/orders/id/pi_3K1xNECehxCgI1R108qnTVKb
export default function OrderDetail() {
    let [orderDetails, setOrderDetails] = useState(null);
    let [appError, setAppError] = useState(null);
    let [loading, setLoading] = useState(false);
    let query = useQuery();
    let params = useParams();
    const { idToken, currentUser, authLoading } = useContext(AuthContext);
    let orderid = params?.orderid || query.get("payment_intent");


    async function getData() {
        if (authLoading) return;
        try {

            let url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/orders`;

            let orderDetailsObject = {
                method: 'POST',
                url,
                headers: idToken && { 'Authorization': 'Bearer ' + idToken },
                data: { id: orderid }
            }
            setLoading(true);
            const { data } = await configFile.Axios(orderDetailsObject);
            if (data) {
                setOrderDetails(data, null);
            } else {
                setAppError('Order Not found')
            }
        } catch (err) {
            const { data, } = err.response;

            setAppError(data || err.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        document.title = `ToGoNow - Order Details`;
    })

    useEffect(() => {
        if (!authLoading) {
            setLoading(true)
            let delayGetOrder = query.get('delayed')
            if(delayGetOrder === 'true'){
                // console.log('🌻🌻🌻')
                var timerId = setTimeout(() => {
                    getData();
                  }, 3500);
                  return ()=>clearTimeout(timerId);
            }else{
                // console.log('🍅🍅🍅')
                getData()
            }

        }
        return () => timerId && clearTimeout(timerId);
    }, [orderid, authLoading])


    if (authLoading || loading) return <div className="spinner vh-100">      <Spinner /></div>


    return (<div className="container">
        {currentUser && <BackTo {...{ lbl: 'Orders', lnk: '/Orders' }} />}
        <AlertLine {...{ appError }} />
        {orderDetails && <Receipt receiptForOrder={orderDetails}  >

        </Receipt>}
    </div>)
};

const Receipt = ({ receiptForOrder, children }) => {
    const { accounting, carts } = receiptForOrder;
    // const {accounting, carts} = fullorder;
    const { locationinfo } = {};
    const standalone = true;
    return (
        <div className="card border-0">
            <div className=" card-body text-center" >
                Question about your order, <a className={'text-success'} onClick={showChatWidget}>Chat with us</a>, or Call/Text <a href="tel:1-650-691-5067">(650) 691-5067</a>{' '}
                <a href="sms:1-650-691-5067"><FontAwesomeIcon icon={faSms} color="green" transform="grow-8 right-4" /></a>
            </div>
            <ReceiptHeader  {...{ accounting, locationinfo, standalone, order: receiptForOrder }} />
            <div className='text-muted text-center'>· details ·</div>
            <ReceiptSubscriber {...{ carts, accounting, order: receiptForOrder }}   />
        </div>
    )
}