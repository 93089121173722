import React, { useState, useEffect, useContext, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import Script from 'react-load-script'
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { zone, subzones } from '../components/Zones';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonSpinner, Spinner } from './tinyparts/Spinners';
import { InsightsContext } from "../Insights";
import { setLocation as __setLocation, getPlaceByID as __getPlaceById } from '../redux/actions/marketplaceActions';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import LocationSelection from './LocationSelection';


function AddressInstructions({ loading, currentUser, locations, setLocation, getPlaceByID }) {

    let history = useHistory();
    return <div className="p text-success font-weight-bold mb-1">
        Enter a new delivery address
        {loading ? <ButtonSpinner /> : currentUser ?
            locations &&
            <div className="d-inline">
                {/* eslint-disable-next-line */}
                <LocationSelection {...{
                    setLocation, getPlaceByID, locations, currentUser,
                    color: 'outline-success'
                }}>
                    <div>or pick existing {' '}</div>
                </LocationSelection>
            </div>
            : // eslint-disable-next-line
            <React.Fragment> or{' '}
                <a style={{ cursor: 'pointer' }}
                    onClick={() => history.push("/signin?x=1")}
                    color="text-success">Sign In
                </a>
            </React.Fragment>}

    </div>;
}



export default function SearchAddress({ loading,
    currentUser,
    locations,
    // getPlaceByID 
    // actFoundAddress, actNotFoundAddress,
    // pickedAddress,
    // stateAddress,
    // actSubmit
}) {
    let history = useHistory();
    const dispatch = useDispatch();
    const { appInsights } = useContext(InsightsContext);
    const setLocation = (location) => dispatch(__setLocation(location))
    const getPlaceByID = (placeId) => dispatch(__getPlaceById(placeId))
    const autoCompleteRef = useRef();


    // const [address, setAddress] = useState(null);
    // const [placeId, setPlaceId] = useState(null);
    // const [latLng, setlatLng] = useState(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [validatingAddress, setValidatingAddress] = useState(false);
    const [error, setError] = useState(false);

    function resetState() {
        setValidatingAddress(false);
        // setAddress(null);
        // setPlaceId(null);
        // setlatLng(null);
        setError(null);
    }

    useEffect(() => {

        if (appInsights) {
            appInsights.trackEvent({ name: 'PageLoaded' }, { value: 'Address' });
        }
    }, [appInsights])


    async function actSubmit(place) {

        try {


            setValidatingAddress(true);
            resetState();
            const { place_id, formatted_address, geometry } = place;
            var __latlng;
            if (place_id) {
                // setAddress(formatted_address);

                // setPlaceId(place_id);
                let lat = await geometry.location.lat();
                let lng = await geometry.location.lng();
                __latlng = { lat, lng }
                // setlatLng(__latlng);
            }

            const google = window.google;
            var deliveryPolygon = new google.maps.Polygon({ paths: zone });
            let location = new google.maps.LatLng(__latlng);

            let containsLocation = google.maps.geometry.poly.containsLocation(__latlng, deliveryPolygon);

            if (!!containsLocation) {
                subzones.some(function (z) {
                    var zPolygon = new google.maps.Polygon({ paths: z.polygon });
                    let subzone = google.maps.geometry.poly.containsLocation(location, zPolygon);

                    if (!!subzone) {
                        // address in zone
                        let location = {
                            address: formatted_address,
                            id: z.id,
                            latlng: __latlng,
                            placeId: place_id
                        }
                        setLocation(location);
                        getPlaceByID(place_id)
                        appInsights.trackEvent({ name: 'AddressFound' }, { address: formatted_address });
                        history.push({ pathname: `/menu` });
                        setValidatingAddress(false);
                        return true;
                    }
                    return false;
                });
            } else {

                appInsights.trackEvent({ name: 'AddressNotFound' }, { address: formatted_address });
                setError("We currently don't deliver to the address you entered. Follow along as we expand our delivery.");
                setValidatingAddress(false);
            }
        } catch (e) {
            setError(e.message)
        }
        setValidatingAddress(false);
    }


    function handleScriptCreate() {
        setScriptLoaded(false);
        setError(null);
    }

    function handleScriptError(e) {
        setScriptLoaded(false);
        setError('Error loading. Make sure you are connected to the internet and reload this page.')
    }

    function handleScriptLoad() {
        setScriptLoaded(true);
        setError(null);
    }


    var sectionStyle = {
        // width: '100%',
        borderRadius: '0px 10px 10px 0px',
        // border: '1px solid light gray',
        borderLeft: '0px',
        backgroundImage: "url('https://i.stack.imgur.com/BwgiB.jpg?s=32&g=1') no-repeat scroll 7px 7px",
        padding: '0.5rem 1rem',
        fontSize: '1.25rem',
        lineHeight: '1.5',
        margin: '0rem'
    };

    return (<React.Fragment>
        <div className="111 container-sm">
            <div className=" ">

                <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}&libraries=places,geometry`}
                    onCreate={handleScriptCreate}
                    onError={handleScriptError}
                    onLoad={handleScriptLoad}
                />

                {/* <FontAwesomeIcon icon={faLocationArrow} color={"green"} className={'mr col col-1 fa-2x'} /> */}
                {scriptLoaded ? <>
                    <AddressInstructions {...{ loading, currentUser, locations, setLocation, getPlaceByID }} className='A' />

                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faLocationArrow} color={"green"} className={'B fa-2x'} /></span>
                        {/* <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" /> */}
                        <Autocomplete
                            className={'C form-control'}
                            apiKey={process.env.REACT_APP_MAPS_KEY}
                            style={{ ...sectionStyle }}
                            onPlaceSelected={actSubmit}
                            options={{
                                types: ['address'],
                                componentRestrictions: { country: "us" }
                            }}
                            ref={autoCompleteRef}
                            placeholder={'Enter a delivery location'}
                        />
                    </div>








                </>
                    : <Spinner />
                }
            </div>
        </div>

        {error && <div className='text-danger'>{error}</div>}
    </React.Fragment>)
}