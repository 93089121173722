import React from 'react';    // eslint-disable-line no-unused-vars
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import useModalState from '../tinyparts/ModalState';
import { useStripe } from '@stripe/react-stripe-js';
import { CCIcon } from '../tinyparts';

export default function ({ source, darkgreen = "#01791f", isDefault = false, deleteCardSubmit }) {
    const card = source.card || card;
    const { isOpen, toggle } = useModalState();


    return (
        <div style={{ minWidth: '300px', backgroundColor: "rgb(163 163 163 / 10%)" }} className="list-group-item ">

            <div className="row align-items-center">
                <div className="col-4"> <CCIcon brand={card.brand} /></div>
                <div className="col-6 ">
                    <p className={'text-muted my-0'} >ending {card.last4}</p>
                    <p className='my-0' >Expires: {card.exp_month}/{card.exp_year}</p>
                </div>
                <div className="col-2 ">
                    {isDefault && (
                        <small className={'text-success'}>
                            <FontAwesomeIcon icon={faCheck} color={darkgreen} /> Default </small>)}
                    {deleteCardSubmit && <FontAwesomeIcon style={{ cursor: 'pointer' }}
                        className={"card-link  text-danger float-right"}
                        icon={faTimes} onClick={toggle} color="danger" />}
                </div>
            </div>


            {/****************************  MODAL Confirm DELETE  *****************************************/}
            <Modal isOpen={isOpen} toggle={toggle}  >
                <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
                <ModalBody>
                    <div className="d-flex w-100 justify-content-between ms-1 mb-2">
                        <h5 className="mb-1">
                            <CCIcon brand={card.brand} height='150' />
                        </h5>
                        <i className={'text-muted'} >ending {card.last4}</i>
                        <p className="mb-1">Expires: {card.exp_month}/{card.exp_year}</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-lg btn-danger  btn-block'
                        style={{ cursor: 'pointer' }}
                        onClick={e => { toggle(); deleteCardSubmit(source.id) }}>Delete Payment</button>
                    <button
                        className='btn btn-lg btn-success  btn-block'
                        style={{ cursor: 'pointer' }}
                        onClick={toggle} >Cancel</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}