import React from 'react';
export default class TimedPopOver extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      timer: null,
      counter: 0,
      toggle: null
    };
    this.tick =this.tick.bind(this);
  }

  componentDidUpdate(previousProps, previousState){
      if(previousProps.children.props.isOpen ===  false && this.props.children.props.isOpen === true){
        let timer = setInterval(this.tick, 1000);
        this.setState({timer});
      }
      if(previousProps.children.props.isOpen ===  true && this.props.children.props.isOpen === false){
        clearInterval(this.state.timer);
        this.setState({
          counter: 0,
          timer: null
        });
      }

    }
  tick() {
    this.setState(previousState =>{ 
      let counter = previousState.counter + 1;
      return {counter};
    });
    if(this.state.counter >= 3){
      clearInterval(this.state.timer);
      this.setState({
        "counter": 0,
        timer: null
      });
      this.props.toggle()
    }
  }
  render() {
    // console.log("rendered ");
    return (
      <React.Fragment>
          {this.props.children}
      </React.Fragment>
    );
  }
};