import React, { useEffect, useContext, useState } from 'react';
import { InsightsContext } from "../Insights";
import { useParams } from "react-router-dom";
import { get } from 'lodash'
import MenuItemsSubscriber from './MenuItemsSubscriber'
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSubscriber
} from '../redux/actions/marketplaceActions';
import { Spinner } from './tinyparts/Spinners';

export default ({
    addItem, addedItemTobag, selectItem, clearSelectedSubscriber, appBoundary
}) => {
    const { appInsights } = useContext(InsightsContext);

    const { subscriberid } = useParams();

    const { marketplaceclosed,
        subscriberinfo,
        subscribermenu,
        selectedSubscriber } = useSelector(({ marketplaceReducer }) => ({
            subscriberinfo: get(marketplaceReducer, `availableSubscribers[${subscriberid}]`),
            selectedSubscriber: get(marketplaceReducer, `selectedSubscriber`),

        }));

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedSubscriber !== subscriberid) {
            dispatch(selectSubscriber(subscriberid))
            appInsights.trackEvent({ name: 'RestaurantSelected' }, { id: subscriberid, Restaurant: get(subscriberinfo, 'name') });
        }
        setLoading(false)

    }, [selectedSubscriber, subscriberid])

    if(loading){
        return             <div className="spinner vh-100">     
        <Spinner />
        </div>  
    }        

        return <>
            <MenuItemsSubscriber {...{
                subscriberid,
                addItem, addedItemTobag, clearSelectedSubscriber, selectItem,
                appBoundary
            }} />
        </>
};
