import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useHistory } from "react-router-dom";
// import { ClipLoader } from 'react-spinners';
import { ButtonSpinner, Spinner } from './tinyparts/Spinners'
import { AuthContext } from "../Auth.js";
import { useSelector, useDispatch } from 'react-redux'
// map Selectors
import { cartItemCount as _cartItemCount } from '../redux/selectors'
import Card from './CheckoutParts/Card.js';
import useModalState from './tinyparts/ModalState';
import configFile from '../config';
import { getStripeProfile as __getStripeProfile, setLocation } from '../redux/actions/marketplaceActions.js';


import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
import { PaymentForm } from './CheckoutParts';
import { getType } from '../togonowutils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);



function Payment() {

    let history = useHistory();
    const { stripeProfile } = useSelector(({ marketplaceReducer }) => ({
        stripeProfile: marketplaceReducer.stripeProfile,
    }));

    const { cartItemCount } = useSelector((state) => ({
        cartItemCount: _cartItemCount(state),
    }));

    const dispatch = useDispatch();
    const getStripeProfile = (usertoken) => dispatch(__getStripeProfile(usertoken))

    const { isOpen, toggle } = useModalState();
    const { currentProfile, loading, idToken } = useContext(AuthContext);
    const [cardError, setCardError] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);

    const somethingLoading = useMemo(() => {
        return pageLoading === true || stripeProfile === 'loading' || loading === true

    }, [pageLoading, stripeProfile, loading])



    useEffect(() => {
        document.title = `ToGoNow - Payment Method`;
    })

    useEffect(() => {
        if (currentProfile) {
            idToken && getStripeProfile(idToken);
        }
    }, [currentProfile]);

    async function addCardSubmit(e) {
        try {
            e.preventDefault();
        } catch (e) {
            setCardError(e.message);
        }
    }


    async function deleteCardSubmit(cardid) {
        setPageLoading(true);
        try {
            const url = `${process.env.REACT_APP_API_DOMAIN}/api/profile/deletecard`;

            let res = await configFile.Axios({
                method: 'POST',
                url,
                headers: {
                    'Authorization': 'Bearer ' + idToken
                },
                data: { cardid }
            })
            idToken && getStripeProfile(idToken);

        } catch (e) {
            console.error("TCL: delete Card Submit Error -> e", e) // need to implement
            setCardError(e.message);
        }finally{
            setPageLoading(false);
        }
    }


    function doReset() {
        setCardError(null);
        setPaymentError(null);
    }

    const setupIntent = async () => {
        setPageLoading(true);
        try {
            if (!clientSecret) {
                const url = `${process.env.REACT_APP_API_DOMAIN}/api/payment/setup-intent`;

                let res = await configFile.Axios({
                    method: 'POST',
                    url,
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    },
                    data: { profile: { stripe_id: currentProfile.stripe_id } }
                });
                setClientSecret(res.data.clientSecret)
                console.log(res.data.clientSecret)
            }

            toggle()
        } catch (e) {
            console.error(e.message);
        } finally {
            setPageLoading(false);
        }
    };



    


    return (
        <div id={'paymentdiv'} className="container mt-2">
            {!!somethingLoading && <div className="spinner vh-100">
                <Spinner />
            </div>}
            {/*****************************  ADD PAYMENT *******************/}

            <Modal isOpen={isOpen} toggle={toggle}  >
                <ModalHeader toggle={toggle}>Add New Payment</ModalHeader>
                <>
                    <ModalBody>
                        {cardError && <div className="alert alert-danger" role="alert">{cardError}</div>}
                        {clientSecret && <Elements stripe={stripePromise} options={{ clientSecret }}>
                            <PaymentForm mode={'save'} />
                        </Elements>}
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </>
            </Modal>

            <div className="container-sm">
                <div className="row justify-content-center" >
                    {getType(stripeProfile) === 'object' && stripeProfile.sources.map((source, index) => (
                        <div key={index} className="col col-4"><Card {...{ source, deleteCardSubmit }} height={50} /></div>
                    ))}




                    <div className="mt-2 d-grid gap-2 d-md-flex justify-content-md-center">
                        <button
                            style={{ cursor: 'pointer' }}
                            disabled={somethingLoading === true}
                            onClick={setupIntent}
                            className="btn btn-success me-md-2" type="button">
                            {pageLoading && <><ButtonSpinner size={'30px'} />{' '}</>}
                            Add Payment
                        </button>

                        {cartItemCount > 0 && <button
                            style={{ cursor: 'pointer' }}
                            onClick={e => history.push('/checkout')}
                            className="btn btn-success" type="button">
                            Checkout
                        </button>}
                    </div>



                </div>
            </div>

        </div>
    );
}

// const injector = () => {

//     useEffect(() => { }, [])
//     return <Payment />

// }


export default Payment;
