import React from 'react';    // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AuthContext } from '../Auth';
import { has, get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLocationArrow,
    faThumbsUp, faBan, faIceCream, faHandHoldingUsd,
    faClock, faDollarSign, faNetworkWired
} from '@fortawesome/free-solid-svg-icons'
import './Address.css';
import SearchAddress from '../components/SearchAddress';
import LocationSelection from '../components/LocationSelection';
import { zone, subzones } from '../components/Zones';
import {
    setLocation, getPlaceByID
} from '../redux/actions/marketplaceActions';
import * as Sentry from '@sentry/browser';
import { SendSentryException } from '../togonowutils';
import { ButtonSpinner } from '../components/tinyparts/Spinners';
import { useHistory } from "react-router-dom";

class A extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            address: null,
            redirectAfterAddress: null
        }
        this.actFoundAddress = this.actFoundAddress.bind(this);
        this.actNotFoundAddress = this.actNotFoundAddress.bind(this);
        this.actSubmit = this.actSubmit.bind(this);
    }
    static contextType = AuthContext;

    componentDidMount() {
        let strTitle = 'ToGoNow Marketplace - Skip the delivery fee';
        document.title = strTitle;
    }


    actNotFoundAddress(x, y) {
        let strError = 'We couldn’t find that address.';
        this.setState({ error: strError, address: null });
        SendSentryException(Sentry, strError, 'stateAddress', this.state.address)
    }

    actFoundAddress(address) {
        this.setState({ error: null, address });
    }

    async actSubmit() {
        const google = window.google;
        const { AddAddress } = this.context;
        var deliveryPolygon = new google.maps.Polygon({ paths: zone });
        let c = new google.maps.LatLng(this.state.address.latLng);
        if (google.maps.geometry.poly.containsLocation(c, deliveryPolygon)) {
            let location = this.state.address;
            subzones.forEach(function (z) {
                var zPolygon = new google.maps.Polygon({ paths: z.polygon });
                if (google.maps.geometry.poly.containsLocation(c, zPolygon)) {
                    location.id = z.id
                }
            });
            if (!has(location, 'id')) {
                SendSentryException(Sentry, `${this.state.address} address found in zone but not found in any subzones!`, 'location', location)
            }
            this.props.setLocation(location);
            this.props.getPlaceByID(location.placeId);
            this.props.history.push({ pathname: `/menu` });
            AddAddress(location);
        } else {
            this.props.history.push({ pathname: `/address/notfound${this.state.address && `/${this.state.address.latLng.lat}/${this.state.address.latLng.lng}`}` });
        }
    }






    render() {
        const { actFoundAddress, actNotFoundAddress, actSubmit } = this;
        const { error, address } = this.state;
        const { setLocation, getPlaceByID } = this.props
        const pickedAddress = address !== null;
        const stateAddress = address;
        const { currentUser, loading, currentProfile } = this.context;
        const locations = get(currentProfile, 'locations', null);



        //
        return (
            <React.Fragment>
                <div className="page1">







                    <div className="px-4 py-5 my-5 text-center centerdiv">
                        <h1 className='mb-2 display-5 text-success'>Skip the delivery fee</h1>
                        <div className=''>Lunch and dinner delivery</div>
                        <div className='mb-2'>for Santa Clara and Mountain View in California</div>
                        <div className="col-lg-6 mx-auto">

                            {/* <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <button type="button" class="btn btn-primary btn-lg px-4 gap-3">Primary button</button>
                                <button type="button" class="btn btn-outline-secondary btn-lg px-4">Secondary</button>
                            </div> */}
                        </div>
                        <div className=" mx-auto">

                            <SearchAddress {...{
                                loading,
                                currentUser,
                                locations,
                            }} />


                        </div>
                    </div>




                </div>
                <div className='page2'>
                    <div className='pageWrapper'>
                        <div className="row  justify-content-center align-items-center pt-4">
                            <h2 className="text-center text-success font-weight-bold ">Why ToGoNow?</h2>
                        </div>
                        <div className="box">
                            <div className="container">
                                <div className="row">
                                    <InfoBox2 {...{ 'description': 'We combine orders so you don’t pay delivery fees.', 'title': 'ZERO Delivery Fee', 'icon': faDollarSign }} />
                                    <InfoBox2 {...{ 'description': 'Efficient delivery routes means on time delivery.', 'title': 'On Time Delivery', 'icon': faClock }} />
                                    <InfoBox2 {...{ 'description': 'Want Sushi and a Smoothie? Multiple restaurants -> one delivery.', 'title': 'Restaurant Bundling', 'icon': faNetworkWired }} />
                                    <InfoBox2 {...{ 'description': 'Crave for a donut? Delivery is still free!', 'title': 'No Minimum Order', 'icon': faIceCream }} />
                                    <InfoBox2 {...{ 'description': 'All orders treated equal. We don’t work on tips.', 'title': 'No Tipping', 'icon': faHandHoldingUsd, 'icon2': faBan }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="page-footer font-small bg-success">
                    <div className="footer-copyright text-center py-3 text-white">
                        © 2022 copyright {' '}
                        <a className='badge badge-light' href="https://www.togonow.com/">
                            ToGoNow.com
                        </a>
                    </div>
                </footer>
            </React.Fragment>

        )

    }


}

function AddressInstructions({ loading, currentUser, locations, setLocation, getPlaceByID }) {

    let history = useHistory();
    return <div className="p text-success font-weight-bold mb-1">
        Enter a new delivery address
        {loading ? <ButtonSpinner /> : currentUser ?
            locations &&
            <div className="d-inline">
                {/* eslint-disable-next-line */}
                <LocationSelection {...{
                    setLocation, getPlaceByID, locations, currentUser,
                    color: 'outline-success'
                }}>
                    <div>or pick existing {' '}</div>
                </LocationSelection>
            </div>
            : // eslint-disable-next-line
            <React.Fragment> or{' '}
                <a style={{ cursor: 'pointer' }}
                    onClick={() => history.push("/signin?x=1")}
                    color="text-success">Sign In
                </a>
            </React.Fragment>}

    </div>;
}


function mapStateToProps(state, prop) {
    return {

    }
}
function mapDispatchToProps(dispatch) {
    return {
        setLocation: customerlocation => {
            dispatch(setLocation(customerlocation))
        },
        getPlaceByID: (placeId) => {
            dispatch(getPlaceByID(placeId));
        }
    }
}



const Address = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(A)
)

export default Address;


const InfoBox2 = ({ icon = faThumbsUp, icon2, title, description }) => (
    // taken from bootstrap snippet card with icon
    <div className="col-lg-4 col-sm-6 col-xs-12">
        <div className="box text-center">
            <div className="icon">
                <div className="image">
                    {(icon2 ?
                        <span className="fa-layers fa-3x">
                            <FontAwesomeIcon icon={icon} color="green" transform="shrink-4 down-1" />
                            <FontAwesomeIcon icon={icon2} color="lightgray" transform="grow-5  down-2" />
                        </span>
                        :
                        <FontAwesomeIcon icon={icon} color="green" size="3x" transform="down-2" />
                    )}
                </div>
                <div className="info">
                    <div className="title">
                        <h3>{title}</h3>
                    </div>

                    <div className="px-2 text">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    </div>);
