import React from 'react';
import { useHistory } from "react-router-dom";
import { SCart } from './CheckoutParts';
import { Dock } from 'react-dock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { changeQtyItemInCart as __changeQtyItemInCart } from '../redux/actions/marketplaceActions';
import { cartsAccounting as _cartsAccounting, subscriberCartsArray as _subscriberCartsArray } from '../redux/selectors';

export default function ({ appBoundary }) {

    let history = useHistory();
    // map actions
    const dispatch = useDispatch();
    const changeQtyItemInCart = (itemInCart, qty) => dispatch(__changeQtyItemInCart(itemInCart, qty))
    const { toogleCartDock, cartDockVisible, sizeOfDock } = appBoundary;

    const { cartsAccounting, subscriberCartsArray, availableSubscribers } = useSelector(({ marketplaceReducer }) => ({
        cartsAccounting: _cartsAccounting({ marketplaceReducer }),
        subscriberCartsArray: _subscriberCartsArray({ marketplaceReducer }),
        availableSubscribers: marketplaceReducer.availableSubscribers,
    }));

    return <Dock size={sizeOfDock} position='right' isVisible={cartDockVisible} onVisibleChange={toogleCartDock}>
        {
            subscriberCartsArray === undefined || subscriberCartsArray.length === 0 ?
                <div className="row h-100">
                    <div className="col-sm-12 align-self-center">
                        <div className="w-25  mx-auto">
                            <svg className='d-block' x="0px" y="0px" width="100" height="100" viewBox="0 0 446.853 446.853"            >
                                <g>
                                    <path d="M444.274,93.36c-2.558-3.666-6.674-5.932-11.145-6.123L155.942,75.289c-7.953-0.348-14.599,5.792-14.939,13.708   c-0.338,7.913,5.792,14.599,13.707,14.939l258.421,11.14L362.32,273.61H136.205L95.354,51.179   c-0.898-4.875-4.245-8.942-8.861-10.753L19.586,14.141c-7.374-2.887-15.695,0.735-18.591,8.1c-2.891,7.369,0.73,15.695,8.1,18.591   l59.491,23.371l41.572,226.335c1.253,6.804,7.183,11.746,14.104,11.746h6.896l-15.747,43.74c-1.318,3.664-0.775,7.733,1.468,10.916   c2.24,3.184,5.883,5.078,9.772,5.078h11.045c-6.844,7.617-11.045,17.646-11.045,28.675c0,23.718,19.299,43.012,43.012,43.012   s43.012-19.294,43.012-43.012c0-11.028-4.201-21.058-11.044-28.675h93.777c-6.847,7.617-11.047,17.646-11.047,28.675   c0,23.718,19.294,43.012,43.012,43.012c23.719,0,43.012-19.294,43.012-43.012c0-11.028-4.2-21.058-11.042-28.675h13.432   c6.6,0,11.948-5.349,11.948-11.947c0-6.6-5.349-11.948-11.948-11.948H143.651l12.902-35.843h216.221   c6.235,0,11.752-4.028,13.651-9.96l59.739-186.387C447.536,101.679,446.832,97.028,444.274,93.36z M169.664,409.814   c-10.543,0-19.117-8.573-19.117-19.116s8.574-19.117,19.117-19.117s19.116,8.574,19.116,19.117S180.207,409.814,169.664,409.814z    M327.373,409.814c-10.543,0-19.116-8.573-19.116-19.116s8.573-19.117,19.116-19.117s19.116,8.574,19.116,19.117   S337.916,409.814,327.373,409.814z" fill="#91DC5A" />
                                </g>
                            </svg>
                        </div>
                        <div className='mx-auto text-center'>
                            <p className='d-block text-muted'>Your cart is empty. Add items to checkout.</p>
                        </div>
                    </div>
                </div>
                :
                <React.Fragment>
                    {/* <div className="p-1"> */}
                    <div className="container pt-4">
                        <div className='d-flex'>
                            <button type="button" className="btn btn-success btn-lg btn-block" onClick={e => { history.push('/checkout'); toogleCartDock() }} >
                                <div className="d-flex justify-content-between">
                                        Checkout{'  '}${cartsAccounting.accounting.subtotal}
                                </div>
                            </button>
                            <button type="button" className="ms-auto btn btn-outline mb-2 " onClick={toogleCartDock} >
                                <FontAwesomeIcon icon={faTimes} color={"green"} transform={'grow-4x'} />
                            </button>
                        </div>


                    
                    {
                        subscriberCartsArray.map((scart) => {
                            let locationSubscriberInfo = availableSubscribers[scart.sid];
                            return <SCart className='grayout' key={scart.sid} {...{ scart, changeQtyItemInCart, subscriberInfo: locationSubscriberInfo }} />
                        })
                    }
                </div>
    {/* </div> */}
    </React.Fragment >
}
  </Dock >    
}