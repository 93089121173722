import {map, get } from 'lodash';
import { SubscriberCart} from '../utils/SubscriberCart';
import defaultState from './state';
const trimCarts = (__persistedState)=>{
    let stateSCarts = get(__persistedState, 'marketplaceReducer.carts', undefined)
    if(stateSCarts){
        map(stateSCarts, function (sCart, sid) {
            stateSCarts[sid] = new SubscriberCart(sCart);
        });

    }else{
        __persistedState.marketplaceReducer.carts = {}
    }

}

export const loadState = ()=>{
    try{
        let __defaultState = defaultState();
        let __defaultState_version = __defaultState.version;
        const serializedState = localStorage.getItem('state');
        if(serializedState === null || serializedState === undefined ){
            return  __defaultState;
        };
        let __persistedState = JSON.parse(serializedState);
        // all the conditions for persisted state has to be met before we proceed

        // if(!get(__persistedState, 'marketplacereducer.carts', false)  ){
        //     return __defaultState;
        // }    

        let __persisted_version = get(__persistedState,'marketplaceReducer.version',0)


        // Handle version difference
        if(__persistedState && __persisted_version !== __defaultState_version){
            // version has been changed need to load the state template from './state';
            return __defaultState;
        }


        trimCarts(__persistedState);



        //***  */need a way to make sure the state follow a certain template
        // makesure currentOrders is defined
        let updateLocalStorage = false;
        if(__persistedState.marketplaceReducer.currentOrders){
            // added 3/7/2019
            delete __persistedState.marketplaceReducer.currentOrders;
            updateLocalStorage = true
        }

        if(updateLocalStorage){
            const serializedState = JSON.stringify(__persistedState);
            localStorage.setItem('state', serializedState);
        }
        return __persistedState.marketplaceReducer;
    }catch(err){
        console.log(err);
        return undefined;
    }
}


export const saveState = (state) =>{
    try{
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch(err){
        console.log('saveStateError:',err);
    }
}