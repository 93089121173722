import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import {
    selectItem, selectSubscriber,
    clearSelectedSubscriber, addItem, changeQty, changeQtyItemInCart,
    selectOV,
} from '../redux/actions/marketplaceActions';
import {
    menuSections, marketplaceSubscriberIds,
    cartItemCount,
    subscriberCartsArray,
    cartsAccounting,
    deliverytimes
} from '../redux/selectors';


import moment from 'moment';
import '../components/Menu.css';
import { Spinner } from '../components/tinyparts/Spinners'
import MenuItemsMarketplace from '../components/MenuItemsMarketplace'
import MenuHeader from '../components/MenuHeader';




moment.locale();

const __lgScreenDock = 0.4;
const __smScreenDock = 0.9;

class Menu extends Component {
    constructor(props) {
        super(props);
        this.mql = window.matchMedia(`(min-width: 800px)`);
        this.goCheckout = this.goCheckout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.state = {
            cartDlockVisible: false,
            addedToCartPopOver: false,
            accountDropDownVisible: false,
            sizeOfDock: (this.mql.matches ? __lgScreenDock : __smScreenDock),
            smallScreen: !this.mql.matches,
        };

        this.notificationSystem = React.createRef();
        this.addNotification = event => {
            if (event && event.preventDefault) event.preventDefault();
            let { lastItemAddedToCart, availableSubscribers } = this.props;
            let title = (lastItemAddedToCart && availableSubscribers && availableSubscribers[lastItemAddedToCart.subscriberid] ? availableSubscribers[lastItemAddedToCart.subscriberid].name + ' added' : '- - -');
            let message = (lastItemAddedToCart ? lastItemAddedToCart.qty + ' x ' + lastItemAddedToCart.item_Title + ' ' + lastItemAddedToCart.linetotal : 'nothing has been added');
            const notification = this.notificationSystem.current;
            notification.addNotification({
                message, title,
                level: 'success',
                position: 'tc',
                action: {
                    label: 'Checkout >>',
                    callback: () => {
                        this.goCheckout();
                    }
                }
            });
        };
        this.addNotification = this.addNotification.bind(this);
    }

    componentWillMount() {
        this.mql.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        this.mql.removeListener(this.mediaQueryChanged);
    }

    mediaQueryChanged() {
        this.setState({
            sizeOfDock: (this.mql.matches ? __lgScreenDock : __smScreenDock),
            smallScreen: !this.mql.matches
        })
    }

    toggle() {
        setTimeout(this.addNotification, 750);
    }
    goCheckout() {
        this.props.history.push('/checkout');
    }

    render() {
        const {
            // Passed actions
            // eslint-disable-next-line no-unused-vars
            selectItem, selectSubscriber,
            clearSelectedSubscriber, addItem,
            // Passed props
            customerlocation, availableSubscribers,
            marketplaceclosed,
            loading, sinfo_loading, currentSubscriberMenu_loading, marketplacemenu, selectedItem,
        } = this.props;

        if (!customerlocation) return <Redirect to='/address' />
        const { smallScreen } = this.state;

        const showSpinner = loading === 'loading' || sinfo_loading === 'loading' || currentSubscriberMenu_loading === 'loading';

        return (
            <>
                <MenuHeader />
                <MenuItemsMarketplace {...{ marketplacemenu, availableSubscribers, addItem, addedItemTobag: this.toggle, marketplaceclosed, selectItem, clearSelectedSubscriber, selectSubscriber }} />
                {showSpinner &&
                    <div className="spinner vh-100">
                        <Spinner />
                    </div>}
            </>
        );
    }
}














function mapStateToProps(state, prop) {
    const { loading, sinfo_loading, currentSubscriberMenu_loading, selectedSubscriber,
        marketplacemenu, selectedItem, carts, lastItemAddedToCart,
        customerlocation, availableSubscribers, appError,
        marketplaceclosed, marketplacemessage, marketplaceinfo,
        menuversion } = state.marketplaceReducer;
    return {
        loading, sinfo_loading, currentSubscriberMenu_loading, selectedSubscriber, marketplacemenu, selectedItem,
        marketplaceclosed, marketplacemessage, marketplaceinfo,  //: state.marketplaceReducer.marketplaceinfo,
        menuversion,
        carts,  //: state.marketplaceReducer.carts,
        lastItemAddedToCart, //: state.marketplaceReducer.lastItemAddedToCart,
        customerlocation, //: state.marketplaceReducer.customerlocation,
        availableSubscribers, //: state.marketplaceReducer.availableSubscribers,
        appError, //: state.marketplaceReducer.appError,
        //*** SELECTORS */
        menuSections: menuSections(state),
        marketplaceSubscriberIds: marketplaceSubscriberIds(state),
        deliverytimes: deliverytimes(state),
        cartItemCount: cartItemCount(state),
        cartsAccounting: cartsAccounting(state),
        subscriberCartsArray: subscriberCartsArray(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectItem: selectedItem => {
            dispatch(selectItem(selectedItem));
        },
        selectSubscriber: subscriberid => {
            dispatch(selectSubscriber(subscriberid));
        },
        clearSelectedSubscriber: () => {
            dispatch(clearSelectedSubscriber());
        },

        addItem: (selectedItem, qty) => {
            dispatch(addItem(selectedItem, qty));
        },
        changeQty: (item, qty) => {
            dispatch(changeQty(item, qty));
        },
        changeQtyItemInCart: (itemInCart, qty) => {
            dispatch(changeQtyItemInCart(itemInCart, qty));
        },

        selectOV: OVid => {
            dispatch(selectOV(OVid));
        }
    }
}


const MenuContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu)
)

export default MenuContainer;