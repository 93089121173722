import Axios from 'axios';

let configFile= {};

Axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN;
Axios.defaults.headers.common = {'Cache-Control' : 'no-cache'};
Axios.defaults.headers.post = {};
Axios.defaults.headers.put = {};
Axios.defaults.headers.patch = {};
configFile.Axios = Axios;

// const stripeKey =  process.env.REACT_APP_STRIPE_PUBLIC_KEY
// configFile.stripe = window.Stripe(stripeKey);


export default configFile;