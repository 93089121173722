import React from 'react';
import PropTypes from 'prop-types';
import { CCIcon } from '../tinyparts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareDown } from '@fortawesome/free-regular-svg-icons';

const SelectedCard = (props) => {
    const { selectedOrDefaultCard, toggle } = props;
    return (
        <React.Fragment>
            {/* CARD SELECTED SNIPPET */}
            <button className="btn btn-light btn-lg btn-block" onClick={e => toggle()}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        {selectedOrDefaultCard ?
                            (<div className="col-sm align-top me-2">
                                <CCIcon brand={selectedOrDefaultCard.card.brand} {...{ height: '64', width: '74' }} />
                                <span className="ms-2 text-muted"> ....{selectedOrDefaultCard.card.last4} - Expiry {selectedOrDefaultCard.card.exp_month}/{selectedOrDefaultCard.card.exp_year}</span>
                            </div>)
                            :
                            (<div className="col-sm align-top me-2">
                                <span className="me-4">Select payment</span>
                            </div>)
                        }
                        <FontAwesomeIcon className=" col-2" icon={faCaretSquareDown} color={"gray"} />


                    </div>
                </div>
            </button>
        </React.Fragment>
    );
}


SelectedCard.propTypes = {
    toggle: PropTypes.func.isRequired,
    selectedOrDefaultCard: PropTypes.object
}
export default SelectedCard;