import React, { useState, useEffect } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { getAuth, PhoneAuthProvider, EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { useHistory, withRouter } from "react-router-dom";
import { Spinner } from './tinyparts/Spinners'
import { BackTo } from './tinyparts/BackTo';
import { auth } from '../base';
import './firebaseui-styling.global.css'; // Import globally.

const uiConfig = {
    signInSuccessUrl: '/',
    signInOptions: [
        EmailAuthProvider.PROVIDER_ID,
        PhoneAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        GoogleAuthProvider.PROVIDER_ID,
        FacebookAuthProvider.PROVIDER_ID,
    ],
    credentialHelper: 'none',
    privacyPolicyUrl: `${process.env.REACT_APP_TERMLY}`,
    callbacks: {
        signInSuccessWithAuthResult: () => `😀💮🌻  Test Sign Up with signInSuccessWithAuthResult snippet` && false
    }
};





const SignIn = () => {
    let history = useHistory();
    let [loading, setLoading] = useState(true);

    function uiCallback(ui) {
        setLoading(false);
    }

    useEffect(() => {
        document.title = `ToGoNow SignIn`;
        // *** KEEP The below commented for ideas until December 2022 ***
        // const a = getAuth().onAuthStateChanged((x, y) => {
        //     console.log('***onAuthStateChanged***')
        // })
        // return () => a();
    })

    return <div className="container ">
        <div className="row justify-content-center">
            <div className="col-md d-fl">
                <BackTo {...{ history }} />

            </div>
        </div>
        <div className="row justify-content-center text-center">
                <h2 className="card-header mb-2">Sign In / Sign Up</h2>
        </div>
        <div className="row justify-content-center">
                <StyledFirebaseAuth  {...{ uiCallback, uiConfig, firebaseAuth: getAuth() }} />
        </div>
    </div>

}



export default withRouter(SignIn);