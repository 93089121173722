// src/Checkout.js
import React from 'react';

import CardSection from './CardSection';

const Checkout = () => {
  // Inject a reference to elements with the useElements hook.

  return (
    <form >
      <div>DEPRECATED</div>
      <CardSection />
      <button type="submit">Pay</button>
    </form>
  );
};

export default Checkout;