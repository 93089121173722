import decode from 'jwt-decode';
import {throwIfMissing} from '../../togonowutils';

export function getTokenExpirationDate(token = throwIfMissing()) {
    const decoded = decode(token)
    if (!decoded.exp) {
        return null
    }
    const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp)
    return date
}

export function isTokenExpired(token = throwIfMissing()) {
    const date = getTokenExpirationDate(token)
    if (date === null) {
        return false
    }
    return !(date.valueOf() > new Date().valueOf())
}

export function isAuthenticated(token) {
    if(!token) return false;
    return !isTokenExpired(token);
}
