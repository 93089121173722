import configFile from '../../config';  // eslint-disable-line no-unused-vars
let defaultState = ()=> ( {
version: 3.0,
    //**** Checkout Related */
    checkout: {
        name: null,
        email: null,
        phone: null,
        couponResponse: null,
        deliveryvalue: null,
        deliveryinputrequired: false,    
    },
    checkout_validating: null,

    //**** ALL Subscribers info 
    sinfo_loading: null,    // null | loading | success | error 
    sinfo_error: null,

    //**** current subscriber menu
    currentSubscriberMenu_loading: null,    // null | loading | success | error 
    currentSubscriberMenu_error: null,

    //**** marketplace menu ***********/
    // menu: null, 
    marketplacemenu: null, 
    loading: null,          // null | loading | success | error
    error: null,            // null | loading | string
    marketplaceinfo: {
        salestax: 0.09,
        nextDeliveryToday: null,
        nextOrderbyToday: null,
        allExpiredForToday: null,
        someExpiredForToday: null,
        upcomingDeliveries: null
    }, 
    menuversion: null,      // e.g. 'Tue, 19 Jun 2018 22:06:25 GMT'
    marketplaceclosed: false,
    marketplacemessage: null,

    //**** customerlocation related *********
    customerlocation: null,
    //**** availableSubscribers related *********
    /*
    // availableSubscribers: {
        "C5A2ED07-CDFB-467A-AD6C-31E47D0F5526": {
          "subscriberid": "C5A2ED07-CDFB-467A-AD6C-31E47D0F5526",
          "name": "Roger's Deli",
          "address": "295 East Middlefield Road",
          "city": "Mountain View",
          "state": "CA",
          "zipcode": "94043",
          "menuversion": 47,
          "latestordertime": "12:00 pm",
          "deliverytime": "1:15 pm",
          "latitude": "37.397042",
          "longitude": "-122.060160"
        },
        "BA16FA78-A6CE-4B2B-AD42-F95BA0302239": {...
      }
    */
    availableSubscribers: null,

    //*** Item related **************     
    selectedItem: null,
    selectedSubscriber: null,


    //*** CART */
    /* sample: 
    carts: 
        // sCart
        {
            // sid
            "BA16FA78-A6CE-4B2B-AD42-F95BA0302239" : {
                items: [
                    {
                        item: selectedItem1,
                        qty: 1,
                        price: x.xx,
                        linetotal: xx.xx
                    },
                    {
                        item: selectedItem2,
                        qty: 1,
                        price: x.xx,                        
                        linetotal: xx.xx
                    }
                ],
                subtotal: xx.xx
        }
    },
    */
    carts: {},
    lastItemAddedToCart: null, // ItemInCart type

    currentOrderId: null, // if not signed in. temp location to save the id's of orders placed without signed in.
    orderDetails: null, // used to show the details of the selected order
    
    
    // Profile Related
    userProfile: null,
    userProfileTrimmed: null,
    orders: null,
    isFetchingProfileOrders: null,
    profileOrdersError: null,     
    
    //STRIPE RELATED
    stripeProfile: null,
    isFetchingStripeProfile: null,
    isFetchingAddCard: null,
    isFetchingDeleteCard: null,
    stripeError: null,
    selectedCard: null,
    paymentMode: 'PaymentElement', // {"PaymentElement" || "SavedPayment"}
   
    // app state
    // requestFullfilled: false,
    //**** logging / error */
    appError: null,
    loggerError: null
});



export default defaultState;