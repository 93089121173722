import React, { useContext, useState, useEffect } from 'react';
import './Orders.css';
import { OrderList } from './Receipt';
import { AlertLine } from './AlertLine';
import { AuthContext } from '../Auth';
import configFile from '../config';
// import { ClipLoader } from 'react-spinners';
import {Spinner} from './tinyparts/Spinners'
import { BackTo } from './tinyparts/BackTo';

import { useHistory } from "react-router-dom";
import {throwIfMissing} from '../togonowutils'


function Orders() {

    let history = useHistory();
    const { idToken, currentUser } = useContext(AuthContext);
    let [orderList, setOrderList] = useState(null);
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState(null);

    const divStyle = { width: 'calc(100% - 120px)' };


    
    useEffect(() => {
        document.title = `ToGoNow Orders`;
    }, [])


    useEffect(() => {


        async function getData() {
        // const getData = async () => {
            try { 
                let url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/orders/list`;
                let orderDetailsObject = {
                    method: 'POST',
                    url,
                    headers: idToken && { 'Authorization': 'Bearer ' + idToken },
                }
                setLoading(true);
                const { data } = await configFile.Axios(orderDetailsObject);
                setOrderList(data);
                setLoading(false)
            } catch (e) {
                console.log("💣💣💣: get order list ->", e);
                setOrderList(null);
                setLoading(false);
                setError(e.message)
            }
        }


        if(idToken){
            getData();
        }

    }, [idToken]);


    if (!currentUser && !loading) {
        return <div>Must login first</div>
    }
    if(loading){
        return <div className="spinner vh-100">      
        <Spinner />
      </div>
    }

    // console.log("TCL: Orders -> orderList", orderList)
    // return <div><input type="textarea">{JSON.stringify(orderList[0])}</input></div>







    function goToOrderDetailAction(order = throwIfMissing()) {
        history.push({ pathname: `/orders/id/${order.id}` });
    }







    if(error) return   <AlertLine appError={error} />



    if (!orderList || orderList.length === 0) {
        return (
            <React.Fragment>
                <AlertLine appError={error} />
                <div style={divStyle} className="container">
                    <div className="row h1 h-75 justify-content-center align-items-center text-muted">No Orders found.</div>
                </div>
                {loading &&
                    <div className="spinner vh-100">
                        <Spinner />
                        {/* <ClipLoader className="spinnerloading" loading={loading} color="#26A65B" size={parseInt(25, 10)} margin="4px" /> */}
                    </div>}

            </React.Fragment>);
    }



    return (
        <React.Fragment>
             <AlertLine appError={error} />
            <div className="container">
                <BackTo {...{ history }} />
                <div className="h2 mb-1 text-center" >Your Orders</div>
                <div className="row"><OrderList {...{ orders: orderList, goToOrderDetailAction }} /></div>
            </div>
            {loading &&
                <div className="spinner vh-100">
                    <Spinner />
                </div>}
        </React.Fragment>
    )

}


export default Orders;