import {get} from 'lodash';

const trimUserProfile = (userProfile= null)=>{
    if(!userProfile) return null;
    let {app_metdata, user_metadata} = userProfile;
    // let app_metdata = get(userProfile, '["https://www.togonow.com/app_metadata"]', null);
    // let user_metadata = get(userProfile, '["https://www.togonow.com/user_metadata"]', null);
    let stripe_customerid = get(app_metdata, 'stripe_customerid', null);
    let name = get(user_metadata, 'given_name', '') + ' ' +  get(user_metadata, 'family_name', '') ;
    let given_name = get(user_metadata, 'given_name',null);
    let family_name = get(user_metadata, 'family_name',null);
    let phone = get(user_metadata, 'phone',null);
    let locations = get(user_metadata, 'locations',[]);
    let email = get(userProfile, 'email', null);
    let sub = get(userProfile, 'sub', null);
    
    let trimmed = {name, given_name, family_name, email, phone, locations, sub, stripe_customerid}
    return trimmed;
}

export {trimUserProfile}