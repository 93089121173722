import React, {  useEffect } from 'react';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { DeliveryCountdown } from './DeliveryCountdown';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import AppBar from "@mui/material/AppBar";
import { makeStyles } from '@mui/styles';
// import {  useDispatch} from 'react-redux';
import {Link} from 'react-router-dom'

export default function ({  subscriberinfo, selectSubMenu=null, marketplaceclosed, handleChange, v, headerRef }) {
    if (!subscriberinfo) return null;

    const { subscriberid, name, address, city, STATE, expiry, menu } = subscriberinfo;
    const dte_nextorderbytoday = get(subscriberinfo, 'expiry.orderby', null);
    let formated_delivery_time = get(expiry, 'delivery_f') || get(expiry, 'nextDeliveryBy_f');
    const restaurant_logourl = (id: string) => (`https://togonowloc.imgix.net/${id}.jpg?w=100`);
    // const clearSelectedSubscriber = () => dispatch(__clearSelectedSubscriber())



    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        if(selectSubMenu){
            handleChange(null,selectSubMenu)
        }

    }, [selectSubMenu, handleChange])

    const useStyles = makeStyles({
        root: {
            // outline: 'none'
            // flexGrow: 1,
            // maxWidth: 500,
        },
        botton: {
            // outline: 'none'
        }
    });

    const classes = useStyles();

    return (
        <>
            <nav className="navbar navbar-expand-md navbar-dark mdb-color py-0 my-0">
                <Link to="/menu" className="h2 text-success me-2"> 
                    <FontAwesomeIcon icon={faArrowLeft} color="green" transform="shrink-4 down-1" style={{ cursor: 'pointer' }} />
                </Link>
                {/* <img className="navbar-brand rounded-circle" src={restaurant_logourl(subscriberid)} alt={name} /> */}
                <div className="me-auto">
                    <address>
                        <strong>{name}</strong><br />
                        {address && <div>{address} {city}, {STATE}</div>}
                    </address>
                </div>
                {!marketplaceclosed && formated_delivery_time && <div className="text-success lead" style={{ lineHeight: '150%' }} id='pre_countdown'>
                    Next delivery {formated_delivery_time}.
                    <DeliveryCountdown textFormat={''} nextOrderbyToday={dte_nextorderbytoday} />
                </div>}
            </nav>
            {menu  &&  menu.Groups && (
                // <ElevationScroll >
                <AppBar ref={headerRef} position='sticky' elevation={0} className={classes.root + ' aaa'} style={{zIndex : 1000}} >
                    <Paper square className={classes.root  + ' bbb'} elevation={0} >
                        <Tabs
                            value={v || false}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs ccc"
                            TabIndicatorProps={{
                                style: {
                                    // background:'red',
                                        height:"4px",
                                }}}
                                // centered
                        >
                            {menu.Groups.map(g => <Tab key={g.groupID} label={g.groupTitle} {...a11yProps(g.groupTitle)} value={g.groupTitle} />)}
                        </Tabs>
                    </Paper>
                </AppBar>
                // </ElevationScroll>                
            )}
        </>
    );

}