import React, { useEffect, useState, useContext } from 'react';
import accounting from 'accounting';
import './itemDetail.css';
import PropTypes from 'prop-types';
import { 
    Modal, ModalHeader, ModalBody,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { useSelector, useDispatch} from 'react-redux';
import {InsightsContext}  from "../Insights";
import {get, has} from 'lodash';

import ItemOptions from './ItemOptions';
import ImageWithStatusText from './tinyparts/ImageWithStatusText';

import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
// import CardTitle from '@mui/material/CardTitle';
import { makeStyles } from '@mui/styles';

import {  selectItem, selectSubscriber, addItem, selectOV } from '../redux/actions/marketplaceActions';
import {calculateSelectionMarkup, optionsPrintout} from '../redux/utils/aggregate'


import {useHistory} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({

    media: {
      backgroundSize: 'contain',
      height: '50vh',
      borderRadius: "4px",
      position: "relative",
      zIndex: 1000
    }

}));

function ItemDetail ({
    toggleAddedToCart
}) {


    const dispatch = useDispatch()


    const { locationSubscriberInfo, selectedItem,  marketplaceclosed, selectedSubscriber } = useSelector(({marketplaceReducer}) => ({
        locationSubscriberInfo: marketplaceReducer['availableSubscribers'][marketplaceReducer.selectedSubscriber],
        selectedItem : get(marketplaceReducer, 'selectedItem'),
        marketplaceclosed: marketplaceReducer.marketplaceclosed,
        selectedSubscriber: get(marketplaceReducer , 'selectedSubscriber')
      }));
  
    
    const [AddPaymentModalOpen, setAddPaymentModalOpen] = useState(true);
    const [qtyDropDownOpen, setqtyDropDownOpen] = useState(false);
    const [itemQty, setitemQty] = useState(1);
    const [currentSize, setcurrentSize] = useState('x1');
    const [itemImageUrl, setitemImageUrl] = useState(null);
    
    
    
    const classes = useStyles();

    // *********
    // static contextType = InsightsContext;
    // *********

    const { appInsights } = useContext(InsightsContext);

    useEffect(()=>{
        if(has(selectedItem, 'item_Image')){
            setitemImageUrl(null);
        }
        const _imgixparam = 'fill-color=fff&auto=compress&fit=fillmax&fill=blur&h=700&w=700?padding=2px';
        setitemImageUrl(get(selectedItem, 'item_Image') ? `https://togonow.imgix.net/${selectedItem.item_Image}?${_imgixparam}` : null);
    },[selectedItem]);

    function toggle() {
        setqtyDropDownOpen(!qtyDropDownOpen)
    }

    function select_qtyDropDownOpen(e){
        setqtyDropDownOpen(!qtyDropDownOpen)
        setitemQty(e.target.innerText)

    }

    function clearSelectedItem(){
        dispatch(selectItem(null));
    }

    function handleMoreLikeThis(){
        dispatch(selectSubscriber(locationSubscriberInfo.subscriberid))
        dispatch(clearSelectedItem());
    }

    if(!selectedItem || !locationSubscriberInfo) return null;


        

        return(<Modal  
            size='xl'
            fade
            isOpen={selectedItem !== null} 
            backdrop={true}
            modalTransition={{ timeout: 0 }}
            style={{zIndex: 1500}}
        >
            <ModalHeader className="" toggle={clearSelectedItem}  >

            </ModalHeader>
            <ModalBody >
                    <div className="row mb-2" >
                        {itemImageUrl && 
                            <div className="col-md-6">
                                <Card className={classes.root}>
                                    <CardMedia
                                        className={classes.media}
                                        image={itemImageUrl}
                                        title={selectedItem.item_Title}
                                    />
                                </Card>
                            </div>
                        }                        
                        <div className="col-md-6">
                            <div className="a4_0 p-2  border-2 border-left  border-secondary   bg-white">
                                <div className='mb-1'>
                                    <h1 className="d-inline me-3">{selectedItem && selectedItem.item_Title}</h1>
                                    {!selectedSubscriber && <div onClick={handleMoreLikeThis} 
                                        style={{cursor: 'pointer'}}
                                        className="p-1 bg-success text-white small d-inline">more like this</div>}
                                </div>
                                { <div className="lead mb-2"> {locationSubscriberInfo.name}</div>}

                                <p>{selectedItem && selectedItem.item_Description}</p>
                                <h3 className="cost">{selectedItem && accounting.formatMoney(calculateSelectionMarkup(selectedItem, true))}<small className="pre-cost"><span className="glyphicon glyphicon-usd"></span></small></h3>
                                {marketplaceclosed ? <div className="text-danger">Currently closed!</div> :
                                    <div className="d-flex mb-1">        
                                        <Dropdown  isOpen={qtyDropDownOpen} toggle={toggle}>
                                            <DropdownToggle caret color="success">
                                            Qty: {itemQty}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >1</DropdownItem>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >2</DropdownItem>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >3</DropdownItem>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >4</DropdownItem>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >5</DropdownItem>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >6</DropdownItem>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >7</DropdownItem>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >8</DropdownItem>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >9</DropdownItem>
                                                <DropdownItem onClick={select_qtyDropDownOpen} >10</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <button 
                                            onClick={
                                                (e) => {
                                                    if(marketplaceclosed) {e.preventDefault(); return alert('Store is currently closed')};
                                                    dispatch(addItem(selectedItem, itemQty));
                                                    dispatch(selectItem(null));
                                                    toggleAddedToCart(locationSubscriberInfo.name , `${itemQty} x ${selectedItem.item_Title}.`)
                                                    appInsights.trackEvent({name: 'ItemAddedToCart'} ,
                                                        {
                                                            id:     selectedItem.item_ID, 
                                                            title:  selectedItem.item_Title, 
                                                            price:    selectedItem.item_Price,
                                                            qty:     itemQty,
                                                            subscriberid: selectedItem.subscriberid 
                                                        });

                                                }
                                            }
                                            type="button" 
                                            className="btn btn-success ms-2">
                                        add to cart
                                    </button>
                                    </div>
                                }
                            </div>
                            {selectedItem  && <ItemOptions  menuItem={selectedItem} {...{selectOV : dispatch(selectOV)}}/>}
                        </div>
                    </div>


            </ModalBody>
        </Modal>
        )
    
}


export default ItemDetail