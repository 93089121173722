
import React ,{Fragment} from 'react';
import {OptionValue} from './OptionValue';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';

import './ItemOption.css';
import { 
    // renderToString , 
    renderToStaticMarkup} from 'react-dom/server';

export class ItemOption extends React.Component{
    rawMarkup =  (O, rawOptionValue) =>{
        if(O.option_Title){
            return { __html: `<span><b>${O.option_Title}</b>: ${rawOptionValue + '&nbsp &nbsp'}</span>`   }
        }else{
            return { __html: ``};
        }
    }

    render(){
        const {O, OGid, Oid, hasNoIndex, simultedCheckBox, selectedOV_MAP, selectOV, isPrintout} = this.props;

        //*** if the selected option value is no and is default, and is printout then skip */
        //*** SKIP showing NO OPTIONS in printout */
        if(isPrintout && 
            hasNoIndex > -1  && 
            O.OptionValues[hasNoIndex ].optionvalue_isdefault === true &&
            (isEmpty(selectedOV_MAP) ||      // is selected
            O.OptionValues[hasNoIndex].optionvalue_ID === selectedOV_MAP[0].split('.')[2])
        ){
            return <React.Fragment />;
        }
        
        const oType = O.option_OptionType;
        // no is selected and is default and is printout then skip
        const passThrough = {OGid,Oid,oType, hasNoIndex, simultedCheckBox, selectedOV_MAP, selectOV, isPrintout};
        if(isPrintout === true){
            //  Pick One: *
            let keyval = Math.random();
            return (
                <div  key={keyval} className="d-inline-block me-1">
                    <span key={keyval +1} className="font-weight-bold">{(O.option_Title ? O.option_Title + ": " : '' )}</span>
                    <OptionValue  key={keyval +2} OVs={O.OptionValues}  {...passThrough }  />
                </div>
            )
            
        }
        passThrough.OVs = O.OptionValues;
        passThrough.children = O.children;
        if(isPrintout === 'richtext'){
            // Pick One: 
            passThrough.isPrintout = 'richtext';
            const rawOptionValue = renderToStaticMarkup(<OptionValue {...passThrough}></OptionValue>);
            let __rawMarkup = {__html : (O.option_Title ? '<strong>' + O.option_Title + "</strong>: " : '' ) + rawOptionValue};
            // Pick One: 
            return (
                    <span dangerouslySetInnerHTML={__rawMarkup} />
            )
        }

        return(
            simultedCheckBox ? 
                <Fragment>
                    {O.option_Title && <label className="a1_0" htmlFor="inlineCheckbox1">{O.option_Title}</label>         }
                    <OptionValue   {...passThrough }  />
                </Fragment>                            
            :
                <div className="a1_1 mb-3">
                    {O.option_Title && <label className="mb-0 ms-3 a1_2" htmlFor="inlineCheckbox1">{O.option_Title}</label>         }
                    <OptionValue  {...passThrough }  />
                </div>
        )
    }
}

ItemOption.propTypes = {
    OGid: PropTypes.string.isRequired,
    Oid: PropTypes.string.isRequired,
}