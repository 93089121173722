import React, { Component, useState, useEffect, useContext } from 'react';
import { get } from 'lodash';
import './HeaderView.css';
import LocationSelection from '../../components/LocationSelection';
import { LoginWidget } from './LoginWidget';
import { AuthContext } from '../../Auth';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faCartShopping, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
// import { faShoppingCart, faCartShopping, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Help from '../../components/Help';
import Button from 'react-bootstrap/Button';
import DROPDOWN from 'react-bootstrap/Dropdown';


import IconButton from '@mui/material/IconButton';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
// import Badge from '@mui/material/Badge';
// import {   withStyles} from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Badge from 'react-bootstrap/Badge';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { sizeHeight } from '@mui/system';
import { showChatWidget } from '../../components/tinyparts'


// Hook
function useMedia(queries, values, defaultValue) {
    // Array containing a media query list for each query
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));
    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.findIndex((mql) => mql.matches);
        // Return related value or defaultValue if none
        return typeof values[index] !== "undefined" ? values[index] : defaultValue;
    };
    // State and setter for matched value
    const [value, setValue] = useState(getValue);
    useEffect(
        () => {
            // Event listener callback
            // Note: By defining getValue outside of useEffect we ensure that it has ...
            // ... current values of hook args (as this hook callback is created once on mount).
            const handler = () => setValue(getValue);
            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach((mql) => mql.addListener(handler));
            // Remove listeners on cleanup
            return () =>
                mediaQueryLists.forEach((mql) => mql.removeListener(handler));
        },
        [] // Empty array ensures effect is only run on mount and unmount
    );
    return value;
}


const styles = theme => ({
    toolbar: {
        backgroundColor: '#01791f', //COLOR NAME FUNGREEN

    },
    branding: {
        flexGrow: 1,
    }
});


const headerExclusionArray = ['address'];
const size = { a: '-3x', b: '-xl' }

const HeaderView = function (props) {
    const [accountDropDownOpen, setAccountDropDownOpen] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [notifyPopoverOpen, setNotifyPopoverOpen] = useState(null)

    const sizeOfButton = useMedia(
        // Media queries
        ["(min-width: 1500px)", "(min-width: 1000px)", "(min-width: 600px)"],
        // Column counts (relates to above media queries by array index)
        ['lg', 'lg', 'sm'],
        // Default column count
        'sm'
    );

    const sizeOfFA = useMedia(
        // Media queries
        ["(min-width: 1500px)", "(min-width: 400px)", "(min-width: 200px)"],
        ['-2x', '-lg', '-lg'],
        // ['-2x', 'xl', 'lg'],
        '-xs'
    );



    const { currentProfile, idToken } = useContext(AuthContext) || {};

    function selectLocation(customerlocation) {
        setSelectedLocation(customerlocation)
        // this.setState(() => { return { 'selectedLocation': customerlocation } });
    }

    function toggle_locationDropDown() {
        setlocationDroDownOpen(current => !current)
        // this.setState((prevState, props) => {
        //     return { locationDropDownOpen: !prevState.locationDropDownOpen };
        // });
    }



    function handleClick(event) {
        event.preventDefault();
        switch (event.currentTarget.id) {
            case 'help':
                props.history.push({ pathname: this.props.location.pathname, search: 'help=true' });
                break;
            case 'others':
                if (selectedLocation && props.setLocation) {
                    setLocation(this.state.selectedLocation);
                    props.getPlaceByID(this.state.selectedLocation.getPlaceByID);

                }
                break;
            default:
            // do nothing
        }
    }

    function toggle_accountDropDown() {
        setAccountDropDownOpen(current => !current)
        // this.setState({ 'accountDropDownOpen': !this.state.accountDropDownOpen });
    };

    function toggle_notifyPopoverOpen() {
        setNotifyPopoverOpen(current => !current)
        // this.setState({ 'notifyPopoverOpen': !this.state.notifyPopoverOpen });
    };


    function goHome(e) {
        e.preventDefault();
        props.clearSelectedSubscriber();
        props.history.push({ pathname: '/' });
    }

    // render() {
    if (headerExclusionArray.indexOf(props.location?.pathname) > -1) return null;
    // const { currentProfile } = this.context || {};
    const locations = get(currentProfile, 'locations', null);
    const {
        classes,
        cartItemCount, toogleCartDock,
        logoutSuccess, logout, loginRequest, history,
        customerlocation,
        setLocation,
        getPlaceByID
    } = props;
    // 
    let variant = 'success' // 'link'
    let size = 'sm'
    return (<div style={{ backgroundColor: '#01791f' }} className="d-flex  mb-3">
        <div className="d-flex me-auto ">
            <img
                src='https://togonowassets.imgix.net/logo.png?h=125' alt="ToGoNow Marketplace"
                style={{
                    height: "auto", width: "auto",
                    maxHeight: '8vh',
                    cursor: 'pointer'
                }}
                onClick={goHome}
                className="img-fluid m-sm-2 px-0 my-2 col-5" />
        </div>




        <div className={' me-2 my-auto    '}>
            <div>{<LocationSelection  {...{ setLocation, getPlaceByID, customerlocation, locations, history, compact: true }} />}</div>
            <div className={" aaa d-flex justify-content-end "}>
                <Button variant={variant}
                    className={'m-md-e-2'}
                    size={size} onClick={toogleCartDock}>
                    <FontAwesomeIcon icon={faShoppingCart} color={"white"} className={` fa${sizeOfFA}`} />
                </Button>

                <LoginWidget className="aaa" />


                <Button variant={variant} size={size}
                    onClick={showChatWidget}
                >
                    <FontAwesomeIcon icon={faQuestionCircle}
                        color={"white"} className={` col   fa${sizeOfFA}`} />
                </Button>
            </div>
        </div>


    </div >
    );
    // }
}

export default withStyles(styles, { withTheme: true })(HeaderView);