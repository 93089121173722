import React from 'react';
import PropTypes from 'prop-types';
import QtyStepper from './QtyStepper';



const CartLine = ({ itemInCart, changeQtyItemInCart,
    isAbstract }) => {
    const {
        qty, selectionString } = itemInCart;

    return (
        (isAbstract === true ?
            <li className="list-group-item">
                <div className="d-flex  justify-content-between fw-bolder">
                    <span>{itemInCart.item_Title}</span>
                    <span className="badge "><QtyStepper  {...{ qty, changeQtyItemInCart, itemInCart }} /> </span>
                </div>
            </li>
            :

            <li className="list-group-item d-flex">
                <div className=" fw-bolder">
                    <h3 className=''>{itemInCart.item_Title}</h3>
                    {selectionString &&
                        <p
                            className="text-muted ms-2"
                            dangerouslySetInnerHTML={{ __html: selectionString }}></p>
                    }
                </div>
                <span className="badge "><QtyStepper  {...{ qty, changeQtyItemInCart, itemInCart }} /> </span>
            </li>
        )
    )
}

CartLine.propTypes = {
    isAbstract: PropTypes.bool,
}

export default CartLine;