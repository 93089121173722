import React, {Component} from 'react';
import { Collapse, Spinner} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCheckCircle as facheckHollow } from '@fortawesome/free-regular-svg-icons';
import {  faCheckCircle, faPlus} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../tinyparts/ModalAlert';
// import {Elements} from 'react-stripe-elements';
import {ElementsConsumer, PaymentElement} from '@stripe/react-stripe-js';
import StripeEntryForm from './StripeEntryForm';
import {CCIcon} from '../tinyparts';
import Card from './Card';
import {BackTo} from '../tinyparts/BackTo';
import { getType } from '../../togonowutils';

class Cardlist extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedCard : null,
            AddPaymentModalOpen: false,
            removeCardModalIsOpen: false,
        }
        // this.auth = new Auth();
        this.showRemoveCardModal = this.showRemoveCardModal.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    showRemoveCardModal(cardid){
        this.setState({selectedCard: cardid, removeCardModalIsOpen: true});
    }

    toggle = (e)=>{
        if(e.target.id === 'cancel'){
           this.setState(prevState =>{
               return { removeCardModalIsOpen : !prevState.removeCardModalIsOpen, selectedCard: null }
            });
        }
        if(e.target.id === 'ok'){
            const t = this.auth.getToken();
            this.props.deleteCard(this.state.selectedCard,t);
            this.setState(prevState =>{
                return { removeCardModalIsOpen : !prevState.removeCardModalIsOpen, selectedCard: null }
             });
        }
    };
    
    render(){
        const {toggle } = this;
        const {  
            collapse, stripeProfile, selectedOrDefaultCard, selectCardAction,
            goPayment,
            presentAsCard, darkgreen,
            setStripeElement, setToken,
            handleClickAddPayment,
            allowAddNewPayment,
            errorCard,
            history
        } = this.props;

        const{ removeCardModalIsOpen } = this.state;
        if(presentAsCard){
            return (
            <div className="container mt-2"> 
                <ModalAlert 
                    isOpen={removeCardModalIsOpen || false} 
                    card = {this.state.selectedCard}
                    title='Remove Payment Method'
                    strCancel='Cancel'
                    toggle={toggle}
                    body='Remove payment ending with x 4242.'/>
                <BackTo {...{history}}  />
                <div className="card-header">
                    Manage Payments</div>
                <div className="card-body row">
                {stripeProfile && stripeProfile.sources.map((card, index) => (
                    <Card key={index} showRemoveCardModal={this.showRemoveCardModal} {...{darkgreen, card}} />
                ))}
                </div>
                {allowAddNewPayment &&
                    <div className="card-footer">
                        {errorCard && <div className='text-danger'>{errorCard}</div>}
                        <ElementsConsumer className="d-flex align-self-center"   > 
                            <StripeEntryForm   {...{  setToken,   }} onRef={ref => { setStripeElement(ref);  }} />
                        </ElementsConsumer>
                        <button onClick={handleClickAddPayment}>Add</button>
                    </div>
                    }
            </div>)
        }


        return (   
            <Collapse isOpen={collapse}>
                <small className="mt-2 ms-2 text-muted" htmlFor="selectCard">Select Payment Method or add new payment method.</small>
                <div className=" list-group ms-4" id="selectCard" >
                    {strypeProfile === 'loading' && <Spinner />}
                    {getType( stripeProfile) === 'object' && stripeProfile.sources.map((card, index,sources) => (
                            <button href="#" className="list-group-item list-group-item-action"   key={index} onClick={(e) => selectCardAction(card)}>
                                {selectedOrDefaultCard && selectedOrDefaultCard.id === card.id ?
                                    <FontAwesomeIcon icon={faCheckCircle}  className='me-4'  color="green"  transform="grow-2"/>
                                    : 
                                    <FontAwesomeIcon icon={facheckHollow}  className='me-4'   color="lightgray"  transform="grow-2"/>
                                }
                                <CCIcon brand={card.card.brand} height={'64'} />
                                <span className={`ms-2 ${selectedOrDefaultCard && selectedOrDefaultCard.id === card.id  ? "text-success"   : "text-muted"  }`}> ....{card.last4}  Exp. {card.exp_month}/{card.exp_year}</span>
                            </button>
                            
                    ))}  
                    <button href="#"  className="list-group-item list-group-item-action"  onClick={(e) => goPayment()}>
                        <FontAwesomeIcon icon={faPlus}  className='me-2'   color="green"  transform="grow-2 up-2"/>
                        <span className="ms-2 lead">Add Card</span>
                    </button>
                </div>
            </Collapse>  
        )
        
    }
}


Cardlist.defaultProps = {
    presentAsCard : false,
    darkgreen : "#01791f"
}
export default Cardlist;