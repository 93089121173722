// checkout example at https://github.com/erikras/react-redux-universal-hot-example/blob/master/src/utils/validation.js
import { forOwn, union, forEach, pick, flatten, map, startsWith, filter, get ,  sumBy, find, chain, isEmpty } from 'lodash';
// import { } from 'lodash';
import moment from 'moment-timezone';
import { throwIfMissing } from '../../togonowutils';

export const getSubscriberIdsFromMarketplaceMenu = (marketplacemenu) => {
    let uniquesubscribers = [];
    forOwn(marketplacemenu, function (items, subscriberid) {
        uniquesubscribers = union(
            uniquesubscribers,
            map(items, function (item, idx) {
                return item.subscriberid;
            })
        )
    });
    return uniquesubscribers;
}


export const arrSubscriberToObject = (__payloadData = throwIfMissing(), availableSubscribers = {}) => {
    __payloadData.subscriberinfo.forEach((s_info) => {
        if (s_info.menuversion && availableSubscribers[s_info.subscriberid] &&
            (s_info.menuversion !== availableSubscribers[s_info.subscriberid].menuversion)) {
            s_info.menu = null;
            s_info.menuloaded = false;
        }
        availableSubscribers[s_info.subscriberid] = { ...availableSubscribers[s_info.subscriberid], ...s_info };
    });
}

//****************************************** */
// builds up 11:00 am and 12:00 pm etc..
// generates 6:15 AM & 6:25 AM & 1:32 PM
//****************************************** */
export const deliveryTimes = (upcomingDeliveries, strWhen = '') => {
    const AmPm_arrDeliveriesToday = upcomingDeliveries.map((m, i) => {
        let moment_m = moment(m).tz("America/Los_Angeles");
        strWhen = filterDay(moment_m.fromNow()) || '';
        return `${i > 0 ? ' &' : ''} ${moment_m.format("h:mm A")}`;
    }); // generates ['12:00 PM',' & 12:30 PM']
    const formattedDeliveriesToday = AmPm_arrDeliveriesToday.join('');
    return { formattedDeliveriesToday, strWhen };
}
function filterDay(amDifferenceOutput) {
    var mappings = {
        "in a day": "Tomorrow",
    };
    var mappedValue = mappings[amDifferenceOutput];
    return mappedValue || null;
}
//****************************************** */
//****************************************** */


// Decides when the app should reload to reflect the new delivery times and item availablity
export const calculateNextUpdate = (nextOrderbyToday) => {
    let tomorrow_midnight = moment(new Date()).tz("America/Los_Angeles").add(1, 'days').local().startOf('day').utc().format();
    let min_update = nextOrderbyToday || tomorrow_midnight; //min([nextOrderbyToday, tomorrow_midnight]);
    let nextUpdate = moment(min_update).diff(moment(), 'seconds');
    return nextUpdate;
}


// decide wheather the item can be delivered today and subscriber is enabled
// should be deprecated
// export const  itemEnabled= (subscriberinfo, marketplaceclosed) => {
//     const fulfillToday = get(subscriberinfo, 'expiry.fulfill_today', true);
//     const subscriberDisabled = get(subscriberinfo, 'disabled', false);
//     const subscriberinfo_closed = get(subscriberinfo, 'status', null) === 'closed'
//     const isEnabled = fulfillToday && !marketplaceclosed && !subscriberDisabled && !subscriberinfo_closed;
//     return isEnabled;
// }


export const func_isEnabled = (locationSubscriberInfo, marketplaceclosed = throwIfMissing()) => {
    if (marketplaceclosed) return false;

    const fulfillToday = get(locationSubscriberInfo, 'expiry.fulfill_today', true);
    const subscriberDisabled = get(locationSubscriberInfo, 'disabled', false);
    const isEnabled = fulfillToday && !subscriberDisabled;
    return isEnabled;
}

//** two items match if they have the same selected optionalues */
export const compareTwoItems = (item, base) => {
    if (item.item_ID !== base.item_ID) return false;
    let baseOVs = flattenOVs(base);
    let itemOVs = flattenOVs(item);
    let areEqual = true;
    forEach(baseOVs, (bOV, idx) => {
        if (!compareOVs(bOV, itemOVs[idx])) {
            areEqual = false;
        }
    });
    return areEqual;
}

export const compareOVs = (OV1, OV2) => {
    if (OV1.optionvalue_ID === '2AF91A21-7CAB-4E58-919C-607A79991157')
        if (OV1.optionvalue_ID !== OV2.optionvalue_ID) return false;  // must match optionvalue_ID
    let ov1selected = false, ov2selected = false;
    ov1selected = (OV1.optionvalue_isdefault === true || OV1.selected === true) || false;
    ov2selected = (OV2.optionvalue_isdefault === true || OV2.selected === true) || false;
    return ov1selected === ov2selected;
}

//*** use this function to get the list of all possible option values from an item  ****
//*** used to compare items    *********************************************************
export const flattenOVs = (item) => {
    if (!item.OptionGroups || item.OptionGroups.length === 0) return [];
    let itemOVs = []
    item.OptionGroups.map((OG) => {
        OG.Options.map(O => {
            itemOVs.push(pick(O, 'OptionValues').OptionValues);
            return true;
        });
        return true;
    })
    return flatten(itemOVs);
}



//*** get selection printouts and markup */

export const calculateSelectionMarkup = (cartItem = throwIfMissing(), addToPrice = false)=>{
    if(cartItem.selectedOVs){
        const markupSum =  sumBy(cartItem.selectedOVs, (sOV) => {
            const [OGid,Oid,OVid, id, SubOV] = sOV.split('.');

            const relatedOG = find(cartItem.OptionGroups, function (og) { return og.optionGroup_ID === OGid })
            if(relatedOG === undefined) throw new Error(`Invalid optionGroup selected ${OGid} not found`);
            const relatedO = find(relatedOG.Options, function (o) { return o.option_ID === Oid});
            if(relatedO === undefined)  throw new Error(`Invalid option selected ${Oid} not found`);
            const relatedOV = find(relatedO.OptionValues, function (ov) { return ov.optionvalue_ID === OVid});
            if(relatedOV === undefined)  throw new Error(`Invalid optionvalue selected ${OVid} not found`);
            if(id){
                const relatedChild = find(relatedO.children, function (c) { return c.id === id});
                if(relatedChild === undefined)  throw new Error(`Invalid Option children selected ${id} not found`);
                const relatedSubOption = find(relatedChild.suboptions, function (so){return so.id === SubOV})
                if(relatedSubOption === undefined)  throw new Error(`Invalid SubOption selected subotpion ${id} not found`);
                return  Number.parseFloat(relatedSubOption.markup || 0);            //        Number.parseFloat((Number.parseFloat(relatedSubOption.markup)? Number.parseFloat(relatedOV.optionvalue_markup): 0) );
            }else{

                return Number.parseFloat(relatedOV.optionvalue_markup|| 0) ; //  Number.parseFloat((Number.parseFloat(relatedOV.optionvalue_markup)? Number.parseFloat(relatedOV.optionvalue_markup): 0) );
            }
        });
        const subtotal = addToPrice ? Number.parseFloat( cartItem.item_Price) + markupSum : markupSum;        //   + Number.parseFloat(addToPrice ? markupSum : cartItem.item_Price : 0) + markupSum
        return subtotal;
    }else{
        return addToPrice ? Number.parseFloat(cartItem.item_Price) :  0 ;
    }
}

export const ovMap = (ov, OGid) => `${OGid}.${ov.optionvalue_optionid}.${ov.optionvalue_ID}`;
export const splitBy = (ovMap , i, by = '.') => (ovMap + '').split(by)[i];


export const optionsPrintout = (item) => {
    if (!item.OptionGroups) {
        return null;
    }

    let selections_OG = item.OptionGroups.map((OG) => {
        const Os = OG.Options,
            OGid = OG.optionGroup_ID,
            selectedOVs = item.selectedOVs;
        let selection_O = Os.map((O) => {
            let Oid = O.option_ID;
            const hasNoIndex = O.OptionValues.findIndex((ov) => (ov.optionvalue_value.toLowerCase().startsWith('no')));
            const simultedCheckBox = hasNoIndex > -1 && O.OptionValues.length === 2;
            const strStartsWith = `${OGid}.${Oid}.`;
            const selectedOV_MAP = filter(selectedOVs, (sOV) => startsWith(sOV, strStartsWith))[0];
            // skip Options that defaults to no
            if (hasNoIndex > -1 &&
                O.OptionValues[hasNoIndex].optionvalue_isdefault === true &&
                (selectedOV_MAP === undefined ||      // is selected
                    O.OptionValues[hasNoIndex].optionvalue_ID === selectedOV_MAP.split('.')[2])
            ) {
                return null;
            } else {

                // let OVid;
                let OVs = O.OptionValues;
                if (simultedCheckBox) {
                    let isChecked = false;
                    const no_OptionValue = OVs[hasNoIndex];
                    const textOptionValue = (hasNoIndex === 0 ? OVs[1] : OVs[0])// since it's only two values 0 or 1 we can use 
                    if (selectedOV_MAP) {
                        let ovFromMapped = splitBy(selectedOV_MAP, 2);
                        if (ovFromMapped === textOptionValue.optionvalue_ID) {
                            isChecked = true;
                        }
                        if (ovFromMapped === no_OptionValue.optionvalue_ID) {
                            isChecked = false;
                        }
                    } else {
                        if (textOptionValue && textOptionValue.optionvalue_isdefault) {
                            isChecked = true;
                        }
                        if (no_OptionValue && no_OptionValue.optionvalue_isdefault) {
                            isChecked = false;
                        }
                    }
                    const OV = textOptionValue;
                    const selection_OV = (isChecked ? 
                        `${OV.optionvalue_isdefault ? OV.optionvalue_value : `<i>${OV.optionvalue_value}${OV.optionvalue_markup ? '-$ ' : ''}</i>`}, `
                    :
                        (no_OptionValue.optionvalue_isdefault ?
                            ''
                        :
                            `<s>${OV.optionvalue_value}</s>, `
                        )
                    )
                    return selection_OV;
                }
                const listOVs = OVs.map((OV) => {
                    const OVid = OV.optionvalue_ID
                    let isChecked2 = false;
                    let SubVOsprintout = ''

                    if ((selectedOV_MAP && splitBy(selectedOV_MAP, 2) === OVid) || (!selectedOV_MAP && OV.optionvalue_isdefault)) {
                        // This OV is selected, need to get the related Sub OVs
                        isChecked2 = true;
                        // get the printout of subOVs
                        if(!isEmpty(O.children)){
                            // let relatedSubOVs = chain(O.children).filter(c => c.optionvalue_ID === OVid).head().value();
                            let relatedChilds = chain(O.children).filter(c => c.optionvalue_ID === OVid).value();
                            
                            if(!isEmpty(relatedChilds)){

                                relatedChilds.forEach(relatedChild =>{
                                    // find if anything selected from this suboptions set
                                        let foundSelectedSubOption = chain(selectedOVs).filter(sov_map => {
                                            let str = `${OGid}.${Oid}.${OVid}.${relatedChild.id}.`;
                                            return sov_map.startsWith(str);
                                        }).head().value();
                                    let suboptions = relatedChild.suboptions;
                                    if(foundSelectedSubOption){
                                        // console.log("optionsPrintout -> foundSelectedSubOption", foundSelectedSubOption)
                                        const r = chain(suboptions).filter(so => so.id === splitBy(foundSelectedSubOption, 4)).head().value();
                                        SubVOsprintout = SubVOsprintout + ' ' + r.title + (r.isdefault ? '' :'*');
                                    }else{
                                        const defaultSubOption = chain(suboptions).filter(so => so.isdefault === true).head().value();
                                        // console.log("optionsPrintout -> defaultSubOption", defaultSubOption)
                                        SubVOsprintout = SubVOsprintout + ' ' + defaultSubOption.title
                                    }
                                });
                            }
                        }
                    }
                    let addDollar = (isChecked2 ?   OV.optionvalue_value + (OV.optionvalue_markup ? '-$ ' : ' ')  : '');
                    let  addItalic = `<i>${addDollar}</i>`
                    return addItalic + SubVOsprintout;
                });






                
                return (O.option_Title ? '<strong>' + O.option_Title + "</strong>: " : '') + listOVs.join(''); // + listSubOvs.join('');
            }
        });
        return (OG.optionGroup_Title ? `<u>${OG.optionGroup_Title}</u>: ` : '') + selection_O.join('');
    })
    return selections_OG.join('');
}