import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

const __MinsInSeconds = 60;
const __HoursInSeconds = 60 * 60;
const __DayInSeconds = 60 * 60 * 24;

const Timer = ({ secondsOrMoment, critical=300 }) => {

    let [timerClock, setTimerClock] = useState(-1);
    let [timerPointer, setTimerPointer] = useState(null);

    const formatter = (timerClock) => {

        let day = 0, hour = 0, min = 0, sec = 0, deducted = timerClock, str='';
        if(deducted > __HoursInSeconds){

            hour = Math.trunc(deducted/ __HoursInSeconds)
            deducted = deducted % __HoursInSeconds
            str+= `${hour}h `;
        }

        
        if(deducted > __MinsInSeconds){
            min = Math.trunc(deducted/ __MinsInSeconds)
            deducted = deducted % __MinsInSeconds
            str+= `${min}m `;
        }

        if(deducted > 0 && hour === 0 && min <= 2){
            sec = Math.trunc(deducted)
            str+= `${sec}s `;
        }

        str+= ' left';
        return str;

    }

    const clearing = () => {
        clearTimeout(  timerPointer);
        setTimerPointer(null)
    }


    useEffect(() => {
        return () => { // this should work flawlessly besides some milliseconds lost here and there 
            clearing()
        }
    }, [])


    useEffect(() => {
        if(timerClock < 0) return;


        const timer = setTimeout(function () {
            setTimerClock(timerClock - 1);

        }, 1000)
        setTimerPointer(timer);

    }, [timerClock]);

    useEffect(() => {

        if (secondsOrMoment === NaN || secondsOrMoment === undefined) return setTimerClock(-1);

        if(timerPointer) clearing();


        // check if secondsOrMoment is integer or date
        if (moment.isMoment(secondsOrMoment) === true) {
            let seconds = secondsOrMoment.diff(moment(), 'seconds')
            setTimerClock(seconds)
            return
        }

        // check if secondsOrMoment is integer or date
        if (moment.isDate(secondsOrMoment) === true) {
            let seconds = (secondsOrMoment - new Date()) / 1000;
            setTimerClock(seconds)
            console.log('parameter is Date', seconds)
            return
        }
        setTimerClock(secondsOrMoment)
    }, [secondsOrMoment]);

    if (timerClock < 0) {
        return null;
    }
    if (timerClock) return <div className={'badge ' + timerClock < critical ? 'text-danger' : ''} >{formatter(timerClock)}</div>

    return null;




}


export default Timer

