import React from 'react'
import LoadingSpinner from './loading.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinnerButton from './loading_button.svg';


export const ButtonSpinner = ({ size = '50px' }) => <div
    style={{ height: size, width: size, justifyContent: 'center', display: 'inline' }} src={LoadingSpinnerButton} >
    <img src={LoadingSpinnerButton} alt="loading" style={{ height: size, width: size, }} />
</div>


export const Spinner = () => <div id='wrapper' style={{ display: 'flex', height: '100%', alignItems: 'stretch' }}>
    <div id='spin' >
        <img src={LoadingSpinner} alt="loading" />
    </div>
</div>


export const _Errored = ({ message }) => <div id='wrapper' style={{ display: 'flex', height: '100%', alignItems: 'stretch' }}>
    <div id='error' alignitems="center">
        <FontAwesomeIcon icon={faExclamationTriangle} color={"red"} size="6x" />
        {message && message}
    </div>
</div>

/* e.g.
    <Errored message={error} size={'2x'}  >
        <span onClick={hydatePaymentIntent}>  Retry</span>
    </Errored>
*/
export const Errored = ({ message, size = '6x', recovery = null, children }) => <div className={"row"}>
    <div className={"row justify-content-center"}>
        <div className={"col-3"}>
            <FontAwesomeIcon icon={faExclamationTriangle} color={"red"} size={size} />
            {message && message}
            {recovery}
            {children}
        </div>
    </div>
</div>