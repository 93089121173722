import React, { useState } from 'react';
import { Elements, useStripe, PaymentElement, useElements, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { ButtonSpinner } from '../tinyparts/Spinners'

const PaymentForm = ({ mode = 'save', setPaymentEntryValid }) => {  // or entry

    const elements = useElements();
    const stripe = useStripe();
    const [errorMessage, setErrorMessage] = useState(null);
    const [paymentLoading, setPaymentLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setPaymentLoading(true);
        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_BASEURL_FULL}/Payment`,
            }
        }
        );

        setPaymentLoading(false)
        if(error){
            setErrorMessage(error.message)
        }
    }

    const handleChange = async (e) => {
        if (setPaymentEntryValid) {
            setPaymentEntryValid(e.complete)
        }
    }

    return <form onSubmit={handleSubmit}>
        <PaymentElement id="card-element" onChange={handleChange} options={mode === 'save' ? { wallets: { applePay: 'never', googlePay: 'never' } } : {}} />
        {errorMessage && <div>{errorMessage}</div>}
        {mode === 'save' && <button type="submit" className='btn  btn-lg btn-success  btn-block'
            style={{ cursor: 'pointer' }}
            disabled={!stripe || paymentLoading}
        >{paymentLoading && <><ButtonSpinner size={'30px'} />{' '}</>}
            Save
        </button>}
    </form>

}
export default PaymentForm;