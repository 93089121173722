import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
  setLocation, 
  clearSelectedSubscriber,
  getPlaceByID
} from '../../redux/actions/marketplaceActions';

import { cartItemCount} from '../../redux/selectors';
import HeaderView from './HeaderView';

const mapStateToProps = (state, ownProps = {}) => {
  return (
    {
      checkout_validating: state.marketplaceReducer.checkout_validating,
      customerlocation: state.marketplaceReducer.customerlocation,
      cartItemCount: cartItemCount(state)
    })
}

const mapDispatchToProps = dispatch => ({
  setLocation: customerlocation =>dispatch(setLocation(customerlocation)),
  getPlaceByID: placeId =>dispatch(getPlaceByID(placeId)),
  clearSelectedSubscriber: ()=>dispatch(clearSelectedSubscriber()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderView)
);