import { filter, startsWith, last } from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './ItemOptions.css';
import { ItemOption } from './ItemOption';

function Grouped({ Os, OGid, selectedOVs, selectOV }) {
    const groupSimulatedCheckBoxes = [
        /* EXAMPLE ENTRIES
            {nodeType: SimulatedCheckbox, nodes: []},
            {nodeType: NonSimulatedCheckbox, nodes: []},
            {nodeType: SimulatedCheckbox, nodes: []},
            {nodeType: NonSimulatedCheckbox, nodes: []},
        */
    ];



    Os.forEach((O, i) => {
        let Oid = O.option_ID;
        const hasNoIndex = O.OptionValues.findIndex((ov) => (ov.optionvalue_value.toLowerCase().startsWith('no')));
        const simultedCheckBox = hasNoIndex > -1 && O.OptionValues.length === 2;
        const strStartsWith = `${OGid}.${Oid}.`;
        const selectedOV_MAP = filter(selectedOVs, (sOV) => startsWith(sOV, strStartsWith));



        const lastElementEntry = last(groupSimulatedCheckBoxes);
        const latestEntryType = (lastElementEntry ? lastElementEntry.nodeType : null);
        if (simultedCheckBox) {
            if (latestEntryType === null || latestEntryType === 'NonSimulatedCheckbox') {
                groupSimulatedCheckBoxes.push(
                    {
                        nodeType: 'SimulatedCheckbox',
                        nodes: [<ItemOption {... { O, OGid, Oid, hasNoIndex, simultedCheckBox, selectedOV_MAP, selectOV }} key={O.option_ID} />]
                    }
                );
            } else if (latestEntryType === 'SimulatedCheckbox') {
                lastElementEntry.nodes.push(<ItemOption {... { O, OGid, Oid, hasNoIndex, simultedCheckBox, selectedOV_MAP, selectOV }} key={O.option_ID} />);
            } else {
                throw new Error('must match a criteria');
            }

        } else {

            if (latestEntryType === null || latestEntryType === 'SimulatedCheckbox') {
                groupSimulatedCheckBoxes.push(
                    {
                        nodeType: 'NonSimulatedCheckbox',
                        nodes: [<ItemOption {... { O, OGid, Oid, hasNoIndex, simultedCheckBox, selectedOV_MAP, selectOV }} key={O.option_ID} />]
                    }
                );

            } else if (latestEntryType === 'NonSimulatedCheckbox') {
                lastElementEntry.nodes.push(<ItemOption {... { O, OGid, Oid, hasNoIndex, simultedCheckBox, selectedOV_MAP, selectOV }} key={O.option_ID} />);

            } else {
                throw new Error('must match a criteria');
            }
        }

    })

    let x = groupSimulatedCheckBoxes.map((grouped, idx) => {
        if (grouped.nodeType === 'SimulatedCheckbox') {
            return <div key={`_${idx}`} className="a0_1 p-2  bg-white  border-left  border-success border-2 d-flex flex-wrap">{[...grouped.nodes]}</div>
        } else {
            return <Fragment key={`_${idx}`}>{[...grouped.nodes]}</Fragment>
        }
    });
    return x;
    // }
}


function ItemOptions({ menuItem, selectOV }) {


    return (
        menuItem.OptionGroups ?
            menuItem.OptionGroups.map((OG) => {

                const { optionGroup_ID, optionGroup_Title, Options, optionMatrix } = OG;
                if (optionMatrix) {
                    const relatedOptionMatrix = menuItem.OptionMatrix[optionMatrix];
                    
                    return <div>OptionGroupMatrix Here!</div>
                }


                return (
                    <Fragment key={optionGroup_ID}>
                        <div className="font-weight-bold mb-1 mt-2 a0" key={'title_' + optionGroup_ID}>
                            {optionGroup_Title}
                        </div>
                        <Grouped key={'_' + optionGroup_ID} Os={Options} OGid={optionGroup_ID} selectedOVs={menuItem.selectedOVs}  {...{ selectOV }} />
                    </Fragment>
                )
            })
            :
            null
    )
}

ItemOptions.propTypes = {
    menuItem: PropTypes.object.isRequired,
}
export default ItemOptions;